import { Button, KIND } from 'baseui/button';
import { FormControl } from "baseui/form-control";
import { Input } from 'baseui/input';
import { StyledLink } from "baseui/link";
import { Notification } from 'baseui/notification';
import { DisplayMedium, DisplaySmall, Label4, Paragraph1 } from 'baseui/typography';
import firebase from 'firebase';
import React from 'react';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import { useStyletron } from 'styletron-react';
import { setUserDetails } from '../actions/user';
import { woodBackground } from '../assets';
import { Spacer } from '../components/layout/Spacer';
import { Header } from '../components/navigation/Header';
import { ROUTE_SIGNUP } from '../components/navigation/Sidebar';
import { NotificationOverride } from '../theming';
import useShortcut from '../utils/useShortcut';
import { pushKeepSearchString } from './URLTracker';

export const LoginPage = ({ setUserDetails }) => {
    return <div style={{ width: '100%', justifyContent: 'center', display: 'flex', flexDirection: 'column', margin: 'auto', height: '100%' }}>
        <LoginComponent setUserDetails={setUserDetails} />
    </div>
}

const defaultStyle = (duration) => ({
    transition: `opacity ${duration}ms ease-in-out, transform ${duration}ms ease-in-out`,
    opacity: 0,
});

const transitionStyles = {
    entering: { opacity: 0, transform: 'translate(0, 100px)' },
    entered: { opacity: 1, transform: 'translate(0)' },
};

const SignupButton = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1050 });
    const history = useHistory();

    return <Transition appear in timeout={100}>
        {state => (
            <div style={{
                ...defaultStyle(400),
                ...transitionStyles[state],
            }}>
                <Button overrides={{
                    BaseButton: { style: { color: '#1E5299', width: isTabletOrMobile ? '90px' : '130px', fontFamily: 'Lexend Exa', fontSize: isTabletOrMobile ? '12px' : '18px' } }
                }} kind={KIND.secondary} onClick={() => pushKeepSearchString(history, ROUTE_SIGNUP)}>Sign Up</Button>
            </div>
        )}
    </Transition>
};

const LoginButton = ({ login }) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1050 });

    return <Transition appear in timeout={500}>
        {state => (
            <div style={{
                ...defaultStyle(600),
                ...transitionStyles[state],
            }}><Button overrides={{
                BaseButton: { style: { backgroundColor: '#1E5299', width: isTabletOrMobile ? '90px' : '130px', fontFamily: 'Lexend Exa', fontSize: isTabletOrMobile ? '12px' : '18px' } }
            }} onClick={login}>Log in</Button>
            </div>
        )}
    </Transition>
};

export default connect(() => ({
}), {
    setUserDetails
})(LoginPage);

const LoginComponent = ({ setUserDetails }) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1050 });
    const [css] = useStyletron();
    const passwordInput = React.useRef(null);

    const [forgotPasswordFlowState, setForgotPasswordFlowState] = React.useState(undefined);
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [error, setError] = React.useState('');
    const [passwordResetFlowError, setPasswordResetFlowError] = React.useState('');

    const login = () =>
        firebase.auth()
            .signInWithEmailAndPassword(username, password)
            .then(e => setUserDetails(e.user))
            .catch(e => setError(e.message));

    const resetPassword = async () => {
        try {
            await firebase.auth().sendPasswordResetEmail(username);
            setForgotPasswordFlowState('success')
        } catch (e) {
            console.log(e)
            setPasswordResetFlowError(e.message)
        }
    }

    useShortcut({ ref: passwordInput, trigger: 'Enter', func: login, });

    const styles = {
        screen: { display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', backgroundSize: 'cover', height: '100vh', width: '100vw', maxHeight: '100vh', maxWidth: '100vw', overflow: 'hidden', backgroundImage: `url(${woodBackground})` },
        headerContainer: { width: '100%', top: 0, height: '80px', zIndex: '1', backgroundColor: 'white', position: 'absolute' },
        circle: {
            left: isTabletOrMobile ? '0' : '43vw',
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            padding: isTabletOrMobile ? '0' : '1rem',
            alignItems: isTabletOrMobile ? 'center' : 'flex-start',
            justifyContent: 'center',
            borderRadius: isTabletOrMobile ? '500px 0% 0% 500px' : '50%',
            top: isTabletOrMobile ? '80px' : 'inherit',
            minHeight: '500px',
            minWidth: '500px',
            height: isTabletOrMobile ? 'calc(100vh - 80px)' : 'calc(70vh + 30vw)',
            width: isTabletOrMobile ? '1050px' : 'calc(70vh + 30vw)',
            backgroundColor: isTabletOrMobile ? 'rgba(204, 212, 223, 0.87)' : 'rgba(187, 222, 255, 0.75)',
        },
        font: {
            color: '#1E5299',
            fontFamily: 'Lexend Exa',
        },
        contianer: {
            marginTop: '-2vh',
            marginRight: isTabletOrMobile ? '0' : '16%',
            left: isTabletOrMobile ? 'calc(50vw - 130px)' : '0',
            position: isTabletOrMobile ? 'absolute' : 'relative',
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        welcome: { fontSize: isTabletOrMobile ? '18px' : '32px' },
        dcalc: { fontSize: isTabletOrMobile ? '36px' : '80px', fontFamily: 'Stereo Gothic', },
        description: { fontSize: isTabletOrMobile ? '14px' : '24px' },
        splashText: { textAlign: 'center', maxWidth: isTabletOrMobile ? '260px' : '600px', },

        formControlOverrides: {
            Label: { style: { color: '#1E5299', fontSize: isTabletOrMobile ? '14px' : '18px', fontFamily: 'Lexend Exa', } }
        },
        inputContainer: { width: isTabletOrMobile ? "260px" : "370px" },
        buttonGroup: { display: "flex", width: '80%', justifyContent: 'space-between', float: 'right' },
    }

    return <div className={css({ ...styles.screen })}>
        <div style={{ ...styles.headerContainer }}>
            <Header logoOnly />
        </div>
        <div className={css({ ...styles.circle, })}>
            <div className={css({ ...styles.contianer })}>
                <div style={{ ...styles.splashText }}>
                    <DisplaySmall $style={{ ...styles.font, ...styles.welcome }}>welcome to</DisplaySmall>
                    <Spacer />
                    <DisplayMedium $style={{ ...styles.dcalc }}>D-Calc</DisplayMedium>
                    <Paragraph1 $style={{ ...styles.font, ...styles.description }}>Quickly and accurately evaluate the effect of damage or decay on pole strength</Paragraph1>
                </div>
                <Spacer />
                {forgotPasswordFlowState ?
                    <div style={{ width: "100%" }}>
                        {error && <Notification kind="negative" overrides={{ Body: { style: { width: '90%', borderRadius: 0 } } }}>{error}</Notification>}

                        {forgotPasswordFlowState === 'getEmail' ?
                            <FormControl label={() => "Email Address"} overrides={styles.formControlOverrides} error={passwordResetFlowError}>
                                <Input placeholder="email..." value={username} onChange={(e) => setUsername(e.target.value)}></Input>
                            </FormControl> : undefined
                        }
                        {forgotPasswordFlowState === 'success' ?
                            <Notification overrides={NotificationOverride} >Success! Please check your email.</Notification> : undefined
                        }
                        <div style={{ ...styles.buttonGroup }}>
                            {forgotPasswordFlowState === 'getEmail' ? undefined : <div></div>}
                            <Button kind={KIND.secondary} onClick={(e) => setForgotPasswordFlowState(undefined)}>Go Back</Button>
                            {forgotPasswordFlowState === 'getEmail' ? <Button onClick={(e) => resetPassword()}>Send Email</Button> : undefined}
                        </div>
                    </div>
                    : <div style={{ ...styles.inputContainer }}>
                        <FormControl label={() => "Email Address"} overrides={styles.formControlOverrides}>
                            <Input placeholder="email..." value={username} onChange={(e) => setUsername(e.target.value)}></Input>
                        </FormControl>
                        <FormControl label={() => "Password"} overrides={styles.formControlOverrides}>
                            <>
                                <Input placeholder="password..." type='password' value={password} onChange={e => setPassword(e.target.value)} inputRef={passwordInput} />
                                <div style={{ textAlign: 'right', width: '100%', marginTop: '3px' }}>
                                    <StyledLink onClick={(e) => {
                                        setError('')
                                        setForgotPasswordFlowState('getEmail')
                                    }}><Label4>Forgot Password?</Label4></StyledLink>
                                </div>
                            </>
                        </FormControl>
                        <div style={{ width: "100%" }}>
                            {error && <Notification kind="negative" overrides={{ Body: { style: { width: '90%', borderRadius: 0 } } }}>{error}</Notification>}
                            <div style={{ ...styles.buttonGroup }}>
                                <SignupButton />
                                <LoginButton login={login} />
                            </div>
                        </div>
                        <div style={{ padding: '70px 0px 50px 0px', textAlign: 'right' }}><a href="https://help.dcalc.edmlink.com/" style={{ color: 'black' }}>Documentation / Get Help</a></div>
                        <Spacer />
                    </div>}
            </div>
        </div>
    </div>
}
