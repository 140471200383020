import { Button } from 'baseui/button';
import { Card, StyledAction, StyledBody } from 'baseui/card';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { Label3, Paragraph4 } from 'baseui/typography';
import React from 'react';
import { connect } from 'react-redux';
import UserApi from '../../firebase/UserApi';

export const Registration = () => {
    const [orgName, setOrgName] = React.useState('');


    const [purchaseButtonError, setPurchaseButtonError] = React.useState();
    const [orgNameError, setOrgNameError] = React.useState();
    const [purchaseLoading, setPurchaseLoading] = React.useState(false);

    function validatePurchase() {
        setOrgNameError();

        let validation = true;
        if (!orgName || orgName.length === 0) {
            setOrgNameError('Please provide a organization name');
            validation = false;
        }

        if (orgName.length > 50) {
            setOrgNameError('Organization name length must be less than 50 characters!');
            validation = false
        }
        return validation;
    }

    return <div style={{ display: 'flex' }}>
        <Card title="Purchase Licenses" action={() => console.log('Purchase License not implemented!')} overrides={{
            Body: {
                style: {
                    maxWidth: '300px',
                    borderRadius: 0,
                }
            }
        }}>
            <StyledBody>
                <Label3>Start here to purchase licenses for an organization.</Label3><br />
                <FormControl label="Organization Name" error={orgNameError}>
                    <Input placeholder="Organization Name" value={orgName} onChange={(e) => setOrgName(e.target.value)}></Input>
                </FormControl>
            </StyledBody>
            <StyledAction>
                <FormControl error={purchaseButtonError}>
                    <Button
                        overrides={{
                            BaseButton: { style: { width: "100%" } }
                        }}
                        onClick={(e) => {
                            if (validatePurchase()) {
                                setPurchaseLoading(true);
                                UserApi.createOrganization(orgName).then(() => setPurchaseLoading(false)).catch((e) => {
                                    setPurchaseButtonError(e.message);
                                    setPurchaseLoading(false);
                                });
                            }

                        }} isLoading={purchaseLoading}>Start Purchase</Button>
                </FormControl>
            </StyledAction>
            <Paragraph4>If you are trying to register a license purchased by your employer, please check your email for a link that will provide access.</Paragraph4>
        </Card>
    </div>
}

export default connect(state => ({}), {})(Registration);