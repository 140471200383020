export const NotificationOverride = {
    Body: {
        style: ({ $theme }) => ({ borderRadius: 0, borderTopRightRadius: '12px', borderBottomLeftRadius: '12px' })
    }
}

export const CardOverride = {
    Root: {
        style: {
            borderRadius: 0,
        }
    }
}

export const ModalOverride = {
    Dialog: {
        style: {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
        },
    }
}

export const ToasterContainerOverride = {
    ToastBody: {
        style: {
            borderRadius: 0
        }
    }
}