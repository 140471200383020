export function pushKeepSearchString(history, route) {
    if (window.location.search.startsWith('?orgId')) {
        history.push(route + window.location.search)
    } else {
        history.push(route);
    }
}

export function withSearchString(routeToWrap) {
    return window.location.search.startsWith('?orgId') ? routeToWrap + window.location.search : routeToWrap;
}