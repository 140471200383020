import { SET_FIREBASE } from "../actions/firebase";

function getDefaultState() {
    return {
        firebase: undefined,
    };
}

export const firebase = (state = getDefaultState(), action) => {
    switch (action.type) {
        case SET_FIREBASE:
            return { ...state, firebase: action.firebase }
        default:
            return state;
    }
}