import { KIND } from 'baseui/button';
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from 'baseui/modal';
import firebase from 'firebase';
import React from 'react';
import { connect } from 'react-redux';
import { ModalOverride } from '../../theming';

export const ChangePasswordModal = ({ setOpen, isOpen }) => {
    const [errorMessage, setErrorMessage] = React.useState();
    const [confirmationErrorMessage, setConfirmationErrorMessage] = React.useState();
    const [newPassword, setNewPassword] = React.useState('');
    const [confirmNewPassword, setConfirmNewPassword] = React.useState('');
    const [oldPassword, setOldPassword] = React.useState('');
    const [oldPasswordError, setOldPasswordError] = React.useState();
    const [inPasswordFlow, setInOldPasswordFlow] = React.useState(false);

    async function confirmPasswordChange() {
        if (newPassword !== confirmNewPassword) {
            setConfirmationErrorMessage('Passwords do not match');
        } else {
            try {
                await firebase.auth().currentUser.updatePassword(newPassword);
                setOpen(false)
            } catch (e) {
                console.log(e);
                if (e.code === 'auth/requires-recent-login') {
                    setInOldPasswordFlow(true);
                } else {
                    setConfirmationErrorMessage(e.message);
                }
            }
        }
    }

    async function submitPasswordChangeAfterAuthenticating() {
        try {
            const credential = firebase.auth.EmailAuthProvider.credential(
                firebase.auth().currentUser.email,
                oldPassword
            );
            await firebase.auth().currentUser.reauthenticateWithCredential(credential)
            await firebase.auth().currentUser.updatePassword(newPassword);
            cancel()
        } catch (e) {
            console.log(e);
            setOldPasswordError(e.message);
        }
    }

    function cancel() {
        setInOldPasswordFlow(false);
        setOpen(false);
    }

    function reauthenticateBody() {
        return <>
            <ModalBody>
                <FormControl error={oldPasswordError} label="Please enter your old password">
                    <Input
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        placeholder="Old Password"
                    ></Input>
                </FormControl>
            </ModalBody>
            <ModalFooter>
                <ModalButton
                    onClick={() => setInOldPasswordFlow(false)}
                    kind={KIND.tertiary}
                >
                    Back
                </ModalButton>
                <ModalButton
                    onClick={() => submitPasswordChangeAfterAuthenticating()}
                    overrides={{ BaseButton: { style: { backgroundColor: '#2596DE', color: 'white', ':hover': { backgroundColor: '#4FB1F7' } } } }}
                >
                    Confirm
                </ModalButton>
            </ModalFooter>
        </>
    }

    function passwordChangeBody() {
        return <>
            <ModalBody>
                <FormControl error={errorMessage} label="New Password">
                    <Input
                        type='password'
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        placeholder="New Password"
                    ></Input>
                </FormControl>
                <FormControl error={confirmationErrorMessage} label="Confirm New Password">
                    <Input
                        type='password'
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                        placeholder="Confirm New Password"
                    ></Input>
                </FormControl>
            </ModalBody>
            <ModalFooter>
                <ModalButton
                    onClick={() => cancel()}
                    kind={KIND.tertiary}
                >
                    Cancel
                </ModalButton>
                <ModalButton
                    onClick={() => confirmPasswordChange()}
                    overrides={{ BaseButton: { style: { backgroundColor: '#2596DE', color: 'white', ':hover': { backgroundColor: '#4FB1F7' } } } }}
                >
                    Change Password
                </ModalButton>
            </ModalFooter>
        </>
    }

    return <>
        <Modal overrides={ModalOverride} isOpen={isOpen} onClose={() => cancel(false)} closeable>
            <ModalHeader>Change Password</ModalHeader>
            {inPasswordFlow ? reauthenticateBody() : passwordChangeBody()}
        </Modal>

    </>
}

export default connect(state => ({}), {})(ChangePasswordModal);