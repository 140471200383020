import { Block } from "baseui/block";
import { SIZE } from "baseui/button";
import { ButtonGroup } from "baseui/button-group";
import { Cell, Grid } from "baseui/layout-grid";
import { KIND, Notification } from 'baseui/notification';
import { H2, H3, Label4 } from "baseui/typography";
import React from "react";
import { useSelector } from 'react-redux';
import { useMediaQuery } from "react-responsive";
import useIsMobile from '../../utils/useIsMobile';
import { ConnectedHeader } from "../navigation/Header";
import Sidebar from "../navigation/Sidebar";
import { Spacer } from "./Spacer";
import { ConnectedTooManyDevicesModal } from "./TooManyDevicesModal";

export const NavigationWrapper = ({ children }) => {
  const shouldShowHashMenu = useMediaQuery({ query: '(max-width: 600px)' })
  const pgWidth = shouldShowHashMenu ? '100vw' : '97vw';
  const sync = useSelector(state => state.ui.sync);

  return (
    <Block minHeight='100vh' minWidth='100' overrides={{
      Block: {
        style: ({ $theme }) => ({
          overflow: 'hidden', backgroundColor: $theme.colors.backgroundTertiary,
          paddingBottom: '10px'
        })
      }
    }}>
      {!sync ? <Notification
        kind={KIND.negative}
        overrides={{
          Body: {
            style: {
              margin: '0',
              backgroundColor: '#924444',
              width: '100%',
              color: '#FFFFFF',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              borderRadius: 0,
            }
          }
        }
        }>{() => "Reminder: Data Is Not Sync'ed"}
      </Notification> : undefined}
      <ConnectedHeader />
      <Block
        overrides={{
          Block: {
            style: {
              minHeight: "calc(100% - 80px)",
              display: "flex",
              maxWidth: '100vw',
            },
          },
        }}>
        <Sidebar />
        <div style={{ flexDirection: 'column', width: '100%' }}>
          <Spacer />
          <Block
            backgroundColor='white'
            display='flex'
            align-items='center'
            justify-content='center'
            paddingBottom='2rem'
            maxWidth={!shouldShowHashMenu ? `calc(${pgWidth} - 200px)` : pgWidth}>
            {children}
          </Block>
        </div>
      </Block>
      <Spacer />
      <Label4 style={{ paddingRight: '5px', paddingBottom: '2px', textAlign: 'center' }}>Copyright © 2010-2024 EDM International, Inc</Label4>
      <Spacer />
    </Block>
  );
}

const Title = ({ children }) => {
  const isMobile = useIsMobile();
  if (isMobile) return <H3 margin='10px' whiteSpace='nowrap'>{children}</H3>;
  return <H2 style={{ position: "relative", left: "-25px" }}>{children}</H2>;
}

export const Page = ({ children, title, buttons }) => {
  const isMobile = useIsMobile();

  return (
    <Block paddingTop={['0px', '0px']} width={isMobile ? '100vw' : 'calc(100vw - 200px)'}>
      <Block paddingTop={['0px', '0px']} paddingBottom={['0px', '0px']} width={['100%', '100%', '100%']}>
        <Grid gridColumns={[2, 2, 4]} align={'center'} width={'80%'} >
          <Cell span={2} alignItems="center" display="flex"><Title>{title}</Title></Cell>
          <Cell span={2}>
            <Block display={['flex', 'flex', 'flex']} alignItems="center" justifyContent="center">
              <ButtonGroup size={isMobile ? SIZE.mini : SIZE.default}>{buttons}</ButtonGroup>
            </Block>
          </Cell>
        </Grid>
      </Block>
      <ConnectedTooManyDevicesModal />
      {children}
    </Block>
  )
};