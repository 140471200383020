import { Block } from "baseui/block";
import { Button, SIZE } from "baseui/button";
import { Cell } from "baseui/layout-grid";
import {
    Modal,
    ModalBody,
    ModalButton, ModalFooter, ModalHeader
} from 'baseui/modal';
import { Label1, Label2, Label3 } from "baseui/typography";
import React, { useState } from "react";
import { connect } from "react-redux";
import { setInfo } from '../../../actions/pole';
import { ModalOverride } from "../../../theming";
import { Spacer } from '../../layout/Spacer';
import ANSITab from "../ANSITab";
import { CARD_STYLING } from "../styles";
import "./DistanceFromGroundLineEditor.css";


const ANSIInfo = ({ info, setInfo }) => {
    const [isAnsiEditorOpen, setIsAnsiEditorOpen] = useState(false);
    const confirm = () => {
        setIsAnsiEditorOpen(false);
    }
    const close = () => {
        setIsAnsiEditorOpen(false);
    }
    const clearEditANSIDetails = () => {
        setInfo({ ansiSpecies: "", ansiClass: undefined, ansiLength: undefined, userEmbedment: undefined });
    }

    return <div style={CARD_STYLING}>
        <Spacer />
        <Spacer />
        <Label1>Pole Properties</Label1>
        <Spacer />
        <Block display="flex" alignItems='center' justifyContent='space-between'>
            <Label2>Species:</Label2>
            <Label3>{info.ansiSpecies?.name}</Label3>
        </Block>
        <Block display="flex" alignItems='center' justifyContent='space-between'>
            <Label2>Class:</Label2>
            <Label3>{info.ansiClass}</Label3>
        </Block>
        <Block display="flex" alignItems='center' justifyContent='space-between'>
            <Label2>Length:</Label2>
            <Label3>{info.ansiLength}</Label3>
        </Block>
        <Block display="flex" alignItems='center' justifyContent='space-between'>
            <Label2>Taper:</Label2>
            <Label3>{info.isLinearTaper ? 'Linear' : 'ANSI'}</Label3>
        </Block>
        <Block display="flex" alignItems='center' justifyContent='space-between'>
            <Label2>Embedment:</Label2>
            <Label3>{info.userEmbedment !== undefined ? 'User Defined' : 'ANSI'}</Label3>
        </Block>
        <Block display="flex" alignItems='center' justifyContent='space-between'>
            <Label2>Fiber Strength:</Label2>
            <Label3>{info.fiberStrength || 0}</Label3>
        </Block>
        <Spacer />
        <Block display='flex' justifyContent='flex-end'>
            <Button onClick={() => setIsAnsiEditorOpen(true)} size={SIZE.compact}>Edit ANSI Details</Button>
        </Block>
        <Spacer />

        <Modal overrides={ModalOverride} onClose={close} isOpen={isAnsiEditorOpen} unstable_ModalBackdropScroll>
            <ModalHeader>Edit ANSI Details</ModalHeader>
            <ModalBody>
                <ANSITab />
            </ModalBody>
            <ModalFooter>
                <Cell span={12}>
                    <div className="footer__editANSI">
                        <ModalButton onClick={clearEditANSIDetails}>Clear All</ModalButton>
                        <ModalButton onClick={confirm}>Done</ModalButton>
                    </div>
                </Cell>
            </ModalFooter>
        </Modal>

    </div>
}

export const ConnectedANSIInfo = connect(state => {
    let currentLevel = state.ui.currentLevel;
    return {
        info: state.pole.info,
        ansiDiameter: state.pole.output[currentLevel].ansiDiameter,
        damageParameters: state.pole.damageParameters[currentLevel]
    }
}, {
    setInfo
}
)(ANSIInfo);