import firebase from 'firebase';
import Firebase from './Firebase';

class BillingApi {
    async purchase(stripe, paymentModel, numLicenses, apiAccess) {
        let purchaseObject = {};
        if (paymentModel) {
            purchaseObject[paymentModel] = numLicenses;
        }

        if (apiAccess !== undefined) {
            purchaseObject['apiAccess'] = apiAccess;
        }

        let sessionResponse = await Firebase.fetch('/stripe/startPaymentSession', {
            method: 'POST', body: purchaseObject
        });

        if (!sessionResponse.sessionId) {
            throw new Error('Failed communicating with payment system. Please try later')
        }
        return await stripe.redirectToCheckout({ sessionId: sessionResponse.sessionId });
    }

    async goToPortal() {
        let portal = await Firebase.fetch('/stripe/portalLink', {
            method: 'POST'
        });
        window.location.href = portal.url;
    }

    async getAPIKey(orgId) {
        let queryResults = await firebase.firestore().collection('organizations').doc(orgId).collection('api-keys').where('active', '==', true).limit(1).get();
        if (!queryResults.empty) {
            return queryResults.docs[0].data();
        } else {
            return await this.cycleAPIKey()
        }
    }

    async validatePurchase(session_id) {
        try {
            let stripeDetails = await Firebase.fetch('/stripe/validatePayment', {
                method: 'POST',
                body: { session_id }
            });

            if (stripeDetails.status === 'paid') {
                return true
            }
        } catch (e) {
            console.log(e)
        }

        return false;
    }

    async cycleAPIKey() {
        let key = await Firebase.fetch('/organizations/cycleAPIKey', {
            method: 'POST',
            body: {}
        });

        if (key.success) {
            return key.license;
        } else {
            throw new Error(key.error)
        }
    }

    async inviteUser(emailAddress, admin, useCalculator, orgId) {
        return await Firebase.fetch('/organizations/inviteUser', {
            method: 'POST',
            body: {
                orgId,
                emailAddress,
                details: {
                    admin,
                    useCalculator,
                }
            }
        });
    }

    async updateLicense(licenseId, admin, useCalculator, orgId) {
        return await Firebase.fetch('/organizations/updateLicense', {
            method: 'POST',
            body: {
                orgId,
                licenseId,
                details: {
                    admin,
                    useCalculator,
                }
            }
        });
    }

    async deleteUser(licenseId, orgId) {
        return await Firebase.fetch('/organizations/deleteUser', {
            method: 'POST',
            body: {
                orgId,
                licenseId
            }
        });
    }

    getBillingType(orgData) {
        let type = { key: 'monthlyLicenses', label: 'Monthly Licenses' };
        if (!orgData) return type;
        if (orgData.perpetualLicenses) {
            type = { key: 'perpetualLicenses', label: 'Perpetual Licenses' };
        } else if (orgData.yearlyLicenses) {
            type = { key: 'yearlyLicenses', label: 'Yearly Licenses' };
        } else if (orgData.licenses) {
            type = { key: 'licenses', label: 'Other Licenses' };
        }
        return type;
    }

    getHasPurchasedLicenses(orgData) {
        return this.getBillingType(orgData).key && orgData[this.getBillingType(orgData).key] > 0;
    }

    getNumLicensesString(orgData) {
        if (!orgData) return 'No Licenses';
        return (orgData[this.getBillingType(orgData).key] || 0) + ' ' + this.getBillingType(orgData).label
    }

    hasStripeLicenses(orgData) {
        return orgData && (orgData.monthlyLicenses || orgData.perpetualLicenses || orgData.yearlyLicenses);
    }

    hasManualLicenses(orgData) {
        return orgData && orgData.licenses;
    }

    getStripeLicensesString(orgData) {
        if (!this.hasStripeLicenses(orgData)) return 'No Stripe Licenses Purchased';
        return (orgData[this.getBillingType(orgData).key] || 0) + ' ' + this.getBillingType(orgData).label
    }

    getManualLicensesString(orgData) {
        if (!this.hasManualLicenses(orgData)) return 'No Licenses Manually Assigned';
        return orgData['licenses'] + ' License(s) Manually Assigned.'
    }
}

export default new BillingApi();
