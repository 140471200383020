import { validateDamage } from '@edm-internationa/d-calc-calculations';
import { Block } from 'baseui/block';
import { Button, SIZE } from 'baseui/button';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { Notification } from 'baseui/notification';
import { Tab, Tabs } from 'baseui/tabs-motion';
import { Label1 } from 'baseui/typography';
import React from 'react';
import { connect } from "react-redux";
import { useStyletron } from "styletron-react";
import { addDamage, calculateSlice, removeDamage, setDamageValue } from '../../actions/pole';
import { setCurrentDamageIndex, setCurrentlyEditingAngle } from '../../actions/ui';
import { setReturnURLDetail } from "../../actions/user";
import { NotificationOverride } from '../../theming.jsx';
import { ConnectedLevelChooser } from '../Assess/LevelChooser';
import DistanceFromGroundLineEditor from '../Assess/dimensions/DistanceFromGroundLineEditor';
import PoleDimensionEditor from '../inputs/PoleDimensionEditor.js';
import inputs from './inputs';


const DamageInput = ({ setReturnURLDetail, calculateSlice, currentInput, currentDamageValue, level, damageName, setDamageValue, damageIndex, errors, currentDamageIndex, removeDamage, setCurrentlyEditingAngle, poleParamsDetailsData }) => {

    if (!currentInput) return <div></div>
    return <div style={{ width: 'calc(100% - 40px)', backgroundColor: '#EEEEEE', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)', marginBottom: '10px', paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px', paddingBottom: '25px', borderRadius: '0px 0px 15px 15px' }}>
        <Label1>{damageName} Damage Editor</Label1>
        {currentInput.fields.map(({ label, unit, fieldId, Component }) => {
            let value = currentDamageValue?.[fieldId] !== undefined ? currentDamageValue?.[fieldId] : '';
            const props = {
                value,
                currentDamageIndex,
                setDamageValue: setDamageValue,
                onChange: ({ target }) => setDamageValue(level, damageIndex, { [target.name]: +target.value }),
                startEnhancer: unit,
                size: "compact",
                type: 'number',
                name: fieldId,
                key: label + fieldId,
                overrides: {
                    Input: {
                        style: ({ $theme }) => ({
                            backgroundColor: '#FFFFFF',
                        })
                    }
                }
            }
            if (Component) return <Component {...props} {...{ currentDamageValue, level, damageName }} />
            return <div id='lkasdf' style={{ display: 'flex', justifyContent: "space-around" }}>
                <FormControl label={label} key={fieldId}>
                    <Input {...props} pattern={'[0-9]*'} inputMode='decimal' />
                </FormControl>
                {fieldId && fieldId.includes('angle') ? <Button size={SIZE.mini} onClick={() => {
                    setCurrentlyEditingAngle(true)
                    document.getElementById('lkasdf').scrollIntoView({ behavior: 'smooth' })
                }}>Set</Button> : undefined}
            </div>
        })}
        {errors.map(message => <Notification overrides={NotificationOverride} kind="negative">{message}</Notification>)}
        <div style={{ textAlign: 'center', width: '100%' }}><Button onClick={() => removeDamage(level, currentDamageIndex)}>Delete Damage</Button></div>
    </div>
}

const ConnectedDamageInput = connect(state => {
    const currentDamageIndex = state.ui.currentDamageIndex;
    const section = state.pole.damageParameters[state.ui.currentLevel];

    const currentDamageValue = section.damages[state.ui.currentDamageIndex];
    const currentInput = currentDamageValue ? inputs?.find(input => input.type === currentDamageValue.type) : undefined;
    const damageName = currentDamageValue ? currentDamageValue.name : '';

    let errors = [];
    const formsFilled = currentInput ? currentInput?.fields.every(({ fieldId }) => currentDamageValue?.[fieldId] !== undefined) : false;
    if (section && currentDamageValue && currentDamageValue.type && formsFilled) {
        const validation = validateDamage(currentDamageValue, section);
        errors = validation.errors || [];
    }

    return {
        level: state.ui.currentLevel,
        currentInput,
        damageName,
        currentDamageValue,
        currentDamageIndex,
        damageIndex: currentDamageIndex,
        errors,
        poleParamsDetailsData: state?.user?.poleParamsDetails,
    }
}, {
    setDamageValue,
    removeDamage,
    setCurrentlyEditingAngle,
    calculateSlice,
    setReturnURLDetail
})(DamageInput);

const Icon = ({ input: { iconUrl, name, type }, damage, setDamageName }) => {
    const selected = damage ? damage.type === type : false
    const [css, theme] = useStyletron();

    const handleKeyDown = (e, func) => {
        if (e.key === 'Enter' || e.key === ' ') func();
        e.stopPropagation();
    }

    const handleSelect = () => {
        setDamageName({ name, type });
    }

    return (
        <Block
            className={css({ backgroundColor: selected ? 'white' : '#CCD4DF', boxShadow: selected ? '5px 5px 5px rgba(0, 0, 0, 0.25)' : undefined, borderRadius: '5px 5px 0px 0px' })}
            display='flex'
            flexDirection='column'
            minWidth="40px"
            paddingLeft={['10px', '10px', '10px']}
            paddingRight={['10px', '10px', '10px']}
            paddingBottom='10px'
            align='center'
            paddingTop="5px"
            marginLeft={['10px', '10px', '10px']}
            marginRight={['10px', '10px', '10px']}
            onClick={handleSelect}
            onKeyDown={e => handleKeyDown(e, handleSelect)}
            position='relative'
            tabIndex={[0]}
        >
            <img src={iconUrl} width="50" alt={name} />
            {name}
        </Block>
    );
};

const ConnectedIcon = connect(state => ({
    pole: state.pole,
    damage: state.pole.damageParameters[state.ui.currentLevel].damages[state.ui.currentDamageIndex]
}), { addDamage, removeDamage })(Icon);

const DamageTabs = ({ damages, currentDamageIndex, setCurrentDamageIndex, level }) => {
    return <Tabs activeKey={currentDamageIndex}
        onChange={({ activeKey }) => {
            if (parseInt(activeKey) === damages.length && damages.length <= 3) {
                addDamage(level, {});
            }
            setCurrentDamageIndex(activeKey)
        }}
        overrides={{ Root: { style: { paddingBottom: '0px' } } }}
    >
        {damages.map((damage, i) => {
            let headerContent;

            if (!damage.type) {
                headerContent = <Label1>Damage {i + 1}</Label1>
            } else {
                const input = inputs.find((val) => val.type === damage.type)
                headerContent = <img src={input.iconUrl} width="50" alt={input.name} />
            }

            return <Tab title={headerContent} key={i} ></Tab>
        })}
        {damages.length < 4 ?
            <Tab title="Add Damage"> </Tab> : undefined}
    </Tabs>
}


const ConnectedDamageTabs = connect(state => ({
    level: state.ui.currentLevel,
    damages: state.pole.damageParameters[state.ui.currentLevel].damages,
    currentDamageIndex: state.ui.currentDamageIndex,
}), {
    setDamageValue,
    addDamage,
    setCurrentDamageIndex,
    removeDamage
})(DamageTabs);



const DamageEditor = ({ level, setDamageValue, currentDamageIndex, showPreview }) => {
    const [css, theme] = useStyletron();

    const Icons = () => <Block className={css({ borderRadius: '15px 15px 0px 0px', backgroundColor: '#CCD4DF', height: '100px', overflowX: 'auto' })} overrides={{ Root: { style: { whiteSpace: 'nowrap', } } }}
        display='flex'
        alignItems='center'
        padding={'0'}
        margin={['0']}
    >
        {inputs.map(input =>
            <ConnectedIcon
                input={input}
                level={level}
                currentDamageIndex={currentDamageIndex}
                setDamageValue={setDamageValue}
                setDamageName={(damageUpdate) => setDamageValue(level, currentDamageIndex, { ...damageUpdate }, true)}
                key={input.type}
            />)}
    </Block>


    return (
        <>
            <Block>
                <ConnectedLevelChooser />
                <DistanceFromGroundLineEditor />
                <PoleDimensionEditor />
                {showPreview ?
                    <div>
                        <ConnectedDamageTabs />
                        <div style={{ overflowX: 'auto' }}>
                            <Icons />
                            <ConnectedDamageInput />
                        </div>
                    </div>
                    : <Notification overrides={NotificationOverride} >
                        Please enter a valid circumference or diameter.
                    </Notification>}
            </Block>

        </>
    )
};

export default connect(state => ({
    level: state.ui.currentLevel,
    currentDamageIndex: state.ui.currentDamageIndex,
    numDamages: state.pole.damageParameters[state.ui.currentLevel].damages.length,
    showPreview: state.pole.damageParameters[state.ui.currentLevel].diameter >= 1,
}), {
    setDamageValue,
    addDamage,
    setCurrentDamageIndex,
    removeDamage
})(DamageEditor);
