
import { useStyletron } from 'baseui';
import { Button, StyledLoadingSpinner } from "baseui/button";
import { Modal, ModalBody, ModalHeader } from "baseui/modal";
import {
    StyledCell,
    StyledHead, StyledHeadCell,
    StyledRow,
    StyledTable
} from 'baseui/table';
import { toaster } from "baseui/toast";
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { addPole, savePole } from '../../../actions/poles';
import { ModalOverride } from "../../../theming";
import SyncClientDB from "../../database/SyncClientDB";
import { ROUTE_DATABASE } from "../../navigation/Sidebar";

const SaveButton = ({ savePole, isOpen, setOpen }) => {
    const [css] = useStyletron();
    const history = useHistory();
    const [isLoading, setLoading] = React.useState(true);
    const [projectList, setProjectList] = React.useState([]);
    const idb = SyncClientDB.getIDB();

    React.useEffect(() => {
        getAllProjects();
    }, []);

    const getAllProjects = async () => {
        setLoading(true);
        try {
            let projs = await (idb).projects.where("deleted").equals("0").toArray();
            setProjectList(projs);
            setLoading(false);
        } catch (e) {
            setLoading(false)
        }
    }

    const savePoleUnderProject = (projectId) => {
        setOpen(false);
        savePole((status) => {
            if (status === 'success') {
                toaster.positive('Saved Pole')
            } else {
                toaster.negative('Something went wrong. Please check your data and try again.');
            }
        }, projectId);
    }
    return <Modal overrides={ModalOverride} isOpen={isOpen} unstable_ModalBackdropScroll onClose={() => setOpen(false)}>
        <ModalHeader>
            Which project would you like to save this pole under?
            <div style={{ height: '400px', overflowY: 'auto' }}>
                {isLoading ? <div style={{ width: '100%', textAlign: 'center', height: '400px' }}><StyledLoadingSpinner /></div> :
                    <div
                        className={css({
                            gridColumn: 'span 4',
                            marginTop: "15px"
                        })}
                    >
                        <StyledTable>
                            <StyledHead>
                                <StyledHeadCell $sticky={false}>Project Name</StyledHeadCell>
                                <StyledHeadCell $sticky={false}>Owner</StyledHeadCell>
                                <StyledHeadCell $sticky={false}>Action</StyledHeadCell>
                            </StyledHead>
                            {projectList.map((e) =>
                                <StyledRow>
                                    <StyledCell>{e.name}</StyledCell>
                                    <StyledCell>{e.owner}</StyledCell>
                                    <StyledCell>
                                        <Button onClick={() => savePoleUnderProject(e.id)}>Choose</Button>
                                    </StyledCell>
                                </StyledRow>
                            )}
                        </StyledTable>
                    </div>
                }
            </div>
        </ModalHeader>
        <ModalBody>
            <Button onClick={(e) => history.push(ROUTE_DATABASE)}>Create New Project</Button>
        </ModalBody>
    </Modal>
};

export default connect(({ pole }) => ({ pole: pole }), {
    savePole,
    addPole,
})(SaveButton);