import { Block } from 'baseui/block';
import { Button, KIND, SIZE } from 'baseui/button';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { Cell } from 'baseui/layout-grid';
import {
    Modal,
    ModalBody,
    ModalButton, ModalHeader
} from 'baseui/modal';
import { Label2 } from 'baseui/typography';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { calculateSlice, setDamageParameters, setInfo } from '../../../actions/pole';
import { ModalOverride } from '../../../theming';
import ANSITab from "../ANSITab";
import "./DistanceFromGroundLineEditor.css";

export const DistanceFromGroundLineEditor = ({ setDamageParameters, currentLevel, distanceFromGround, ansiDiameter, calculateSlice, info: { ansiSpecies, ansiClass, ansiLength, userEmbedment }, setInfo }) => {
    const [isAnsiEditorOpen, setIsAnsiEditorOpen] = useState(false);
    const [distance, setDistance] = React.useState(distanceFromGround);

    React.useEffect(() => {
        setDistance(distanceFromGround);
    }, [distanceFromGround])

    const handleChange = () => {
        setDamageParameters(currentLevel, { distanceFromGround: +distance })
        calculateSlice(currentLevel)
    }

    const confirm = () => {
        setIsAnsiEditorOpen(false);
        calculateSlice(currentLevel);
    }
    const close = () => {
        setIsAnsiEditorOpen(false);
    }
    const clearEditANSIDetails = () => {
        setInfo({ ansiSpecies: "", ansiClass: undefined, ansiLength: undefined, userEmbedment: undefined });
    }
    return <Block>
        <Block className="ANSI__container">
            <FormControl label="ANSI Diameter:" >
                {!ansiDiameter ? <Button kind={KIND.secondary} size={SIZE.compact} onClick={() => {
                    setIsAnsiEditorOpen(true);
                }}>Edit ANSI Details</Button> : <div className="edit__ANSI__container">
                    <Label2>{Math.round(ansiDiameter * 100) / 100}</Label2>
                    <Button kind={KIND.secondary} size={SIZE.compact} onClick={() => { setIsAnsiEditorOpen(true); }}>Edit ANSI Details</Button>
                </div>}
            </FormControl>

            <Modal overrides={ModalOverride} onClose={close} isOpen={isAnsiEditorOpen} unstable_ModalBackdropScroll>
                <ModalHeader>Edit ANSI Details</ModalHeader>
                <ModalBody>
                    <ANSITab />
                    <Cell span={12}>
                        <div className="footer__editANSI">
                            <ModalButton onClick={clearEditANSIDetails}>Clear All</ModalButton>
                            <ModalButton onClick={confirm}>Done</ModalButton>
                        </div>
                    </Cell>
                </ModalBody>
            </Modal>
        </Block>
        <FormControl label="Distance From Groundline:">
            <Input
                size='compact'
                value={distance}
                onFocus={() => {
                    if (distance == 0) {
                        setDistance('')
                    }
                }}
                onChange={(e) => setDistance(e.target.value)}
                onBlur={() => handleChange()}
                endEnhancer={"ft"}
                type="number"
                pattern={'[0-9]*'} inputMode='decimal'
                name='distanceFromGround'
            />
        </FormControl>
    </Block>
}

export default connect(state => ({
    info: state.pole.info,
    currentLevel: state.ui.currentLevel,
    ansiDiameter: state.pole.output[state.ui.currentLevel]?.ansiDiameter,
    distanceFromGround: state.pole.damageParameters[state.ui.currentLevel]?.distanceFromGround || 0
}), { setDamageParameters, calculateSlice, setInfo })(DistanceFromGroundLineEditor);