import { useStyletron } from 'baseui';
import { Button, KIND, SIZE } from 'baseui/button';
import { Notification } from 'baseui/notification';
import {
    StyledCell,
    StyledHead, StyledHeadCell,
    StyledRow,
    StyledTable
} from 'baseui/table';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setCurrentPoleWithoutSaving, viewPole } from '../../actions/poles';
import { setPoleDetails } from "../../actions/user";
import { ROUTE_MODEL } from '../navigation/Sidebar';


export const ConnectedPoles = (props) => {
    const [css] = useStyletron();
    const history = useHistory();

    function buildPoleList() {
        return props.poles.sort((poleA, poleB) => poleA.info?.date > poleB.info?.date ? -1 : poleA.info?.date === poleB.info?.date ? 0 : 1).map((pole, index) => {
            return (
                <StyledRow key={index}>
                    <StyledCell>{pole['info']['name']}</StyledCell>
                    <StyledCell>{pole['info']['ansiSpecies']['name']}</StyledCell>
                    <StyledCell>{pole['info']['ansiLength']}</StyledCell>
                    <StyledCell>{pole['info']['ansiClass']}</StyledCell>
                    <StyledCell>{pole['info']['date']}</StyledCell>
                    <StyledCell><Button size={SIZE.mini} onClick={() => {
                        props.deletePole(pole['id'], pole['projectId'])
                    }} kind={KIND.tertiary}>Delete</Button>
                        <Button size={SIZE.mini} onClick={() => viewPoleFunction(pole)}>View</Button>
                    </StyledCell>
                </StyledRow>
            );
        })
    }

    function startPole() {
        props.setCurrentPoleWithoutSaving(undefined, props.projectName, props.projectId);
        history.push(ROUTE_MODEL)
    }

    function viewPoleFunction(pole) {
        props.viewPole(pole, props.projectName, props.projectId);
        props.setPoleDetails({});
        history.push(ROUTE_MODEL)
    }

    function buildNewPoleButton() {
        return <Button onClick={(e) => startPole()}>Add New Pole</Button>
    }

    function buildEmptyList() {
        return <div style={{ textAlign: 'center', width: '100%', gridColumn: 'span 5' }}>
            <Notification overrides={{ Body: { style: { marginLeft: 'auto', marginRight: 'auto', 
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0
             } } }}>Nothing Here!</Notification>
            {buildNewPoleButton()}
        </div>
    }

    if (props.poles.length <= 0) {
        return buildEmptyList();
    }

    return <div
        className={css({
            gridColumn: 'span 5',
            padding: '5px',
        })}
    >
        <StyledTable>
            <StyledHead>
                <StyledHeadCell $sticky={false} style={{ width: "15%" }}>Name</StyledHeadCell>
                <StyledHeadCell $sticky={false} style={{ width: "15%" }}>Species</StyledHeadCell>
                <StyledHeadCell $sticky={false} style={{ width: "15%" }}>Length</StyledHeadCell>
                <StyledHeadCell $sticky={false} style={{ width: "15%" }}>Class</StyledHeadCell>
                <StyledHeadCell $sticky={false} style={{ width: "20%" }}>Updated Date</StyledHeadCell>
                <StyledHeadCell $sticky={false} style={{ width: "20%" }}>Actions</StyledHeadCell>
            </StyledHead>
            {buildPoleList()}
        </StyledTable>
    </div>
}

export default connect((state) => ({
}), {
    setCurrentPoleWithoutSaving,
    viewPole,
    setPoleDetails
})(ConnectedPoles);