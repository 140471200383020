import { Button, KIND } from 'baseui/button';
import { Card } from 'baseui/card';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { Notification } from 'baseui/notification';
import { Label2 } from 'baseui/typography';
import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { setUserDetails } from '../../actions/user';
import { ROUTE_LOGIN } from "../../components/navigation/Sidebar";
import UserApi from '../../firebase/UserApi';
import { pushKeepSearchString } from '../../pages/URLTracker';
import { CardOverride, NotificationOverride } from '../../theming';


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const AddUserOption = ({ titleOff, createAccount }) => {
    const [email, setEmail] = React.useState('');
    const [emailCheck, setEmailCheck] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [error, setError] = React.useState(undefined);
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const history = useHistory();
    const query = useQuery();

    React.useEffect(() => {
        let parsedEmail = query.get('email') || ''
        setEmail(parsedEmail)
        setEmailCheck(parsedEmail)
    }, [])

    const createDcalcAccount = async (email, password) => {
        try {
            await UserApi.new({ firstName, lastName, email, password });
            // history.push('/app');
        } catch (e) {
            setError(e.message)
        }
    }

    return <Card title={titleOff ? '' : 'Create D-Calc Account'} overrides={CardOverride}
    >
        <FormControl label={() => "First Name"}>
            <Input placeholder='First Name' onChange={(e => setFirstName(e.target.value))}></Input>
        </FormControl>

        <FormControl label={() => "Last Name"}>
            <Input placeholder='Last Name' onChange={(e => setLastName(e.target.value))}></Input>
        </FormControl>

        <Label2>Login Info</Label2>
        <hr></hr>

        <FormControl label={() => "Email"}>
            <Input placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)}></Input>
        </FormControl>
        <FormControl label={() => "Re-enter Email"}>
            <Input placeholder='Re-enter Email' value={emailCheck} onChange={(e) => setEmailCheck(e.target.value)}></Input>
        </FormControl>
        <FormControl label={() => "Password"}>
            <Input placeholder='Password' type='password' value={password} onChange={(e) => setPassword(e.target.value)}></Input>
        </FormControl>
        <FormControl label={() => "Re-enter Password"}>
            <Input placeholder='Re-enter Password' type='password'></Input>
        </FormControl>
        <hr></hr>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button kind={KIND.minimal} onClick={() => pushKeepSearchString(history, ROUTE_LOGIN)}>Go Back</Button>
            <Button onClick={(e) => {
                if (email != emailCheck) {
                    setError('Emails don\'t match!')
                } else {
                    createDcalcAccount(email, password)
                }
            }}>{'Create Account'}</Button>
        </div>
        {error ? <Notification overrides={NotificationOverride} kind='negative'>{() => error}</Notification> : null}
    </Card >
}

export default connect(state => ({
}), {
    createAccount: () => setUserDetails({ name: 'asmsksk' })
})(AddUserOption);