import { useStyletron } from 'baseui';
import { Button, KIND as ButtonKind } from "baseui/button";
import { FormControl } from "baseui/form-control";
import { Alert } from 'baseui/icon';
import { Input, MaskedInput } from "baseui/input";
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader
} from "baseui/modal";
import moment from 'moment';
import React from "react";
import { connect } from "react-redux";
import { addProject, updateProject } from "../../actions/projects";
import { ModalOverride } from '../../theming';

export const AddProjectModal = ({
  isAddOpen,
  setIsAddOpen,
  data,
  getData,
  updateProjectFn
}) => {
  const [name, setName] = React.useState("");
  const [owner, setOwner] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [startDate, setStartDate] = React.useState("");
  const [isStartValid, setIsStartValid] = React.useState(true);
  const [isEndValid, setIsEndValid] = React.useState(true);
  const [isOwnerNameValid, setIsOwnerNameValid] = React.useState(true);
  const [isProjectNameValid, setIsProjectNameValid] = React.useState(true);
  const shouldShowProjectError = !isProjectNameValid;
  const shouldShowNameError = !isOwnerNameValid;

  React.useEffect(() => {
    if (data !== undefined) {
      setName(data["name"]);
      setOwner(data["owner"]);
      setEndDate(data["endDate"]);
      setStartDate(data["startDate"]);
    } else {
      clearFields()
    }
  }, [data]);

  async function createNewProject() {
    let isDateValid = true;
    if (startDate) {
      let start = moment(startDate);
      let startIsValid = start.isValid();
      if (!startIsValid) {
        setIsStartValid(false)
        isDateValid = false
      }
    }
    if (endDate) {
      let end = moment(endDate);
      let endIsValid = end.isValid();
      if (!endIsValid) {
        setIsEndValid(false)
        isDateValid = false
      }
    }

    let projectNameIsValid = isNameValid(name);
    let nameIsValid = isNameValid(owner);
    let isProjectValid = validateProjectData(projectNameIsValid, nameIsValid, isDateValid);
    if (isProjectValid) {
      if (data && data.id) {
        await updateProjectFn({ ...data, name, owner, startDate, endDate });
      } else {
        await addProject({ name, owner, startDate, endDate });
      }
      getData();
      resetForm();
      clearFields();
    }
  }

  function validateProjectData(projectNameIsValid, nameIsValid, isDateValid) {
    projectNameIsValid ? setIsProjectNameValid(true) : setIsProjectNameValid(false);
    nameIsValid ? setIsOwnerNameValid(true) : setIsOwnerNameValid(false);
    return (isDateValid && projectNameIsValid && nameIsValid);
  }

  function isNameValid(name) {
    // checks that spaces weren't added as a name
    if (!name.replace(/\s/g, '').length) {
      return false;
    }
    return true;
  }

  function resetForm() {
    setIsAddOpen(false);
    setIsEndValid(true);
    setIsStartValid(true);
    setIsProjectNameValid(true);
    setIsOwnerNameValid(true);
  }

  function clearFields() {
    setName("");
    setOwner("");
    setEndDate("");
    setStartDate("");
  }

  function Negative() {
    const [css, theme] = useStyletron();
    return (
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          paddingRight: theme.sizing.scale500,
          color: theme.colors.negative400,
        })}
      >
        <Alert size="18px" />
      </div>
    );
  }

  return (
    <>
      <Button size="compact" onClick={() => setIsAddOpen(true)}>
        New Project
      </Button>
      <Modal overrides={ModalOverride} isOpen={isAddOpen} onClose={() => setIsAddOpen(false)} unstable_ModalBackdropScroll>
        <ModalHeader>Add Project</ModalHeader>
        <ModalBody >
          <FormControl
            label="Project Name"
            error={
              shouldShowProjectError ? 'Please enter a project name' : ""
            }
          >
            <Input
              onChange={(e) => setName(e.target.value)}
              value={name}
              error={shouldShowProjectError}
              overrides={shouldShowProjectError ? { After: Negative } : {}}
            />
          </FormControl>
          <FormControl
            label="Owner"
            error={
              shouldShowNameError ? 'Please enter a project owner' : ""
            }
          >
            <Input
              onChange={(e) => setOwner(e.target.value)}
              value={owner}
              error={shouldShowNameError}
              overrides={shouldShowNameError ? { After: Negative } : {}}
            />
          </FormControl>
          <FormControl
            label="Start Date"
            error={
              !isStartValid ? 'Please enter a valid date' : ""
            }
          >
            <MaskedInput
              mask="99/99/9999"
              onChange={(e) => setStartDate(e.target.value)}
              value={startDate}
              error={!isStartValid}
              overrides={!isStartValid ? { After: Negative } : {}}
            />
          </FormControl>
          <FormControl
            label="End Date"
            error={
              !isEndValid ? 'Please enter a valid date' : ""
            }
          >
            <MaskedInput
              mask="99/99/9999"
              onChange={(e) => setEndDate(e.target.value)}
              value={endDate}
              error={!isEndValid}
              overrides={!isEndValid ? { After: Negative } : {}}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter >
          <ModalButton
            kind={ButtonKind.tertiary}
            onClick={() => setIsAddOpen(false)}
          >
            Cancel
          </ModalButton>
          <ModalButton onClick={() => createNewProject()}>Okay</ModalButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default connect(null, {updateProjectFn: updateProject})(AddProjectModal);
