import { Button } from 'baseui/button';
import { Card, StyledAction, StyledBody } from 'baseui/card';
import { Checkbox } from 'baseui/checkbox';
import { FormControl } from 'baseui/form-control';
import { ArrowLeft, ArrowRight } from 'baseui/icon';
import { HeadingMedium, Label1 } from 'baseui/typography';
import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { connect } from 'react-redux';
import { setUserData } from '../../actions/user';
import UserApi from '../../firebase/UserApi';
import { CardOverride } from '../../theming';
import commercialPDF from './Commercial_Software_License_Agreement_rev_3_3_21.pdf';
import genTermsPDF from './Gen_terms_3_3_21.pdf';
import privacyPDF from './Privacy_Policy_rev_3_3_21.pdf';
import './TOS.css';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const agreementsList = [
    { dbName: 'acceptedTOS', pdf: genTermsPDF, heading: 'Terms Of Service' },
    { dbName: 'acceptedSourceCodeLicense', pdf: commercialPDF, heading: 'Software License' },
    { dbName: 'acceptedPrivacyPolicy', pdf: privacyPDF, heading: 'Privacy Policy' }
];

const scrollTo = (element, to, duration) => {
    if (duration <= 0 || !element) return;
    let difference = to - element.scrollTop;
    let perTick = difference / duration * 10;

    setTimeout(function () {
        element.scrollTop = element.scrollTop + perTick;
        if (element.scrollTop === to) return;
        scrollTo(element, to, duration - 10);
    }, 10);
}

const scrollTop = () => scrollTo(document.getElementById('pageBlock'), 0, 100);

export const TermsOfService = ({ userData, setUserData }) => {
    const [tosAccepted, setTosAccepted] = React.useState(false);
    const [currentAgreement, setCurrentAgreement] = React.useState();
    const [tosError, setTosError] = React.useState();
    const [tosAcceptLoading, setTosAcceptLoading] = React.useState(false);
    const [numPages, setNumPages] = React.useState(null);
    const [currentPage, setCurrentPage] = React.useState(1);

    React.useEffect(() => {
        setCurrentAgreement(getNextToAccept());
    }, [userData])

    function getNextToAccept() {
        for (let agreement of agreementsList) {
            if (!userData[agreement.dbName]) return agreement;
        }
    }

    async function accept() {
        if (!tosAccepted) {
            setTosError('You must agree to the terms with the provided checkbox.')
        } else if (currentPage !== numPages) {
            setTosError('You must click through each page.')
        } else {
            setTosError();
            setTosAcceptLoading(true);
            try {
                await UserApi.acceptLicense(currentAgreement.dbName);
                setUserData({ ...userData, [currentAgreement.dbName]: true });
                setTosAccepted(false);
                setTosAcceptLoading(false);
                setCurrentPage(1)
                scrollTop();
            } catch (err) {
                setTosError(err.message);
                setTosAcceptLoading(false);
            }
        }
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    if (!currentAgreement) return <div />

    return <div id='pageBlock' style={{ width: '100vw', maxHeight: '100vh', height: '100vh', textAlign: 'center' }}>
        <HeadingMedium>Agree To Terms</HeadingMedium>
        <Label1>Please review and accept the {currentAgreement.heading}</Label1>
        <div style={{ maxWidth: '100vw', width: 610, height: 800, margin: 'auto', overflowX: 'clip', overflowY: 'auto' }}>
            <Document file={currentAgreement.pdf} onLoadSuccess={onDocumentLoadSuccess} >
                <Page className="PDFPage" pageNumber={currentPage} />
            </Document>
        </div>
        <div style={{ maxWidth: '100vw', width: 600, margin: 'auto' }}>
            <div style={{ display: 'flex', zIndex: 100 }}>
                <Button disabled={currentPage <= 1} onClick={(e) => setCurrentPage(currentPage - 1)}>
                    <ArrowLeft />
                    Previous
                </Button>
                <div style={{ width: '100%' }} />
                <Button disabled={currentPage >= numPages} onClick={(e) => setCurrentPage(currentPage + 1)}>
                    Next
                    <ArrowRight />
                </Button>

            </div>
            <br></br>
            <div style={{ width: 'calc(100% - 20px)', margin: 'auto', textAlign: 'center' }}>
                <Card overrides={CardOverride}>
                    <StyledBody>
                        <FormControl error={tosError}>
                            <Checkbox checked={tosAccepted} onChange={(e) => setTosAccepted(!tosAccepted)}>I have read and and agree to these terms and conditions</Checkbox>
                        </FormControl>
                    </StyledBody>
                    <StyledAction>
                        <Button onClick={(e) => accept()} isLoading={tosAcceptLoading}>Agree</Button>
                    </StyledAction>
                </Card>
            </div>

        </div>
    </div >
}

export default connect(state => ({
    userData: state.user.userData,
}), {
    setUserData
})(TermsOfService);