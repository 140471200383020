import Dexie from "dexie";
import 'dexie-observable';
import 'dexie-syncable';
import firebase from "firebase";
import initSync from "./FirebaseServerSync";
import './FirebaseSyncComms';
import { isOnline, serverComm } from "./FirebaseSyncComms";


//Set up code
const PROTOCOL = "firebase";
const protocolImplementation = {
  sync: initSync(serverComm, isOnline),
  partialsThreshold: Infinity,
};

Dexie.Syncable.registerSyncProtocol(PROTOCOL, protocolImplementation)    


class SyncClientDB {
  constructor() {
    this.idb = undefined;
  }

  async getShouldSync() {
    return await this.getSetting('shouldSync') == true
  }
  
  async logConnections() {
    this.ensureIDB();
    this.idb.syncable.list().then(urls => {
      return Promise.all(urls.map(url => this.idb.syncable.getStatus(url).then(status => ({url: url, status: status}))))
    }).then(results => {
      results.forEach(x => {
          console.log(`URL: ${x.url}, status: ${Dexie.Syncable.StatusTexts[x.status]}`);
      })
    }).catch (err => {
        console.error (`Error: ${err || err}`);
    });
  }

  async setShouldSync(shouldSync, orgId) {
    await this.putSetting('shouldSync', shouldSync);
    this.logConnections()
    if (shouldSync) {
      await this.startSync(orgId)
    } else {
      await this.stopSync(orgId)
    }
  }

  ensureIDB() {
    if (!this.idb) {
      this.getIDB()
    }
  }

  async forceSync(orgId) {
    if (!this.idb) {
      this.getIDB()
    }
    if (!await this.getShouldSync()) {
      console.error('Request to force sync, but sync not enabled')
      return;
    }

    try {
      //This is extremely dumb. Write 30 records to get the revision bumped
      for (let i = 0; i < 10; i++) {
        await this.putSetting('syncScratchpad1', !(await this.getSetting('syncScratchpad1')));
        await this.putSetting('syncScratchpad2', !(await this.getSetting('syncScratchpad2')));
        await this.putSetting('syncScratchpad3', !(await this.getSetting('syncScratchpad3')));
      }
      await this.delay(3500); // This is the wait time for Dexie's observer. The combination of these 2 things should do the trick.

      await this.idb.syncable.disconnect(orgId)
      await this.idb.syncable.connect(PROTOCOL, orgId)
    } catch(error) {
      console.error(error)
    }
  }
  async delay(ms) {
    return await new Promise(res => setTimeout(res, ms));
  }

  async startSync(orgId) {
    if (!(await this.getShouldSync())) {
      return;
    }
    try {
      await this.idb.syncable.connect(PROTOCOL, orgId);
      let allEndpointURLs = await this.idb.syncable.list();
      for (let endpoint of allEndpointURLs) {
        if (endpoint !== orgId) {
          let status = await this.idb.syncable.getStatus(endpoint)
          console.error('Disconnecting from this Endpoint: ', endpoint, " with status: ", Dexie.Syncable.StatusTexts[status])
          await this.idb.syncable.disconnect(orgId)
        }
      }
      
      console.warn('Starting Cleanup of old changes')
      Dexie.Observable.deleteOldChanges(this.idb)
    } catch(error) {
      console.error(error)
    }
  }

  async stopSync(orgId) {
    try {
      console.log('Stopping Sync to: ', orgId)
      await this.idb.syncable.disconnect(orgId);
      console.log('Stopped Sync to: ', orgId)
    } catch(error) {
      console.error(error)
    }
  }

  async _getClaimsAndStartSync() {
    const idToken = await firebase.auth().currentUser.getIdTokenResult(false) // Don't force a claim refresh which would hit the network.

    if (idToken && idToken.claims && idToken.claims.orgId) {
      this.startSync(idToken.claims.orgId).catch((e) => console.error(e))
    }
  }

  getIDB() {
    const uid = firebase.auth().currentUser.uid;

    if (!this.idb || this.idb.name !== uid) {
      console.log('Opening IDB...', uid)
      let idb = new Dexie(uid);
      idb.version(14).stores({
        poles: "id, deleted, deletedTimestamp, projectId, createdTimestamp, needsSync",
        projects: "id, deleted, deletedTimestamp, createdTimestamp, needsSync",
        settings: "currentUserId , lastSyncedTimestamp",
        settingTable: "settingKey",
        userData: "userId, orgId, firstName, lastName, acceptedPrivacyPolicy, acceptedSourceCodeLicense, acceptedTOS",
        claims: "userId, licensed, admin",
      });

      this._getClaimsAndStartSync()
      idb.syncable.on('statusChanged', function (newStatus, url) {
        console.log ("Sync Status changed:  " + Dexie.Syncable.StatusTexts[newStatus]);
      });
      idb.open();
      this.idb = idb;
    }
    return this.idb;
  }

  //Returns setting or undefined if doesn't exist.
  async getSetting(key) {
    const idbResult = await this.idb.settingTable.get(key);
    if (!idbResult || idbResult.value === undefined) {
      return undefined;
    }

    return idbResult.value;
  }

  async putSetting(key, value) {
    await this.idb.settingTable.put({ settingKey: key, value });
  }

  async clearUser() {
    await this.idb.poles.clear()
    await this.idb.projects.clear()
    await this.idb.settings.clear()
    await this.idb.settingTable.clear()
    await this.idb.userData.clear()
    await this.idb.claims.clear()
  }
}

export default new SyncClientDB();