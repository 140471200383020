import { Button, KIND } from 'baseui/button';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from 'baseui/modal';
import { Select, TYPE } from 'baseui/select';
import { toaster } from 'baseui/toast';
import React from 'react';
import BillingAPI from '../../firebase/BillingAPI';
import { ModalOverride } from '../../theming';
import { Spacer } from '../layout/Spacer';
import { dcalcBlueButton } from './Billing';


export const InviteUserModal = ({ licenses, setLicenses, kind, orgId }) => {
    const [inviteUserModalOpen, setInviteUserModalOpen] = React.useState();
    const [emailToInvite, setEmailToInvite] = React.useState('');
    const [emailInviteError, setEmailInviteError] = React.useState();
    const [licenseDetails, setLicenseDetails] = React.useState({});
    const [sendInviteButtonLoading, setSendInviteButtonLoading] = React.useState(false);


    const openInviteModal = () => {
        setInviteUserModalOpen(true)
    }

    const sendInvite = async () => {
        try {
            if (!licenseDetails.admin && !licenseDetails.useCalculator) {
                throw new Error('No role specified!');
            }
            setSendInviteButtonLoading(true)
            let response = await BillingAPI.inviteUser(emailToInvite, !!licenseDetails.admin, !!licenseDetails.useCalculator, orgId);
            let newLicenses = [...licenses];
            newLicenses.push(response.license)
            setLicenses(newLicenses)
            toaster.positive('License Created!')
            setInviteUserModalOpen(false);
            setSendInviteButtonLoading(false);
        } catch (e) {
            toaster.negative('Unable to create license. ' + e.message)
            setSendInviteButtonLoading(false);
        }
    }

    let value = []
    if (licenseDetails?.useCalculator) {
        value.push({ id: 'Licensed', value: 'useCalculator' });
    }
    if (licenseDetails?.admin) {
        value.push({ id: 'Administrator', value: 'admin' });
    }

    return <>
        <Spacer />
        <Button kind={kind ? kind : KIND.tertiary} onClick={() => openInviteModal()} overrides={dcalcBlueButton}>Invite Users</Button>
        <Modal overrides={ModalOverride} isOpen={inviteUserModalOpen} onClose={(e) => setInviteUserModalOpen(false)} closeable>
            <ModalHeader>Invite User</ModalHeader>
            <ModalBody>
                <FormControl label="Email Address" error={emailInviteError}>
                    <Input
                        value={emailToInvite}
                        onChange={(e) => setEmailToInvite(e.target.value)}
                        placeholder="Email"
                    ></Input>
                </FormControl>
                <FormControl label="Roles">
                    <Select
                        options={[
                            { id: 'Licensed', value: 'useCalculator' },
                            { id: 'Administrator', value: 'admin' },
                        ]}
                        labelKey="id"
                        valueKey="value"
                        placeholder="License Options"
                        maxDropdownHeight="300px"
                        type={TYPE.search}
                        multi
                        onChange={({ value }) => {
                            let newDetails = {
                                useCalculator: false,
                                admin: false,
                            };
                            for (let option of value) {
                                newDetails[option.value] = true;
                            }
                            setLicenseDetails(newDetails);
                        }}
                        value={value}
                    />
                </FormControl>
            </ModalBody>
            <ModalFooter>
                <ModalButton
                    onClick={(e) => setInviteUserModalOpen(false)}
                    kind={KIND.tertiary}
                >
                    Cancel
                </ModalButton>
                <ModalButton
                    onClick={(e) => sendInvite()}
                    isLoading={sendInviteButtonLoading}
                    overrides={dcalcBlueButton}
                >
                    Send Invite
                </ModalButton>
            </ModalFooter>
        </Modal>
    </>
}

export default InviteUserModal;