import { Block } from "baseui/block";
import { ALIGN, HeaderNavigation, StyledNavigationItem, StyledNavigationList } from 'baseui/header-navigation';
import React from 'react';
import { logo } from "../assets";
import WoodBackground from '../assets/woodbackground2.jpg';
import AddUserOption from '../components/admin/AddUserOption';



export const SignUp = () => {
    return <div >
        <HeaderNavigation overrides={{ Root: { style: { backgroundColor: 'white' } } }}>
            <StyledNavigationList $align={ALIGN.left}>
                <StyledNavigationItem>
                    <Block display="flex" alignItems="center">
                        <img src={logo} length="62px" height="60px" alt='d-calc logo' />
                    </Block>
                </StyledNavigationItem>
            </StyledNavigationList>
        </HeaderNavigation>
        <div style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: '0px', zIndex: -99, backgroundImage: `url(${WoodBackground})`, backgroundPosition: 'center' }} />
        <div style={{ width: '100%', height: '100%' }}>
            <div style={{ maxWidth: 550, width: '95%', margin: "auto", paddingTop: '50px' }}>
                <AddUserOption titleOff={false} />
            </div>
        </div>
    </div>
}

export default SignUp;