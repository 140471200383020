/** 
 * @license
 * Copyright © 2010-2024 EDM Internationalk, Inc. info@edmlink.com
 * 
 * This file is part of the D-Calc Software Project.
 * 
 * The D-Calc Software Project can not be copied and/or distributed
 * without the express written permission of EDM International, Inc.
 * 
 */

import { BaseProvider } from 'baseui';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import App from './App';
import './index.css';
import { DCalcReducers } from './reducers';
import * as serviceWorker from './serviceWorker';
import theme from './theme';


const engine = new Styletron();

const logger = store => next => action => {
  console.log('Dispatching', action);
  let result = next(action);
  console.log('Next State:', store.getState());
  return result;
}

export const store = createStore(DCalcReducers, applyMiddleware(thunk, logger));
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <StyletronProvider value={engine}>
        <BaseProvider theme={theme}>
          <App />
        </BaseProvider>
      </StyletronProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
