import React from 'react';
import {StyledSpinnerNext, SIZE} from 'baseui/spinner'
import {withStyle} from 'baseui';

const SpinnerWidth = '5px';
const SpinnerColor = '#41496e';
const SpinnerSize = '96px';
const XLSpinner = withStyle(StyledSpinnerNext, {
    width: SpinnerSize,
    height: SpinnerSize,
    borderLeftWidth: SpinnerWidth,
    borderRightWidth: SpinnerWidth,
    borderTopWidth: SpinnerWidth,
    borderBottomWidth: SpinnerWidth,
    borderTopColor: SpinnerColor,
});

export const CenteredSpinner = () => {
    return <div style={{width: '100vw', height: '100vh', textAlign: 'center', verticalAlign: 'middle', display: 'table-cell'}}>
        <div style={{display: 'inline-block'}}>
            <XLSpinner size={SIZE.large}/>
        </div>
    </div>
}