import firebase from 'firebase';


class FirebaseSyncDAL {
  constructor(orgId) {
    this.orgId = orgId;
  }

  async setPole(id, pole) {
    await (this.getDocRef()
      .collection("poles")
      .doc(id)
      .set(
        {
          ...pole,
          id,
          updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp()
        },
        { merge: true }
      ));
  }

  async updateProject(id, projectModifications) { 
    await (this.getDocRef()
      .collection("projects")
      .doc(id)
      .update(
        {
          ...projectModifications,
          updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp()
        }));
  }

  async updatePole(id, poleModifications) {
    await (this.getDocRef()
      .collection("poles")
      .doc(id)
      .update(
        {
          ...poleModifications,
          updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp()
        }));
  }

  async setProject(id, project) {
    await (this.getDocRef()
      .collection("projects")
      .doc(id)
      .set(
        {
          ...project,
          updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp()
        },
        { merge: true }
      ));
  }

  async getAllServerProjects(sinceTimestamp) {
    console.log("Getting projects from server...");
    let projects = {};

    
    let query = this.getDocRef().collection("projects");
    if (sinceTimestamp) {
      console.log('since timestamp', sinceTimestamp)
      query = query.where('updatedTimestamp', '>=', firebase.firestore.Timestamp.fromDate(sinceTimestamp));
    }

    await query.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        projects[doc.id] = doc.data();
      });
    });
    return projects;
  }

  async getAllServerPoles(sinceTimestamp) {
    console.log("Getting poles from server...");
    let poles = {};
    let query = this.getDocRef().collection("poles");
    if (sinceTimestamp) {
      console.log('since timestamp', sinceTimestamp)
      query = query.where('updatedTimestamp', '>=', firebase.firestore.Timestamp.fromDate(sinceTimestamp));
    }

    await query.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        poles[doc.id] = doc.data();
      });
    });
    return poles;
  }

  getDocRef() {
    return firebase
      .firestore()
      .collection("organizations")
      .doc(this.orgId)
  }
}

export async function isOnline() {
  return Promise.resolve(navigator.onLine)
}

export async function serverComm(url, data, options) {
    console.log('Server Comms:')
    console.log(url)

    console.log('Local Change Data: ')
    console.log(data)
    console.log('Options: ')
    console.log(options)

    let syncDal = new FirebaseSyncDAL(url);
    

    // TYPE 1 = add
    // TYPE 2 = modify
    // TYPE 3 = delete

    for (let datapoint of data.changes) {
      if (datapoint.table == 'projects' && datapoint.type == 1) {
        await syncDal.setProject(datapoint.key, datapoint.obj)
        console.log("Pushed Project: ", datapoint.obj)
      } else if (datapoint.table == 'poles' && datapoint.type == 1) {
        await syncDal.setPole(datapoint.key, datapoint.obj)
        console.log("Pushed Pole: ", datapoint.obj)
      } else if (datapoint.table == 'poles' && datapoint.type == 2) {
        await syncDal.updatePole(datapoint.key, datapoint.mods)
        console.log("Updated Pole: ", datapoint.mods)
      } else if (datapoint.table == 'projects' && datapoint.type == 2) {
        await syncDal.updateProject(datapoint.key, datapoint.mods)
        console.log("Updated Project: ", datapoint.mods)
      }
    }

    let sinceTimestamp = data.syncedRevision
    if (sinceTimestamp) {
      sinceTimestamp.setHours(sinceTimestamp.getHours() - 2);
    }

    //Now we build the list of changes from the server - Get everything since the last time we synced. 
    let projects = await syncDal.getAllServerProjects(data.syncedRevision);
    let poles = await syncDal.getAllServerPoles(data.syncedRevision);
    console.log("Poles from server: ")
    console.log(poles)
    console.log("Projects from server: ")
    console.log(projects)

    //The server always keeps old / deleted data. But the client doesn't need to.
    let changes = []
    for (let projectId in projects) {
      let type = 1;
      if (projects[projectId].deletedTimestamp && projects[projectId].deleted == "1") {
        type = 3;
      } 
      changes.push({key: projectId, obj: projects[projectId], table: 'projects', type})
    }

    for (let poleId in poles) {
      let type = 1;
      if (poles[poleId].deletedTimestamp && poles[poleId].deleted == "1") {
        type = 3;
      } 
      changes.push({key: poleId, obj: poles[poleId], table: 'poles', type})
    }
    
    return Promise.resolve({"success": true, changes})
  }
