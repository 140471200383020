import React from "react";
export default ({ ref = window, condition = document.activeElement === ref.current, trigger, func }) => {
  React.useEffect(() => {
    const { current: element } = ref;
    const execute = e => (condition && e.code === trigger) && func();

    const isSupported = element && !!element.addEventListener;
    if (!isSupported) return;

    if (element) element.addEventListener('keydown', execute);
    return () => element.removeEventListener('keydown', execute);
  }, [condition, func, trigger, ref]);
}
