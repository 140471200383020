import React from 'react';

const IsMobile = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  React.useLayoutEffect(() => {
    const listener = window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => window.removeEventListener('resize', listener);
  });
  return width < 600;
}

export default IsMobile;