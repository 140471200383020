import { AnalyzePole, calcAnsiDiameter, getANSIFiberStrength, SectionCalculator } from '@edm-internationa/d-calc-calculations';
import SyncClientDB from "../components/database/SyncClientDB";
import inputs from '../components/inputs/inputs';
import { PrintLocal } from './print';
import { setCurrentDamageIndex } from './ui';

export const SET_POLE = 'SET_POLE';
export const SET_INFO = 'SET_INFO';
export const SET_PROJECT_ID = 'SET_PROJECT_ID';
export const SET_DAMAGE_VALUE = 'SET_DAMAGE_VALUE';
export const SET_ANGLE = 'SET_ANGLE';
export const ADD_DAMAGE = 'ADD_DAMAGE';
export const REMOVE_DAMAGE = 'REMOVE_DAMAGE';
export const SET_DAMAGE_PARAMETERS = 'SET_DAMAGE_PARAMETERS';
export const SET_OUTPUT = 'SET_OUTPUT';


export const setProjectId = (projectId) => ({
    type: SET_PROJECT_ID,
    projectId,
});

export const setInfo = (info) => {
    return async (dispatch) => {
        if (info.ansiSpecies) {
            info.fiberStrength = getANSIFiberStrength(info.ansiSpecies.name);
        }

        dispatch({
            type: SET_INFO,
            info,
        });

        dispatch(calculatePole());
    }
}

export const calculatePole = () => {
    return async (dispatch, getState) => {
        for (let level = 1; level < 4; level++) {
            const { damages } = getState().pole.damageParameters[level];
            if (damages.length) dispatch(calculateSlice(level));
        }
    }
};

export const setPole = pole => ({
    type: SET_POLE,
    pole,
})

export const setDamageAngle = (angle) => {
    return async (dispatch, getState) => {
        let state = getState();
        const currentDamageIndex = state.ui.currentDamageIndex;
        const section = state.pole.damageParameters[state.ui.currentLevel];
        const currentDamageValue = section.damages[currentDamageIndex] || {};
        const currentInput = inputs?.find(input => input.type === currentDamageValue.type);

        if (currentInput) {
            let unitField = currentInput.fields.find(elem => elem.unit === 'deg');
            if (unitField) {
                dispatch({
                    type: SET_DAMAGE_VALUE,
                    level: state.ui.currentLevel,
                    damageIndex: currentDamageIndex,
                    damage: { [unitField.fieldId]: angle },
                })
            }
        }
    }
}

export const printPole = (callback) => {
    return async (dispatch, getState) => {
        let state = getState();
        console.log(state.pole)

        let projectName = '';
        if (state.pole.projectId) {
            let idb = SyncClientDB.getIDB();
            let projectId = state.pole.projectId

            let project = await idb.projects.get(projectId);
            if (project && project.name) {
                projectName = project.name;
            }
        }

        PrintLocal([{ ...state.pole, info: { ...state.pole.info, projectId: projectName } }]);
    }
}

export const setDamageValue = (level, damageIndex, damage, clear) => ({
    type: SET_DAMAGE_VALUE,
    level,
    damageIndex,
    damage,
    clear
})

export const addDamage = (level, damage) => {
    return async (dispatch, getState) => {
        const state = getState();
        const damages = state.pole.damageParameters[state.ui.currentLevel].damages;

        dispatch({
            type: ADD_DAMAGE,
            level,
            damage,
        });
        dispatch(setCurrentDamageIndex(damages.length))
    }
}


export const removeDamage = (level, damageIndex) => {
    return (dispatch) => {
        dispatch(setCurrentDamageIndex(0))
        dispatch({
            type: REMOVE_DAMAGE,
            level,
            damageIndex,
        });
    }
}

export const calculateSlice = (level) => {
    return async (dispatch, getState) => {
        try {
            const pole = getState().pole;
            const damageParameters = pole.damageParameters[level];
            let raw = new SectionCalculator().calculateLevel(damageParameters);
            const ansiDiameter = getAnsiDiameter(pole.info, damageParameters);
            let analyze = new AnalyzePole(damageParameters, ansiDiameter);
            let output = analyze.calculateForAPI(raw);

            if (ansiDiameter !== undefined) {
                output.ansiDiameter = ansiDiameter;
            } else {
                output.ansiDiameter = 0;
            }

            output.svg = raw.svg;

            dispatch(setOutput(level, output));
        } catch (e) {
            console.log(e)
        }
    }
}

export const getAnsiDiameter = (info, damageParameters) => {
    if (damageParameters.distanceFromGround !== undefined) {
        const poleInfo = info;
        if (poleInfo && poleInfo.ansiSpecies && poleInfo.ansiLength && poleInfo.ansiClass) {
            let ansiDiameter = calcAnsiDiameter(poleInfo.ansiSpecies.name, poleInfo.ansiClass, poleInfo.ansiLength, poleInfo.isLinearTaper, poleInfo.userEmbedment, damageParameters.distanceFromGround);
            return ansiDiameter.ansiDiameter;
        }
    }
}

export const setDamageParameters = (level, damageParameters) => {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_DAMAGE_PARAMETERS,
            level,
            damageParameters,
        });

        dispatch(calculateSlice(level))
    }
}

export const setOutput = (level, output) => ({
    type: SET_OUTPUT,
    level,
    output,
});

