import firebase from 'firebase';
import React from "react";
import { Redirect } from "react-router-dom";
import Firebase from "../firebase/Firebase";

export const Logout = () => {
    const [loggingOut, setLoggingOut] = React.useState(true);

    React.useEffect(() => {
        const logout = async () => {
            if (firebase.auth().currentUser) {
                await Firebase.signOut();
            }
            setLoggingOut(false)
        }

        logout();
    }, []);

    if (!loggingOut) return <Redirect to={'/'} />

    return <div></div>
}