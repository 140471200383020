import { useStyletron, withStyle } from 'baseui';
import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import ChevronDown from 'baseui/icon/chevron-down';
import ChevronRight from 'baseui/icon/chevron-right';
import Overflow from 'baseui/icon/overflow';
import { StatefulMenu } from "baseui/menu";
import { Popover } from "baseui/popover";
import {
    StyledCell
} from 'baseui/table';
import React from 'react';
import ConnectedPoles from './Pole';


const CenteredBodyCell = withStyle(StyledCell, {});

const Project = ({ row, editProject, deleteProject, addPole, searchString, deletePole }) => {
    const [expanded, setExpanded] = React.useState(false);
    const [isActionMenuOpen, setIsActionMenuOpen] = React.useState(false);
    const [css] = useStyletron();

    return (
        <div role="row" className={css({ display: 'contents' })}>

            <CenteredBodyCell>
                <Button
                    size="compact"
                    kind="minimal"
                    onClick={() => setExpanded(!expanded)}
                    shape="square"
                    style={{ marginTop: "-5px" }}
                >
                    {expanded ? (
                        <ChevronDown size={18} />
                    ) : (
                        <ChevronRight size={18} />
                    )}
                </Button>
                {row[0]}</CenteredBodyCell>
            <StyledCell>{row[1]}</StyledCell>
            <CenteredBodyCell>{row[2]}</CenteredBodyCell>
            <CenteredBodyCell>{row[3]}</CenteredBodyCell>
            <CenteredBodyCell>
                <Block>
                    <Popover
                        onClick={() => setIsActionMenuOpen(!isActionMenuOpen)}
                        onClickOutside={() => setIsActionMenuOpen(!isActionMenuOpen)}
                        isOpen={isActionMenuOpen}
                        content={() => (
                            <StatefulMenu
                                items={[
                                    { label: "Add Pole" },
                                    { label: "Edit Project" },
                                    { label: "Delete Project" },
                                ]}
                                onItemSelect={async (event) => {
                                    if (event.item.label === 'Delete Project') {
                                        deleteProject(row.id);
                                        setIsActionMenuOpen(false);
                                    } else if (event.item.label === 'Add Pole') {
                                        addPole()
                                    } else if (event.item.label === 'Edit Project') {
                                        editProject()
                                    }
                                }}
                            />
                        )}
                        returnFocus
                        autoFocus
                    >
                        <Button shape="square" kind="minimal" size="compact">
                            <Overflow size={18} />
                        </Button>
                    </Popover>
                </Block>
            </CenteredBodyCell>
            {expanded && <ConnectedPoles projectId={row[5]} projectName={row[0]} searchString={searchString} deletePole={deletePole} poles={row[4]} />}
        </div>
    )
}

export default Project;