import { CLEAR_ERRORS, SET_CURRENTLY_EDITING_ANGLE, SET_CURRENT_DAMAGE_INDEX, SET_CURRENT_LEVEL, SET_CURRENT_POLE_PROJECT_NAME, SET_LOADING, SET_LOADING_USER_DATA, SET_MENU_OPEN, SET_MODAL, SET_SAVE_DIRTY, SET_SYNCING, UPDATE_ERRORS } from "../actions/ui";

function getDefaultState() {
    return {
        loading: true, //Loading User
        loadingUserData: true,
        menuOpen: false,
        sync: true,
        currentLevel: "1", //UI on pole
        currentDamageIndex: 0, //UI on pole
        projectName: '', //UI on pole
        saveDirty: false,
        currentlyEditingAngle: false,
        errors: {},
        modal: undefined,
    }
}

export const ui = (state = getDefaultState(), action) => {
    switch (action.type) {
        case SET_CURRENT_LEVEL:
            return { ...state, currentLevel: action.level, currentDamageIndex: 0 }
        case SET_SAVE_DIRTY:
            return { ...state, saveDirty: action.isDirty }
        case CLEAR_ERRORS:
            return { ...state, errors: {} }
        case UPDATE_ERRORS:
            return { ...state, errors: { ...state.errors, ...action.errors } }
        case SET_CURRENT_DAMAGE_INDEX:
            return { ...state, currentDamageIndex: action.damageIndex }
        case SET_CURRENT_POLE_PROJECT_NAME:
            return { ...state, projectName: action.projectName, projectId: action.projectId }
        case SET_CURRENTLY_EDITING_ANGLE:
            return {
                ...state,
                currentlyEditingAngle: action.isEditing
            };
        case SET_LOADING_USER_DATA:
            return { ...state, loadingUserData: action.loading }
        case SET_LOADING:
            return { ...state, loading: action.loading }
        case SET_MENU_OPEN:
            return {
                ...state,
                menuOpen: action.open
            };
        case SET_MODAL:
            return {
                ...state,
                modal: action.modal
            }
        case SET_SYNCING: {
            return { ...state, sync: action.sync }
        }
        default:
            return state;
    }
}