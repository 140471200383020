import React from "react";
import { connect } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { setFirebase } from "./actions/firebase";
import { setLoading, setLoadingUserData } from "./actions/ui";
import { loadUserData, setClaims, setOrgData, setReturnURLDetail, setUserDetails } from "./actions/user";
import { ConnectedAssess } from "./components/Assess/Assess";
import CreatePole from "./components/Assess/CreatePole";
import ViewPole from "./components/Assess/ViewPole";
import Database from "./components/Database";
import BillingPage from "./components/account/Billing";
import OnboardingPage from "./components/account/Onboard";
import PaymentSuccessPage from "./components/account/PaymentSuccessPage";
import TOS from "./components/account/TOS.jsx";
import SyncClientDB from "./components/database/SyncClientDB";
import { NavigationWrapper } from "./components/layout/Page";
import * as Routes from "./components/navigation/Sidebar";
import { CenteredSpinner } from "./components/transition/CenteredSpinner";
import Firebase from "./firebase/Firebase";
import UserApi from "./firebase/UserApi";
import { AdminPage } from "./pages/AdminPage";
import LoginPage from "./pages/Login";
import { Logout } from "./pages/LogoutPage";
import { RegisterPage } from "./pages/RegisterPage";
import { SignUp } from "./pages/SignUp";
import { withSearchString } from "./pages/URLTracker";

const App = ({ loggedIn, loading, setUserDetails, setClaims, setOrgData, setLoadingUserData }) => {
  const [loadingClaims, setLoadingClaims] = React.useState(true);

  React.useEffect(() => {
    let claimsListener;
    let authListener = Firebase.auth.onAuthStateChanged(

      async authUser => {
        console.log('Auth User Loaded.')
        if (authUser) {
          const lastSignInTime = new Date(authUser.metadata.lastSignInTime);
          const lastSignInTimeTimeStamp = Math.round(lastSignInTime.getTime() / 1000);
          const thirtyDaysAgoTimestamp = Math.round(new Date().getTime() / 1000) - (24 * 3600 * 30);
          if (lastSignInTimeTimeStamp < thirtyDaysAgoTimestamp) {
            Firebase.signOut();
          }

          let idb = SyncClientDB.getIDB();
          
            let userClaimsFromIdb = await idb.claims.where('userId').equals(authUser.uid).toArray();
            if (userClaimsFromIdb.length) {
              setClaims(userClaimsFromIdb[0])
              setLoadingClaims(false)
          }

          UserApi.claimSnapshotListener(authUser, async (claims) => {
            setClaims(claims);
            await idb.claims.put({
              ...claims,
              userId: authUser.uid,
            });

            if (claims.orgId) {
              UserApi.getOrganizationDetails(claims.orgId).then((e) => {
                setOrgData(e);
                setLoadingClaims(false)
              }).catch(e => {
                console.log('failed');
                setLoadingClaims(false)
              });
            } else {
              setLoadingClaims(false)
            }
          }).then(e => {
            claimsListener = e
            setLoadingClaims(false)
          }).catch(() => {
            setLoadingClaims(false)
          });
        } else {
          setLoadingClaims(false);
        }
        setUserDetails(authUser ? authUser : null);
      });
    return () => {
      if (claimsListener) {
        claimsListener();
      }
      authListener();
    }
  }, []);

  if (loading || loadingClaims) return <CenteredSpinner />;
  if (!loggedIn) return <Login />;
  return <ConnectedConsole />;
};

const Login = () => (
  <BrowserRouter>
    <Switch>
      <Route path={Routes.ROUTE_LOGIN}>
        <LoginPage />
      </Route>
      <Route path={Routes.ROUTE_REGISTER_CODE}>
        <RegisterPage />
      </Route>
      <Route path={Routes.ROUTE_LOGOUT}>
        <Logout />
      </Route>
      <Route path={Routes.ROUTE_SIGNUP}>
        <SignUp />
      </Route>
      <Route path='*'>
        <LoginPage />
      </Route>
    </Switch>
  </BrowserRouter>
);

const Console = ({ loadUserData, claims, userData, loadingData, setReturnURLDetail }) => {
  React.useEffect(() => {
    loadUserData();
    let url = window.location.href;
    setReturnURLDetail({ url })
  }, []);
  if (loadingData) {
    return <BrowserRouter>
      <Switch>
        <Route path={Routes.ROUTE_LOGOUT}>
          <Logout />
        </Route>
        <Route path='*'>
          <CenteredSpinner />
        </Route>
      </Switch>
    </BrowserRouter>
  }

  if (!userData.acceptedPrivacyPolicy || !userData.acceptedTOS || !userData.acceptedSourceCodeLicense) {
    return <BrowserRouter>
      <Switch>
        <Route path={Routes.ROUTE_TOS}>
          <TOS />
        </Route>
        <Route path={Routes.ROUTE_LOGOUT}>
          <Logout />
        </Route>
        <Redirect to={withSearchString(Routes.ROUTE_TOS)} />
      </Switch>
    </BrowserRouter>
  }

  if (!Object.keys(claims).length) {
    return <CenteredSpinner />
  }

  if (!claims.licensed) {
    return <BrowserRouter>
      <Switch>
        <Route path={Routes.ROUTE_ACCOUNT}>
          <NavigationWrapper><BillingPage /></NavigationWrapper>
        </Route>
        <Route path={Routes.ROUTE_ONBOARD}>
          <NavigationWrapper><OnboardingPage /></NavigationWrapper>
        </Route>
        <Route path={Routes.ROUTE_REGISTER_CODE}>
          <RegisterPage />
        </Route>
        <Route path={Routes.ROUTE_PAYMENT_SUCCESS}>
          <PaymentSuccessPage />
        </Route>
        <Route path={Routes.ROUTE_LOGOUT}>
          <Logout />
        </Route>
        {
          claims.susr ?
            <Route path={Routes.ROUTE_ADMIN}>
              <NavigationWrapper><AdminPage /></NavigationWrapper>
            </Route> : undefined
        }
        <Redirect to={window.location.search.startsWith('?orgId') ? Routes.ROUTE_REGISTER_CODE + window.location.search : (claims.admin ? Routes.ROUTE_ACCOUNT : Routes.ROUTE_ONBOARD)} />
      </Switch>
    </BrowserRouter>
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path={Routes.ROUTE_REGISTER_CODE}>
          <RegisterPage />
        </Route>
        <Route path={Routes.ROUTE_LOGOUT}>
          <Logout />
        </Route>
        <Route path={Routes.ROUTE_MODEL}>
          <NavigationWrapper><ConnectedAssess /></NavigationWrapper>
        </Route>
        <Route path={Routes.ROUTE_CREATEPOLE}>
          <NavigationWrapper>
            <CreatePole />
            <Redirect to={Routes.ROUTE_MODEL} />
          </NavigationWrapper>
        </Route>
        <Route path={Routes.ROUTE_VIEWPOLE}>
          <NavigationWrapper>
            <ViewPole />
            <Redirect to={Routes.ROUTE_MODEL} />
          </NavigationWrapper>
        </Route>
        <Route path={Routes.ROUTE_ONBOARD}>
          <NavigationWrapper><OnboardingPage /></NavigationWrapper>
        </Route>
        <Route path={Routes.ROUTE_ACCOUNT}>
          <NavigationWrapper><BillingPage /></NavigationWrapper>
        </Route>
        <Route path={Routes.ROUTE_DATABASE}>
          <NavigationWrapper><Database /></NavigationWrapper>
        </Route>
        {
          claims.susr ?
            <Route path={Routes.ROUTE_ADMIN}>
              <NavigationWrapper><AdminPage /></NavigationWrapper>
            </Route> : undefined
        }
        <Route path={Routes.ROUTE_PAYMENT_SUCCESS}>
          <PaymentSuccessPage />
        </Route>
        <Route path={Routes.ROUTE_SIGNUP}>
          <SignUp />
        </Route>
        <Redirect to={Routes.ROUTE_MODEL} />
      </Switch>
    </BrowserRouter>
  );
};

const ConnectedConsole = connect(state => ({
  claims: state.user.claims,
  userData: state.user.userData,
  loadingData: state.ui.loadingUserData,
}), { loadUserData, setReturnURLDetail })(Console);

export default connect(
  (state) => ({
    loading: state.ui.loading,
    loggedIn: state.user.loggedIn,
  }),
  {
    setLoading,
    setFirebase,
    setUserDetails,
    setClaims,
    setOrgData,
    setLoadingUserData
  }
)(App);
