import { FormControl } from 'baseui/form-control';
import React from 'react';
import { connect } from 'react-redux';
import { setDamageParameters } from '../../actions/pole';
import { setCurrentLevel } from '../../actions/ui';
import CircumferenceOrDiameter from './CircumferenceOrDiameter';

const PoleDimensionEditor = ({ damageParameters, setDamageParameters, currentLevel, poleDiameterData, poleCircumferenceData }) => {
    const handleChange = (value) => setDamageParameters(currentLevel, value)
    return (
        <>
            <FormControl label={"Circumference or Diameter:"}>
                <CircumferenceOrDiameter handleChange={handleChange} diameter={damageParameters?.diameter} />
            </FormControl>
        </>
    );
}

export default connect(state => {
    let currentLevel = state.ui.currentLevel;

    return {
        info: state.pole.info,
        ansiDiameter: state.pole.output[currentLevel]?.ansiDiameter,
        currentLevel: currentLevel,
        damageParameters: state.pole.damageParameters[currentLevel]
    }
}, {
    setCurrentLevel,
    setDamageParameters
})(PoleDimensionEditor);