import { validateDamage } from "@edm-internationa/d-calc-calculations";
import SyncClientDB from "../components/database/SyncClientDB";
import { addPole, savePole } from "./poles";

export const SET_LOADING = 'SET_LOADING';
export const SET_LOADING_USER_DATA = 'SET_LOADING_USER_DATA';
export const SET_MENU_OPEN = 'SET_MENU_OPEN';
export const SET_SYNCING = 'SET_SYNCING';
export const UPDATE_ERRORS = 'UPDATE_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_CURRENT_LEVEL = 'SET_CURRENT_LEVEL';
export const SET_SAVE_DIRTY = 'SET_SAVE_DIRTY';
export const SET_CURRENT_DAMAGE_INDEX = 'SET_CURRENT_DAMAGE_INDEX';
export const SET_CURRENT_POLE_PROJECT_NAME = 'SET_CURRENT_POLE_PROJECT_NAME';
export const SET_CURRENTLY_EDITING_ANGLE = 'SET_CURRENTLY_EDITING_ANGLE';
export const SET_MODAL = 'SET_MODAL';

export const setCurrentDamageIndex = (damageIndex) => ({
    type: SET_CURRENT_DAMAGE_INDEX,
    damageIndex
});

export const openModal = (modalType, callback) => ({
    type: SET_MODAL,
    modal: {
        type: modalType,
        callback
    }
});

export const closeModal = (data) => {
    return (dispatch, getState) => {
        let currentState = getState();
        if (currentState.ui.modal && currentState.ui.modal.callback) {
            currentState.ui.modal.callback(dispatch, getState, data);
        }

        dispatch({
            type: SET_MODAL,
            modal: undefined,
        });
    }
}

export const setCurrentlyEditingAngle = (isEditing) => ({
    type: SET_CURRENTLY_EDITING_ANGLE,
    isEditing
});

export const setIsSaveDirty = (isDirty) => ({
    type: SET_SAVE_DIRTY,
    isDirty
});

export const setCurrentLevel = (level) => ({
    type: SET_CURRENT_LEVEL,
    level
});

export const updateErrors = (errors) => ({
    type: UPDATE_ERRORS,
    errors
});

export const clearErrors = () => ({
    type: CLEAR_ERRORS,
});

export const setProject = (projectName, projectId) => ({
    type: SET_CURRENT_POLE_PROJECT_NAME,
    projectName,
    projectId,
});

export const setLoading = (loading) => ({
    type: SET_LOADING,
    loading
});

export const setLoadingUserData = (loading) => ({
    type: SET_LOADING_USER_DATA,
    loading
});

export const setMenuOpen = (open) => ({
    type: SET_MENU_OPEN,
    open
});

export const newPole = (successCallback) => {
    return (dispatch, getState) => {
        let state = getState();
        let pole = state.pole;
        let errorType = getDamageErrors(pole);

        if (errorType) {
            if (!getOneHasDiameter(pole.damageParameters)) {
                dispatch(openModal('diameterNeeded'))
            } else {
                dispatch(openModal(errorType))
            }
        } else if (pole.projectId) {
            dispatch(addPole());
            successCallback()
        } else {
            dispatch(openModal('unsavedWork'))
        }
    }
}

export const savePoleTrigger = (successCallback) => {
    return (dispatch, getState) => {
        let state = getState();
        let pole = state.pole;
        let errorType = getDamageErrors(pole);

        if (errorType) {
            if (!getOneHasDiameter(pole.damageParameters)) {
                dispatch(openModal('diameterNeeded'))
            } else {
                dispatch(openModal(errorType))
            }
        } else if (!pole.projectId) {
            dispatch(openModal('projectNeeded'))
        } else {
            dispatch(savePole(successCallback))
        }
    }
}

function getDamageErrors(pole) {
    return getType(getOneHasDiameter(pole.damageParameters), getAllDamagesValid(pole.damageParameters))
}

function getType(hasOneDiameterValid, damagesValid) {
    if (!hasOneDiameterValid) {
        return 'diameter';
    } else if (!damagesValid) {
        return 'damages';
    }
}

function getAllDamagesValid(damageParameters) {
    for (let i = 1; i <= 3; i++) {
        let section = damageParameters[i]
        for (let j = 0; j < section.damages.length; j++) {
            let validationResponse = validateDamage(section.damages[j], section)
            if (!validationResponse.isValid) {
                return false;
            }
        }
    }
    return true;
}

function getOneHasDiameter(damageParameters) {
    for (let i = 1; i <= 3; i++) {
        if (damageParameters[i].diameter > 0) {
            return true;
        }
    }
    return false;
}


export const setSyncing = (sync) => {
    return async (dispatch, getState) => {
        dispatch({ type: SET_SYNCING, sync });
    }
}
export const toggleSync = () => {
    return async (dispatch, getState) => {
        const sync = !getState().ui.sync;
        const claims = getState().user.claims;
        console.log('myclaims')
        console.log(claims)
        dispatch(setSyncing(sync))
        SyncClientDB.setShouldSync(sync, claims.orgId)
    }
}
