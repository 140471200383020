import { AnalyzePole } from '@edm-internationa/d-calc-calculations';
import { Block } from "baseui/block";
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { Cell } from 'baseui/layout-grid';
import { KIND, Notification } from 'baseui/notification';
import { Label3, Label4 } from "baseui/typography";
import React from "react";
import { connect } from "react-redux";
import { setInfo } from '../../actions/pole';
import { NotificationOverride } from '../../theming';
import { PoleSelection } from '../PoleSelection';
import { Spacer } from '../layout/Spacer';
import { ConnectedLevelChooser } from './LevelChooser';
import { CARD_STYLING } from './styles';

const DataLabel = ({ children, width }) => <Block width={width || "30%"}>
    <Label4>{children}</Label4>
</Block>

const AssessTable = ({ data, title }) => {
    return <div style={{ maxWidth: '400px' }}>
        <Block width='100%' display='flex' justifyContent='space-between' padding='5px' alignItems="center">
            <Block width='50%'>
                <Label3><b>{title}</b></Label3>
            </Block>
            <Block width='25%'>
                <Label3><b>Inline:</b></Label3>
            </Block>
            <Block width='25%'>
                <Label3><b>Transverse:</b></Label3>
            </Block>
        </Block>
        <Block height='1px' width='100%' margin='5px' backgroundColor='#f7f7f7' />
        {data.map(({ label, values: [inline, transverse] }, i) =>
            <Block display="flex" flexDirection='column' width='100%' padding='5px' backgroundColor={i % 2 === 1 ? '#f7f7f7' : '#fff'} key={i}>
                <Block display="flex" flexDirection='row' justifyContent='space-between' alignItems='center' key={label}>
                    <DataLabel width="50%">{label}</DataLabel>
                    <DataLabel width="25%">{inline}</DataLabel>
                    <DataLabel width="25%">{transverse}</DataLabel>
                </Block>
            </Block>
        )}
        <Spacer />
        <Spacer />
    </div>
};

const PercentRemaining = ({ output, damageParameters, ansiDiameter }) => {
    let ap = new AnalyzePole(damageParameters, ansiDiameter);
    const result = ap.calculatePercent(output.actual);
    const data = [
        { label: 'Section Modulus:', values: [result.polePercentSlopeY, result.polePercentSlopeX] },
        { label: 'ANSI Section Modulus:', values: [result.ansiPercentSlopeY, result.ansiPercentSlopeX] },

        { label: 'Moment of Inertia:', values: [result.polePercentMomentY, result.polePercentMomentX] },
        { label: 'ANSI Moment of Inertia:', values: [result.ansiPercentMomentY, result.ansiPercentMomentX] },

        { label: 'Area:', values: [result.polePercentArea] },
        { label: 'ANSI Area:', values: [result.ansiPercentArea] },
    ]

    return (
        <AssessTable
            title={"Percent Remaining"}
            data={data} />
    );
}

const ActualValues = ({ output, damageParameters, ansiDiameter }) => {
    let ap = new AnalyzePole(damageParameters, ansiDiameter)
    console.log(output.actual)
    const result = ap.calculateActual(output.actual);
    const data = [
        { label: 'Section Modulus (in³):', values: [result.poleActualMomentSlopeY, result.poleActualMomentSlopeX] },
        { label: 'Original Section Modulus (in³):', values: [result.originalMomentSlopeY, result.originalMomentSlopeX] },
        { label: 'ANSI Section Modulus (in³):', values: [result.ansiMomentSlopeY, result.ansiMomentSlopeX] },

        { label: 'Moment of Inertia (in⁴):', values: [result.poleActualMomentInertiaY, result.poleActualMomentInertiaX] },
        { label: 'Original Moment of Inertia (in⁴):', values: [result.originalMomentInertiaY, result.originalMomentInertiaX] },
        { label: 'ANSI Moment of Inertia (in⁴):', values: [result.ansiMomentInertiaY, result.ansiMomentInertiaX] },

        { label: 'Area (in²):', values: [result.poleArea] },
        { label: 'Original Area (in²):', values: [result.originalArea] },
        { label: 'ANSI Area (in²):', values: [result.ansiArea] },
    ]
    return (
        <AssessTable
            title={"Actual Values"}
            data={data} />
    );
}

const PercentStrength = ({ output, damageParameters, ansiDiameter, fiberStrength }) => {
    const { result } = (new AnalyzePole(damageParameters, ansiDiameter)).calculateStrength(output.actual, fiberStrength);

    const data = [
        { label: 'Section Modulus Remaining (in³):', values: [result.polePercentY, result.polePercentX] },
        { label: '% ANSI Section Modulus (in³):', values: [result.ansiPercentY, result.ansiPercentX] },

        { label: 'Remaining Strength (PSI):', values: [result.poleStrengthY, result.poleStrengthX] },
        { label: 'ANSI Remaining Strength (PSI):', values: [result.ansiStrengthY, result.ansiStrengthX] },
    ]
    return (
        <>
            <AssessTable
                title={"Percent Strength"}
                data={data} />
            <Cell span={[10, 10, 5]} skip={[0, 0, 4]}>
                <Spacer /><Spacer />
            </Cell>
        </>
    );
}

export const AnalyzeTab = ({ pole, setFiberStrength, currentLevel }) => {
    const output = pole.output[+currentLevel];
    const damageParameters = pole.damageParameters[+currentLevel];
    const { ansiDiameter } = pole.output[currentLevel]
    const props = { ansiDiameter, output, damageParameters, setFiberStrength, fiberStrength: pole.info.fiberStrength };
    const isPoleSelected = 'id' in pole.info;

    const handleChange = ({ target }) => setFiberStrength(target.value);

    if (!isPoleSelected) return <PoleSelection />

    return (
        <>
            <div style={CARD_STYLING}>
                <ConnectedLevelChooser />
                {damageParameters.diameter ? <><FormControl label="Fiber Strength">
                    <Input startEnhancer={<b>psi</b>} value={pole.info.fiberStrength} size="compact" onChange={handleChange} type='number' pattern={'[0-9]*'} inputMode='decimal' />
                </FormControl>
                    <div style={{}}>
                        <PercentRemaining {...{ ...props }} />
                        <ActualValues {...{ ...props }} />
                        <PercentStrength {...{ ...props }} />
                    </div></> :
                    <Notification overrides={NotificationOverride} closeable={false} kind={KIND.warning}>
                        Cannot display analysis until diameter is set for this level.
                    </Notification>
                }
            </div>
        </>
    );
};

export const ConnectedAnalyzeTab = connect((state) => ({ pole: state.pole, currentLevel: state.ui.currentLevel }), {
    setFiberStrength: (fiberStrength) => setInfo({ fiberStrength })
})(AnalyzeTab);
