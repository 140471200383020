import { AnalyzePole, SectionCalculator, TYPE_BOLT, TYPE_CAVITY, TYPE_HEART, TYPE_POCKET, TYPE_SECTION, TYPE_SHELL, TYPE_SLICE, TYPE_WEDGE } from '@edm-internationa/d-calc-calculations';
import { getAnsiDiameter } from '../../actions/pole';
import { store } from "../../index";

const __fieldPosition = {
    [TYPE_HEART]: ["shellThickness"],
    [TYPE_SHELL]: ["damageDepth"],
    [TYPE_POCKET]: ["angleToMinShell", "pocketDiameter", "shellThickness"],
    [TYPE_WEDGE]: ["angleToDamage", "circumferenceMissing", "depthMissing"],
    [TYPE_SECTION]: ["angleToOpening", "depthMissing", "circumferenceMissing", "shellThickness"],
    [TYPE_SLICE]: ["angleToDamage", "depthMissing", "widthMissing"],
    [TYPE_CAVITY]: ["angleToOpening", "circumferenceMissing", "shellThicknessAtOpening", "nestDiameter"],
    [TYPE_BOLT]: ["angleToOpening", "holeDiameter"],
};

function convertAndParse(val) {
    if (val && val != '%') {
        console.log(val)
        let retVal = parseFloat(val) * .01;

        if (isNaN(retVal)) {
            return 0;
        }
        return retVal;
    }
    return 0;
}

function checkNaN(val) {

    if (isNaN(val)) {
        return 0;
    }
    return val
}

const returnURL = (urlStr) => {
    let poleData = { ...store.getState().pole };

    // Level 1 Calculations
    let ansiDiaL1 = getAnsiDiameter(poleData.info, poleData.damageParameters[1]);
    let apL1 = new AnalyzePole(poleData.damageParameters[1], ansiDiaL1);

    const rawL1 = new SectionCalculator().calculateLevel(poleData.damageParameters[1]);
    const resultActualL1 = apL1.calculatePercent(rawL1);

    let outputL1 = apL1.calculateForAPI(rawL1);

    if (ansiDiaL1 !== undefined) {
        outputL1.ansiDiameter = ansiDiaL1;
    } else {
        outputL1.ansiDiameter = 0;
    }

    outputL1.svg = rawL1.svg;


    const actualDetailL1 = outputL1.actual;
    const ansiDetailL1 = outputL1.ansi;
    const originalDetailL1 = outputL1.original;


    // Level 2 Calculations
    let ansiDiaL2 = getAnsiDiameter(poleData.info, poleData.damageParameters[1]);
    let apL2 = new AnalyzePole(poleData.damageParameters[2], ansiDiaL2)
    const rawL2 = new SectionCalculator().calculateLevel(poleData.damageParameters[2]);
    const resultActualL2 = apL2.calculatePercent(rawL2);

    let outputL2 = apL1.calculateForAPI(rawL2);

    if (ansiDiaL2 !== undefined) {
        outputL2.ansiDiameter = ansiDiaL2;
    } else {
        outputL2.ansiDiameter = 0;
    }

    outputL2.svg = rawL2.svg;
    const actualDetailL2 = outputL2.actual;
    const ansiDetailL2 = outputL2.ansi;
    const originalDetailL2 = outputL2.original;


    // Level 3 Calculations
    let ansiDiaL3 = getAnsiDiameter(poleData.info, poleData.damageParameters[1]);
    let apL3 = new AnalyzePole(poleData.damageParameters[3], ansiDiaL3);
    const rawL3 = new SectionCalculator().calculateLevel(poleData.damageParameters[3]);
    const resultActualL3 = apL3.calculatePercent(rawL3);

    let outputL3 = apL1.calculateForAPI(rawL3);

    if (ansiDiaL3 !== undefined) {
        outputL3.ansiDiameter = ansiDiaL3;
    } else {
        outputL3.ansiDiameter = 0;
    }

    outputL3.svg = rawL3.svg;

    const actualDetailL3 = outputL3.actual;
    const ansiDetailL3 = outputL3.ansi;
    const originalDetailL3 = outputL3.original;

    // Actual Calculations
    let minPercentageInActual1 = Math.min(convertAndParse(resultActualL1.polePercentSlopeX), convertAndParse(resultActualL1.polePercentSlopeY));
    let minPercentageInActual2 = Math.min(convertAndParse(resultActualL2.polePercentSlopeX), convertAndParse(resultActualL2.polePercentSlopeY));
    let minPercentageInActual3 = Math.min(convertAndParse(resultActualL3.polePercentSlopeX), convertAndParse(resultActualL3.polePercentSlopeY));
    // ANSI Calculations
    let minPercentageInANSI1 = Math.min(convertAndParse(resultActualL1.ansiPercentSlopeX), convertAndParse(resultActualL1.ansiPercentSlopeY));
    let minPercentageInANSI2 = Math.min(convertAndParse(resultActualL2.ansiPercentSlopeX), convertAndParse(resultActualL2.ansiPercentSlopeY));
    let minPercentageInANSI3 = Math.min(convertAndParse(resultActualL3.ansiPercentSlopeX), convertAndParse(resultActualL3.ansiPercentSlopeY));



    let returnParamsFilledData = urlStr
        .replace("${msx1_act}", checkNaN(actualDetailL1.momentSlopeX))
        .replace("${msy1_act}", checkNaN(actualDetailL1.momentSlopeY))
        .replace("${mix1_act}", checkNaN(actualDetailL1.momentInertiaX))
        .replace("${miy1_act}", checkNaN(actualDetailL1.momentInertiaY))
        .replace("${area1_act}", checkNaN(actualDetailL1.area))
        .replace("${rsmx1_act}", convertAndParse(resultActualL1.polePercentSlopeX))
        .replace("${rsmy1_act}", convertAndParse(resultActualL1.polePercentSlopeY))
        .replace("${rsm1_net_act}", checkNaN(minPercentageInActual1))
        .replace("${ansi_dia1}", checkNaN(poleData.output[1].ansiDiameter))
        .replace("${svg1}", poleData.output[1].svg)

        .replace("${msx2_act}", checkNaN(actualDetailL2.momentSlopeX))
        .replace("${msy2_act}", checkNaN(actualDetailL2.momentSlopeY))
        .replace("${mix2_act}", checkNaN(actualDetailL2.momentInertiaX))
        .replace("${miy2_act}", checkNaN(actualDetailL2.momentInertiaY))
        .replace("${area2_act}", checkNaN(actualDetailL2.area))
        .replace("${rsmx2_act}", convertAndParse(resultActualL2.polePercentSlopeX))
        .replace("${rsmy2_act}", convertAndParse(resultActualL2.polePercentSlopeY))
        .replace("${rsm2_net_act}", checkNaN(minPercentageInActual2))
        .replace("${ansi_dia2}", checkNaN(poleData.output[2].ansiDiameter))
        .replace("${svg2}", poleData.output[2].svg)

        .replace("${msx3_act}", checkNaN(actualDetailL3.momentSlopeX))
        .replace("${msy3_act}", checkNaN(actualDetailL3.momentSlopeY))
        .replace("${mix3_act}", checkNaN(actualDetailL3.momentInertiaX))
        .replace("${miy3_act}", checkNaN(actualDetailL3.momentInertiaY))
        .replace("${area3_act}", checkNaN(actualDetailL3.area))
        .replace("${rsmx3_act}", convertAndParse(resultActualL3.polePercentSlopeX))
        .replace("${rsmy3_act}", convertAndParse(resultActualL3.polePercentSlopeY))
        .replace("${rsm3_net_act}", checkNaN(minPercentageInActual3))
        .replace("${ansi_dia3}", checkNaN(poleData.output[3].ansiDiameter))
        .replace("${svg3}", poleData.output[3].svg)

        .replace("${msx1_ansi}", checkNaN(ansiDetailL1.momentSlopeX))
        .replace("${msy1_ansi}", checkNaN(ansiDetailL1.momentSlopeY))
        .replace("${mix1_ansi}", checkNaN(ansiDetailL1.momentInertiaX))
        .replace("${miy1_ansi}", checkNaN(ansiDetailL1.momentInertiaY))
        .replace("${area1_ansi}", checkNaN(ansiDetailL1.area))
        .replace("${rsmx1_ansi}", convertAndParse(resultActualL1.ansiPercentSlopeX))
        .replace("${rsmy1_ansi}", convertAndParse(resultActualL1.ansiPercentSlopeY))
        .replace("${rsm1_net_ansi}", checkNaN(minPercentageInANSI1))

        .replace("${msx2_ansi}", checkNaN(ansiDetailL2.momentSlopeX))
        .replace("${msy2_ansi}", checkNaN(ansiDetailL2.momentSlopeY))
        .replace("${mix2_ansi}", checkNaN(ansiDetailL2.momentInertiaX))
        .replace("${miy2_ansi}", checkNaN(ansiDetailL2.momentInertiaY))
        .replace("${area2_ansi}", checkNaN(ansiDetailL2.area))
        .replace("${rsmx2_ansi}", convertAndParse(resultActualL2.ansiPercentSlopeX))
        .replace("${rsmy2_ansi}", convertAndParse(resultActualL2.ansiPercentSlopeY))
        .replace("${rsm2_net_ansi}", checkNaN(minPercentageInANSI2))

        .replace("${msx3_ansi}", checkNaN(ansiDetailL3.momentSlopeX))
        .replace("${msy3_ansi}", checkNaN(ansiDetailL3.momentSlopeY))
        .replace("${mix3_ansi}", checkNaN(ansiDetailL3.momentInertiaX))
        .replace("${miy3_ansi}", checkNaN(ansiDetailL3.momentInertiaY))
        .replace("${area3_ansi}", checkNaN(ansiDetailL3.area))
        .replace("${rsmx3_ansi}", convertAndParse(resultActualL3.ansiPercentSlopeX))
        .replace("${rsmy3_ansi}", convertAndParse(resultActualL3.ansiPercentSlopeY))
        .replace("${rsm3_net_ansi}", checkNaN(minPercentageInANSI3))

        .replace("${msx1_org}", checkNaN(originalDetailL1.momentSlopeX))
        .replace("${msy1_org}", checkNaN(originalDetailL1.momentSlopeY))
        .replace("${mix1_org}", checkNaN(originalDetailL1.momentInertiaX))
        .replace("${miy1_org}", checkNaN(originalDetailL1.momentInertiaY))
        .replace("${area1_org}", checkNaN(originalDetailL1.area))

        .replace("${msx2_org}", checkNaN(originalDetailL2.momentSlopeX))
        .replace("${msy2_org}", checkNaN(originalDetailL2.momentSlopeY))
        .replace("${mix2_org}", checkNaN(originalDetailL2.momentInertiaX))
        .replace("${miy2_org}", checkNaN(originalDetailL2.momentInertiaY))
        .replace("${area2_org}", checkNaN(originalDetailL2.area))

        .replace("${msx3_org}", checkNaN(originalDetailL3.momentSlopeX))
        .replace("${msy3_org}", checkNaN(originalDetailL3.momentSlopeY))
        .replace("${mix3_org}", checkNaN(originalDetailL3.momentInertiaX))
        .replace("${miy3_org}", checkNaN(originalDetailL3.momentInertiaY))
        .replace("${area3_org}", checkNaN(originalDetailL3.area))

        .replace("${dia1}", checkNaN(poleData.damageParameters[1].diameter))
        .replace("${dia2}", checkNaN(poleData.damageParameters[2].diameter))
        .replace("${dia3}", checkNaN(poleData.damageParameters[3].diameter))

        .replace("${circ1}", checkNaN(poleData.damageParameters[1].diameter * Math.PI))
        .replace("${circ2}", checkNaN(poleData.damageParameters[2].diameter * Math.PI))
        .replace("${circ3}", checkNaN(poleData.damageParameters[3].diameter * Math.PI))

        .replace("${dist_gl1}", checkNaN(poleData.damageParameters[1].distanceFromGround))
        .replace("${dist_gl2}", checkNaN(poleData.damageParameters[2].distanceFromGround))
        .replace("${dist_gl3}", checkNaN(poleData.damageParameters[3].distanceFromGround))

        .replace("${ansiLength}", poleData.info.ansiLength)
        .replace("${ansiClass}", poleData.info.ansiClass)
        .replace("${ansiSpecies}", poleData.info.ansiSpecies)
        .replace("${description}", poleData.info.description)
        .replace("${fiberStrength}", poleData.info.fiberStrength)
        .replace("${isLinearTaper}", poleData.info.isLinearTaper)
        .replace("${poleName}", poleData.info.name)
        .replace("${userEmbedment}", poleData.info.userEmbedment)

        .replace("${poleId}", poleData.info.id)


        //L1 Damage Params
        .replace("${l1_d1_type}", poleData.damageParameters[1].damages[0]?.type)
        .replace("${l1_d1_param1}", getParam(poleData.damageParameters[1].damages[0], 1))
        .replace("${l1_d1_param2}", getParam(poleData.damageParameters[1].damages[0], 2))
        .replace("${l1_d1_param3}", getParam(poleData.damageParameters[1].damages[0], 3))
        .replace("${l1_d1_param4}", getParam(poleData.damageParameters[1].damages[0], 4))

        .replace("${l1_d2_type}", poleData.damageParameters[1].damages[1]?.type)
        .replace("${l1_d2_param1}", getParam(poleData.damageParameters[1].damages[1], 1))
        .replace("${l1_d2_param2}", getParam(poleData.damageParameters[1].damages[1], 2))
        .replace("${l1_d2_param3}", getParam(poleData.damageParameters[1].damages[1], 3))
        .replace("${l1_d2_param4}", getParam(poleData.damageParameters[1].damages[1], 4))

        .replace("${l1_d3_type}", poleData.damageParameters[1].damages[2]?.type)
        .replace("${l1_d3_param1}", getParam(poleData.damageParameters[1].damages[2], 1))
        .replace("${l1_d3_param2}", getParam(poleData.damageParameters[1].damages[2], 2))
        .replace("${l1_d3_param3}", getParam(poleData.damageParameters[1].damages[2], 3))
        .replace("${l1_d3_param4}", getParam(poleData.damageParameters[1].damages[2], 4))

        .replace("${l1_d4_type}", poleData.damageParameters[1].damages[3]?.type)
        .replace("${l1_d4_param1}", getParam(poleData.damageParameters[1].damages[3], 1))
        .replace("${l1_d4_param2}", getParam(poleData.damageParameters[1].damages[3], 2))
        .replace("${l1_d4_param3}", getParam(poleData.damageParameters[1].damages[3], 3))
        .replace("${l1_d4_param4}", getParam(poleData.damageParameters[1].damages[3], 4))


        //L2 Damage Params
        .replace("${l2_d1_type}", poleData.damageParameters[2].damages[0]?.type)
        .replace("${l2_d1_param1}", getParam(poleData.damageParameters[2].damages[0], 1))
        .replace("${l2_d1_param2}", getParam(poleData.damageParameters[2].damages[0], 2))
        .replace("${l2_d1_param3}", getParam(poleData.damageParameters[2].damages[0], 3))
        .replace("${l2_d1_param4}", getParam(poleData.damageParameters[2].damages[0], 4))

        .replace("${l2_d2_type}", poleData.damageParameters[2].damages[1]?.type)
        .replace("${l2_d2_param1}", getParam(poleData.damageParameters[2].damages[1], 1))
        .replace("${l2_d2_param2}", getParam(poleData.damageParameters[2].damages[1], 2))
        .replace("${l2_d2_param3}", getParam(poleData.damageParameters[2].damages[1], 3))
        .replace("${l2_d2_param4}", getParam(poleData.damageParameters[2].damages[1], 4))

        .replace("${l2_d3_type}", poleData.damageParameters[2].damages[2]?.type)
        .replace("${l2_d3_param1}", getParam(poleData.damageParameters[2].damages[2], 1))
        .replace("${l2_d3_param2}", getParam(poleData.damageParameters[2].damages[2], 2))
        .replace("${l2_d3_param3}", getParam(poleData.damageParameters[2].damages[2], 3))
        .replace("${l2_d3_param4}", getParam(poleData.damageParameters[2].damages[2], 4))

        .replace("${l2_d4_type}", poleData.damageParameters[2].damages[3]?.type)
        .replace("${l2_d4_param1}", getParam(poleData.damageParameters[2].damages[3], 1))
        .replace("${l2_d4_param2}", getParam(poleData.damageParameters[2].damages[3], 2))
        .replace("${l2_d4_param3}", getParam(poleData.damageParameters[2].damages[3], 3))
        .replace("${l2_d4_param4}", getParam(poleData.damageParameters[2].damages[3], 4))


        //L3 Damage Params
        .replace("${l3_d1_type}", poleData.damageParameters[3].damages[0]?.type)
        .replace("${l3_d1_param1}", getParam(poleData.damageParameters[3].damages[0], 1))
        .replace("${l3_d1_param2}", getParam(poleData.damageParameters[3].damages[0], 2))
        .replace("${l3_d1_param3}", getParam(poleData.damageParameters[3].damages[0], 3))
        .replace("${l3_d1_param4}", getParam(poleData.damageParameters[3].damages[0], 4))

        .replace("${l3_d2_type}", poleData.damageParameters[3].damages[1]?.type)
        .replace("${l3_d2_param1}", getParam(poleData.damageParameters[3].damages[1], 1))
        .replace("${l3_d2_param2}", getParam(poleData.damageParameters[3].damages[1], 2))
        .replace("${l3_d2_param3}", getParam(poleData.damageParameters[3].damages[1], 3))
        .replace("${l3_d2_param4}", getParam(poleData.damageParameters[3].damages[1], 4))

        .replace("${l3_d3_type}", poleData.damageParameters[3].damages[2]?.type)
        .replace("${l3_d3_param1}", getParam(poleData.damageParameters[3].damages[2], 1))
        .replace("${l3_d3_param2}", getParam(poleData.damageParameters[3].damages[2], 2))
        .replace("${l3_d3_param3}", getParam(poleData.damageParameters[3].damages[2], 3))
        .replace("${l3_d3_param4}", getParam(poleData.damageParameters[3].damages[2], 4))

        .replace("${l3_d4_type}", poleData.damageParameters[3].damages[3]?.type)
        .replace("${l3_d4_param1}", getParam(poleData.damageParameters[3].damages[3], 1))
        .replace("${l3_d4_param2}", getParam(poleData.damageParameters[3].damages[3], 2))
        .replace("${l3_d4_param3}", getParam(poleData.damageParameters[3].damages[3], 3))
        .replace("${l3_d4_param4}", getParam(poleData.damageParameters[3].damages[3], 4))

    console.log('Finalized url string', returnParamsFilledData)

    return encodeURI(returnParamsFilledData);
}

function getParam(damage, index) {
    if (!damage || !damage.type) return 0

    const keyAtIndex = __fieldPosition[damage.type][index - 1]
    if (keyAtIndex) {
        if (isNaN(damage[keyAtIndex])) {
            return 0;
        }
        return damage[keyAtIndex]
    }

    return 0;
}
export default returnURL;