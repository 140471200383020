export const ansiSpeciesList = [
    { label: "Alaska Yellow Cedar", id: 0, ansiSpeciesMapId: 7 },
    { label: "Douglas-fir", id: 1, ansiSpeciesMapId: 8 },
    { label: "Engelmann Spruce", id: 2, ansiSpeciesMapId: 4 },
    { label: "Jack Pine", id: 3, ansiSpeciesMapId: 6 },
    { label: "Lodgepole Pine", id: 4, ansiSpeciesMapId: 6 },
    { label: "Northern White Cedar", id: 5, ansiSpeciesMapId: 3 },
    { label: "Ponderosa Pine", id: 6, ansiSpeciesMapId: 5 },
    { label: "Red Pine", id: 7, ansiSpeciesMapId: 6 },
    { label: "Redwood", id: 8, ansiSpeciesMapId: 6 },
    { label: "Sitka Spruce", id: 9, ansiSpeciesMapId: 6 },
    { label: "Southern Pine", id: 10, ansiSpeciesMapId: 8 },
    { label: "Western Fir", id: 11, ansiSpeciesMapId: 6 },
    { label: "Western Hemlock", id: 12, ansiSpeciesMapId: 7 },
    { label: "Western Larch", id: 13, ansiSpeciesMapId: 9 },
    { label: "Western Red Cedar", id: 14, ansiSpeciesMapId: 5 },
    { label: "White Spruce", id: 15, ansiSpeciesMapId: 6 },
]

const ansiEmbedmentMap = {
    20: 4,
    25: 5,
    30: 5.5,
    35: 6,
    40: 6,
    45: 6.5,
    50: 7,
    55: 7.5,
    60: 8,
    65: 8.5,
    70: 9,
    75: 9.5,
    80: 10,
    85: 10.5,
    90: 11,
    95: 11,
    100: 11,
    105: 12,
    110: 12,
    115: 12,
    120: 12,
    125: 12,
}

export const ANSITables = {
    "3": [[0, 390, 370, 350, 330, 310, 290, 270, 250, 230, 210, 190, 170, 150, 150, 120], [200, 0, 0, 0, 0, 0, 0, 380, 355, 330, 305, 280, 260, 240, 220, 175], [250, 0, 0, 0, 0, 0, 0, 420, 395, 365, 340, 315, 290, 270, 240, 195], [300, 0, 0, 0, 0, 0, 0, 455, 430, 400, 370, 345, 320, 295, 260, 0], [350, 0, 0, 0, 0, 0, 0, 490, 460, 425, 395, 370, 340, 315, 0, 0], [400, 0, 0, 0, 0, 0, 0, 515, 485, 450, 420, 390, 360, 0, 0, 0], [450, 0, 0, 0, 0, 0, 0, 545, 510, 475, 440, 410, 0, 0, 0, 0], [500, 0, 0, 0, 0, 0, 0, 570, 535, 495, 460, 430, 0, 0, 0, 0], [550, 0, 0, 0, 0, 0, 0, 590, 555, 515, 480, 0, 0, 0, 0, 0], [600, 0, 0, 0, 0, 0, 0, 610, 575, 535, 500, 0, 0, 0, 0, 0], [650, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [700, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [750, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [800, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [850, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [900, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [950, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [1000, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [1050, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [1100, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [1150, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [1200, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [1250, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
    "4": [[0, 390, 370, 350, 330, 310, 290, 270, 250, 230, 210, 190, 170, 150, 150, 120], [200, 0, 0, 0, 0, 0, 0, 345, 320, 300, 280, 255, 235, 220, 190, 150], [250, 0, 0, 0, 0, 0, 0, 380, 355, 330, 305, 285, 260, 245, 210, 165], [300, 0, 0, 0, 0, 0, 0, 410, 385, 350, 330, 305, 285, 265, 225, 0], [350, 0, 0, 0, 0, 0, 0, 435, 410, 380, 355, 325, 305, 280, 0, 0], [400, 0, 0, 0, 0, 0, 0, 460, 435, 405, 375, 345, 320, 0, 0, 0], [450, 0, 0, 0, 0, 0, 0, 485, 455, 425, 395, 365, 0, 0, 0, 0], [500, 0, 0, 0, 0, 0, 0, 505, 475, 445, 410, 380, 0, 0, 0, 0], [550, 0, 0, 0, 0, 0, 0, 525, 495, 460, 425, 0, 0, 0, 0, 0], [600, 0, 0, 0, 0, 0, 0, 545, 510, 475, 440, 0, 0, 0, 0, 0], [650, 0, 0, 0, 0, 0, 0, 560, 525, 490, 455, 0, 0, 0, 0, 0], [700, 0, 0, 0, 0, 0, 0, 575, 540, 505, 470, 0, 0, 0, 0, 0], [750, 0, 0, 0, 0, 0, 0, 595, 555, 520, 0, 0, 0, 0, 0, 0], [800, 0, 0, 0, 0, 0, 0, 610, 570, 535, 0, 0, 0, 0, 0, 0], [850, 0, 0, 0, 0, 0, 0, 625, 585, 545, 0, 0, 0, 0, 0, 0], [900, 0, 0, 0, 0, 0, 0, 635, 600, 560, 0, 0, 0, 0, 0, 0], [950, 0, 0, 0, 0, 0, 0, 650, 610, 0, 0, 0, 0, 0, 0, 0], [1000, 0, 0, 0, 0, 0, 0, 660, 620, 0, 0, 0, 0, 0, 0, 0], [1050, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [1100, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [1150, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [1200, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [1250, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
    "5": [[0, 390, 370, 350, 330, 310, 290, 270, 250, 230, 210, 190, 170, 150, 150, 120], [200, 0, 0, 0, 0, 0, 0, 335, 315, 295, 270, 250, 230, 215, 185, 150], [250, 0, 0, 0, 0, 0, 0, 370, 345, 325, 300, 280, 255, 240, 205, 165], [300, 0, 0, 0, 0, 0, 0, 400, 375, 350, 325, 300, 280, 260, 220, 0], [350, 0, 0, 0, 0, 480, 455, 425, 400, 375, 345, 320, 300, 275, 0, 0], [400, 0, 0, 565, 535, 510, 480, 450, 425, 395, 365, 340, 315, 0, 0, 0], [450, 645, 620, 590, 560, 535, 505, 475, 445, 415, 385, 360, 330, 0, 0, 0], [500, 670, 645, 615, 585, 555, 525, 495, 465, 435, 400, 375, 0, 0, 0, 0], [550, 700, 670, 640, 610, 575, 545, 515, 485, 450, 420, 0, 0, 0, 0, 0], [600, 720, 690, 660, 630, 595, 565, 535, 500, 465, 435, 0, 0, 0, 0, 0], [650, 745, 715, 680, 650, 615, 585, 550, 515, 480, 450, 0, 0, 0, 0, 0], [700, 765, 735, 700, 670, 635, 600, 565, 530, 495, 460, 0, 0, 0, 0, 0], [750, 785, 755, 720, 685, 650, 615, 580, 545, 510, 0, 0, 0, 0, 0, 0], [800, 805, 770, 740, 705, 670, 630, 595, 560, 520, 0, 0, 0, 0, 0, 0], [850, 825, 790, 755, 720, 685, 645, 610, 570, 535, 0, 0, 0, 0, 0, 0], [900, 845, 810, 770, 735, 700, 660, 625, 585, 545, 0, 0, 0, 0, 0, 0], [950, 860, 825, 790, 750, 715, 675, 635, 595, 0, 0, 0, 0, 0, 0, 0], [1000, 875, 840, 805, 765, 725, 690, 650, 610, 0, 0, 0, 0, 0, 0, 0], [1050, 895, 855, 820, 780, 740, 700, 660, 620, 0, 0, 0, 0, 0, 0, 0], [1100, 910, 870, 835, 795, 755, 715, 675, 630, 0, 0, 0, 0, 0, 0, 0], [1150, 925, 885, 845, 805, 765, 725, 685, 640, 0, 0, 0, 0, 0, 0, 0], [1200, 940, 900, 860, 820, 780, 740, 695, 650, 0, 0, 0, 0, 0, 0, 0], [1250, 955, 915, 875, 830, 790, 750, 705, 660, 0, 0, 0, 0, 0, 0, 0]],
    "6": [[0, 390, 370, 350, 330, 310, 290, 270, 250, 230, 210, 190, 170, 150, 150, 120], [200, 0, 0, 0, 0, 0, 0, 325, 305, 285, 265, 245, 225, 210, 180, 145], [250, 0, 0, 0, 0, 0, 0, 360, 335, 310, 290, 270, 250, 230, 200, 155], [300, 0, 0, 0, 0, 0, 0, 390, 365, 340, 315, 290, 270, 250, 210, 0], [350, 0, 0, 0, 0, 0, 0, 415, 385, 360, 335, 310, 285, 265, 0, 0], [400, 0, 0, 0, 0, 0, 0, 440, 410, 380, 355, 330, 305, 0, 0, 0], [450, 0, 0, 0, 0, 0, 0, 460, 430, 400, 370, 345, 320, 0, 0, 0], [500, 0, 0, 0, 0, 0, 0, 480, 450, 420, 390, 360, 0, 0, 0, 0], [550, 0, 0, 0, 0, 0, 0, 495, 465, 435, 405, 0, 0, 0, 0, 0], [600, 0, 0, 0, 0, 0, 0, 515, 480, 450, 420, 0, 0, 0, 0, 0], [650, 0, 0, 0, 0, 0, 0, 530, 495, 460, 430, 0, 0, 0, 0, 0], [700, 0, 0, 0, 0, 0, 0, 545, 510, 475, 445, 0, 0, 0, 0, 0], [750, 0, 0, 0, 0, 0, 0, 560, 525, 490, 0, 0, 0, 0, 0, 0], [800, 0, 0, 0, 0, 0, 0, 575, 540, 505, 0, 0, 0, 0, 0, 0], [850, 0, 0, 0, 0, 0, 0, 585, 550, 515, 0, 0, 0, 0, 0, 0], [900, 0, 0, 0, 0, 0, 0, 600, 565, 525, 0, 0, 0, 0, 0, 0], [950, 0, 0, 0, 0, 0, 0, 615, 575, 0, 0, 0, 0, 0, 0, 0], [1000, 0, 0, 0, 0, 0, 0, 625, 585, 0, 0, 0, 0, 0, 0, 0], [1050, 0, 0, 0, 0, 0, 0, 635, 600, 0, 0, 0, 0, 0, 0, 0], [1100, 0, 0, 0, 0, 0, 0, 650, 610, 0, 0, 0, 0, 0, 0, 0], [1150, 0, 0, 0, 0, 0, 0, 660, 620, 0, 0, 0, 0, 0, 0, 0], [1200, 0, 0, 0, 0, 0, 0, 670, 630, 0, 0, 0, 0, 0, 0, 0], [1250, 0, 0, 0, 0, 0, 0, 680, 640, 0, 0, 0, 0, 0, 0, 0]],
    "7": [[0, 390, 370, 350, 330, 310, 290, 270, 250, 230, 210, 190, 170, 150, 150, 120], [200, 0, 0, 0, 0, 0, 0, 315, 295, 275, 255, 235, 220, 200, 175, 140], [250, 0, 0, 0, 0, 0, 0, 345, 325, 300, 280, 260, 240, 220, 195, 150], [300, 0, 0, 0, 0, 0, 0, 375, 350, 325, 300, 280, 260, 240, 205, 0], [350, 0, 0, 0, 0, 450, 425, 400, 375, 350, 320, 300, 275, 255, 0, 0], [400, 0, 0, 525, 500, 475, 450, 420, 395, 370, 340, 315, 290, 255, 0, 0], [450, 600, 575, 550, 525, 495, 470, 440, 415, 385, 360, 330, 305, 0, 0, 0], [500, 625, 600, 570, 545, 515, 490, 460, 430, 400, 375, 345, 0, 0, 0, 0], [550, 650, 620, 595, 565, 535, 505, 475, 445, 415, 390, 0, 0, 0, 0, 0], [600, 670, 640, 615, 585, 555, 525, 495, 460, 430, 400, 0, 0, 0, 0, 0], [650, 690, 660, 630, 600, 570, 540, 510, 475, 445, 415, 0, 0, 0, 0, 0], [700, 710, 680, 650, 620, 585, 555, 525, 490, 460, 425, 0, 0, 0, 0, 0], [750, 730, 695, 665, 635, 600, 570, 535, 505, 470, 0, 0, 0, 0, 0, 0], [800, 745, 715, 680, 650, 615, 585, 550, 515, 485, 0, 0, 0, 0, 0, 0], [850, 760, 730, 700, 665, 630, 595, 560, 530, 495, 0, 0, 0, 0, 0, 0], [900, 780, 745, 710, 680, 645, 610, 575, 540, 505, 0, 0, 0, 0, 0, 0], [950, 795, 760, 725, 695, 660, 620, 585, 550, 0, 0, 0, 0, 0, 0, 0], [1000, 810, 775, 740, 705, 670, 635, 600, 560, 0, 0, 0, 0, 0, 0, 0], [1050, 825, 790, 755, 720, 685, 645, 610, 570, 0, 0, 0, 0, 0, 0, 0], [1100, 840, 805, 770, 730, 695, 655, 620, 580, 0, 0, 0, 0, 0, 0, 0], [1150, 855, 815, 780, 745, 705, 670, 630, 590, 0, 0, 0, 0, 0, 0, 0], [1200, 865, 830, 795, 755, 720, 680, 640, 600, 0, 0, 0, 0, 0, 0, 0], [1250, 880, 845, 805, 765, 730, 690, 650, 610, 0, 0, 0, 0, 0, 0, 0]],
    "8": [[0, 390, 370, 350, 330, 310, 290, 270, 250, 230, 210, 190, 170, 150, 150, 120], [200, 0, 0, 0, 0, 0, 0, 310, 290, 270, 250, 230, 210, 195, 175, 140], [250, 0, 0, 0, 0, 0, 0, 335, 315, 295, 275, 255, 230, 215, 195, 150], [300, 0, 0, 0, 0, 0, 0, 365, 340, 320, 295, 275, 250, 235, 205, 0], [350, 0, 0, 0, 0, 435, 415, 390, 365, 340, 315, 290, 270, 250, 0, 0], [400, 0, 0, 510, 485, 460, 435, 410, 385, 360, 335, 310, 285, 0, 0, 0], [450, 585, 560, 535, 510, 485, 455, 430, 405, 375, 350, 325, 300, 0, 0, 0], [500, 610, 585, 555, 530, 505, 475, 450, 420, 390, 365, 340, 0, 0, 0, 0], [550, 635, 605, 580, 550, 520, 495, 465, 435, 405, 380, 0, 0, 0, 0, 0], [600, 655, 625, 595, 570, 540, 510, 480, 450, 420, 390, 0, 0, 0, 0, 0], [650, 675, 645, 615, 585, 555, 525, 495, 465, 435, 405, 0, 0, 0, 0, 0], [700, 690, 665, 635, 605, 570, 540, 510, 480, 450, 415, 0, 0, 0, 0, 0], [750, 710, 680, 650, 620, 590, 555, 525, 490, 460, 0, 0, 0, 0, 0, 0], [800, 725, 695, 665, 635, 600, 570, 540, 505, 470, 0, 0, 0, 0, 0, 0], [850, 745, 715, 680, 650, 615, 585, 550, 515, 480, 0, 0, 0, 0, 0, 0], [900, 760, 730, 695, 665, 630, 595, 560, 530, 490, 0, 0, 0, 0, 0, 0], [950, 775, 745, 710, 675, 645, 610, 570, 540, 0, 0, 0, 0, 0, 0, 0], [1000, 790, 760, 725, 690, 655, 620, 585, 550, 0, 0, 0, 0, 0, 0, 0], [1050, 805, 770, 740, 705, 670, 630, 595, 560, 0, 0, 0, 0, 0, 0, 0], [1100, 820, 785, 750, 715, 680, 645, 605, 570, 0, 0, 0, 0, 0, 0, 0], [1150, 835, 800, 765, 725, 690, 655, 615, 580, 0, 0, 0, 0, 0, 0, 0], [1200, 850, 810, 775, 740, 700, 665, 625, 590, 0, 0, 0, 0, 0, 0, 0], [1250, 860, 825, 785, 750, 710, 675, 635, 595, 0, 0, 0, 0, 0, 0, 0]],
    "9": [[0, 390, 370, 350, 330, 310, 290, 270, 250, 230, 210, 190, 170, 150, 150, 120], [200, 0, 0, 0, 0, 0, 0, 300, 285, 265, 245, 225, 210, 190, 170, 135], [250, 0, 0, 0, 0, 0, 0, 330, 310, 290, 265, 245, 230, 210, 185, 145], [300, 0, 0, 0, 0, 0, 0, 355, 335, 310, 290, 265, 245, 230, 195, 0], [350, 0, 0, 0, 0, 430, 405, 380, 355, 330, 310, 285, 265, 245, 0, 0], [400, 0, 0, 505, 480, 455, 430, 400, 375, 350, 325, 300, 280, 0, 0, 0], [450, 575, 550, 525, 500, 475, 450, 420, 395, 370, 340, 315, 290, 0, 0, 0], [500, 600, 575, 550, 520, 495, 470, 440, 410, 385, 355, 330, 0, 0, 0, 0], [550, 620, 595, 570, 540, 515, 485, 455, 425, 400, 370, 0, 0, 0, 0, 0], [600, 645, 615, 590, 560, 530, 500, 470, 440, 410, 385, 0, 0, 0, 0, 0], [650, 660, 635, 605, 575, 550, 520, 485, 460, 425, 395, 0, 0, 0, 0, 0], [700, 680, 650, 625, 595, 565, 535, 500, 470, 440, 410, 0, 0, 0, 0, 0], [750, 700, 670, 640, 610, 580, 545, 515, 480, 450, 0, 0, 0, 0, 0, 0], [800, 715, 685, 655, 625, 590, 560, 525, 495, 460, 0, 0, 0, 0, 0, 0], [850, 730, 700, 670, 640, 605, 575, 540, 505, 470, 0, 0, 0, 0, 0, 0], [900, 745, 715, 685, 650, 620, 585, 550, 515, 485, 0, 0, 0, 0, 0, 0], [950, 765, 730, 700, 665, 630, 600, 565, 530, 0, 0, 0, 0, 0, 0, 0], [1000, 780, 745, 710, 680, 645, 610, 575, 540, 0, 0, 0, 0, 0, 0, 0], [1050, 790, 760, 725, 690, 655, 620, 585, 550, 0, 0, 0, 0, 0, 0, 0], [1100, 805, 770, 735, 700, 665, 630, 595, 560, 0, 0, 0, 0, 0, 0, 0], [1150, 820, 785, 750, 715, 680, 640, 605, 570, 0, 0, 0, 0, 0, 0, 0], [1200, 830, 795, 760, 725, 690, 650, 615, 580, 0, 0, 0, 0, 0, 0, 0], [1250, 845, 810, 775, 735, 700, 660, 625, 585, 0, 0, 0, 0, 0, 0, 0]]
};

export const ANSI_CLASSES = ['H-6', 'H-5', 'H-4', 'H-3', 'H-2', 'H-1', '1', '2', '3', '4', '5', '6', '7', '9', '10',];
export const ANSI_LENGTHS = [20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125];

export const ansiFiberStrength = [0, 8000, 5600, 6600, 6600, 4000, 6000, 6600, 6600, 6600, 8000, 6600, 7400, 8000, 6000, 6600];
export const ansiTapers = [0, 21, 0, 30, 30, 0, 29, 30, 0, 0, 25, 0, 20, 21, 38, 0];

export const getMinTipCirc = (ansiClass) => {
    let ansiClassId = ANSI_CLASSES.indexOf(ansiClass);

    let minTipSize = [390, 370, 350, 330, 310, 290, 270, 250, 230, 210, 190, 170, 150, 150, 120];
    return minTipSize[ansiClassId] / 10;
}

export const getANSITaper = (ansiSpecies) => {
    let ansiSpeciesId = getSpeciesId(ansiSpecies);
    return ansiTapers[ansiSpeciesId];
}

export const getANSIFiberStrength = (ansiSpecies) => {
    let ansiSpeciesId = getSpeciesId(ansiSpecies);
    return ansiFiberStrength[ansiSpeciesId];
}

export const getMinBaseCirc = ({ ansiSpecies, ansiClass, ansiLength }) => {
    let ansiSpeciesId = getSpeciesId(ansiSpecies);
    if (!ANSI_CLASSES.includes(ansiClass)) {
        throw 'Could not determine class!';
    }
    if (!ANSI_LENGTHS.includes(ansiLength)) {
        throw 'Invalid Length provided!';
    }

    let ansiLengthIndex = ANSI_LENGTHS.indexOf(ansiLength);
    let ansiClassIndex = ANSI_CLASSES.indexOf(ansiClass);
    const ANSIId = ansiSpeciesList[ansiSpeciesId].ansiSpeciesMapId;
    const ansiTable = ANSITables[ANSIId];
    let tableValue = ansiTable[ansiLengthIndex + 1][ansiClassIndex + 1];

    if (tableValue === 0) throw 'The combination of ANSI parameters is invalid!';
    return tableValue / 10;
}

export const validateANSIParameters = ({ ansiSpecies, ansiClass, ansiLength }) => {
    getMinBaseCirc({ ansiSpecies, ansiClass, ansiLength });
    return true;
}

function getSpeciesId(ansiSpecies) {
    let ansiSpeciesId = ansiSpeciesList.find((elem) => elem.label === ansiSpecies);
    if (!ansiSpeciesId) {
        throw 'Could not determine species!';
    }
    return ansiSpeciesId.id;
}

export const getEmbedment = (ansiLength, userEmbedment) => {
    ansiLength = Number(ansiLength);
    let groundLine;
    if (userEmbedment) {
        groundLine = userEmbedment;
    } else {
        groundLine = ansiEmbedmentMap[ansiLength];
    }

    return groundLine;
}

export const calcAnsiDiameter = (ansiSpecies, ansiClass, ansiLength, isLinearTaper, userEmbedment, damageHeight) => {
    const ansiGroundLine = getEmbedment(ansiLength, userEmbedment);
    const ansiCircAtBase = getMinBaseCirc({ ansiSpecies, ansiClass, ansiLength });

    let taper;
    if (isLinearTaper === true) {
        taper = ((ansiCircAtBase - getMinTipCirc(ansiClass)) / (ansiLength - 6));
    } else {
        taper = getANSITaper(ansiSpecies) / 100;
    }

    let ansiDiameterAtHeight = (((ansiCircAtBase) - ((((ansiGroundLine - 6) + damageHeight)) * (taper))) / Math.PI);
    return { embedment: ansiGroundLine, ansiDiameter: ansiDiameterAtHeight, ansiTaper: taper, ansiCirc: ansiCircAtBase };
}
