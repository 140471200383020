import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { Cell } from 'baseui/layout-grid';
import { Notification } from 'baseui/notification';
import React from 'react';
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';
import { setCurrentPoleWithoutSaving } from "../actions/poles";
import { NotificationOverride } from "../theming";
import ImportPole from "./inputs/ImportPole";
import { ROUTE_DATABASE } from './navigation/Sidebar';



export const PoleSelection = ({ setCurrentPoleWithoutSaving }) => {
    const [isImportModalOpen, setIsImportModalOpen] = React.useState(false);
    const history = useHistory();
    return (
        <Cell span={10} skip={[0, 0, 1]}>
            <Notification overrides={NotificationOverride}>No pole selected</Notification>
            <Block>
                <Button kind='secondary' onClick={() => history.push(ROUTE_DATABASE)} overrides={{
                    BaseButton: { style: { margin: '2px' } }
                }}>
                    Choose a Pole
                </Button>
                <Button kind='secondary' onClick={() => setIsImportModalOpen(true)}>
                    Import
                </Button>
                <Button onClick={() => setCurrentPoleWithoutSaving()}>New</Button>
                <ImportPole isOpen={isImportModalOpen} setOpen={setIsImportModalOpen} />
            </Block>
        </Cell>
    )
}

export const ConnectedPoleSelection = connect(state => ({}), { setCurrentPoleWithoutSaving })(PoleSelection);