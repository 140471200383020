import { ANSITables, ANSI_CLASSES, ANSI_LENGTHS, ansiSpeciesList } from '@edm-internationa/d-calc-calculations';
import { Block } from 'baseui/block';
import {
    CustomColumn, DataTable
} from 'baseui/data-table';
import { Notification } from 'baseui/notification';
import { createContext, memo, useCallback, useContext, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useStyletron } from "styletron-react";
import { setInfo } from '../../actions/pole';
import { NotificationOverride } from '../../theming';

const HighlightContext = createContext();

const ReferenceChart = ({ ansiSpeciesId, setInfo }) => {
  console.log(ansiSpeciesId)
  const [css] = useStyletron();
  const styles = useCallback((diameter, isHighlighted) => css({
    backgroundColor: isHighlighted ? '#FFB948' : 'inherit',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'inherit',
    border: 'none',
    padding: 0,
    cursor: diameter === '--' || isHighlighted ? 'default' : 'pointer',
    font: 'inherit',
    outline: 'inherit',
  }), []);

  const ANSIId = ansiSpeciesList[ansiSpeciesId].ansiSpeciesMapId;
  const table = ANSITables[ANSIId];
  const [currentCell, setCurrentCell] = useState({ x: -1, y: -1 });
  let rows = ANSIId
    ? table.map((row, i) => ({
      id: row,
      data: [...row.map(d => d === 0 ? '--' : +d / 10)]
    }))
    : [];
  rows.splice(0, 1);

  const setANSI = useCallback((x, y, ansiClass, cellIndex, diameter) => {
    if (diameter !== '--') {
      setCurrentCell(prev => {
        if (x !== prev.x || y !== prev.y) {
          const ansiLength = ANSI_LENGTHS[y]
          setInfo({ ansiLength, ansiClass, ansiDiameter: diameter });
          return { x: x - 1, y };
        }
      });
    }
  }, [table]);

  const columns = useMemo(() => [
    CustomColumn({
      sortable: false,
      title: 'Length',
      mapDataToValue: (data) => data[0],
      renderCell: ({ value }) => <b>{value}</b>,
    }),
    ...ANSI_CLASSES.map((columnClass, cellIndex) => CustomColumn({
      sortable: false,
      title: columnClass,
      mapDataToValue: (data) => data[cellIndex + 1],
      renderCell: memo(function CustomCell({ x, y, value: diameter }) {
        const { x: currentX, y: currentY, setANSI } = useContext(HighlightContext);
        const isHighlighted = x === currentX + 1 && y === currentY;
        return <button
          onClick={() => setANSI(x, y, columnClass, cellIndex, diameter)}
          className={styles(diameter, isHighlighted)}>
          {diameter}
        </button>
      })
    })),
  ], []);

  return <HighlightContext.Provider value={{ x: currentCell.x, y: currentCell.y, setANSI }}>
    <Block height={['80vh', '80vh', '850px']} width='100%'>
      {rows.length ? <DataTable
        columns={columns}
        rows={rows}
      /> :
        <Notification overrides={NotificationOverride} >Select a species to get started</Notification>}
    </Block>
  </HighlightContext.Provider>;
}

export default connect(null, { setInfo })(ReferenceChart);