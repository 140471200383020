import { SET_RETURN_URL, SET_PARAMS_POLE_DETAILS, SET_CLAIMS, SET_ORG_DATA, SET_USER_DATA, SET_USER_DETAILS } from "../actions/user";

function getDefaultState() {
    return {
        loggedIn: false,
        user: {},
        userData: {},
        claims: {},
        orgData: {},
        poleParamsDetails: {},
        returnURL: {}
    };
}

export const user = (state = getDefaultState(), action) => {
    switch (action.type) {
        case SET_PARAMS_POLE_DETAILS:
            return {
                ...state,
                poleParamsDetails: action.poleParamsDetails
            }
        case SET_RETURN_URL:
            return {
                ...state,
                returnURL: action.returnUrl
            }
        case SET_USER_DETAILS:
            return { ...state, ...action.details }
        case SET_USER_DATA:
            return {
                ...state, userData: {
                    ...action.userData,
                }
            }
        case SET_CLAIMS:
            return {
                ...state,
                claims: action.claims
            }
        case SET_ORG_DATA:
            return {
                ...state,
                orgData: action.orgData
            }
        default:
            return state;
    }
}