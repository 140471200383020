import { Block } from "baseui/block";
import { FileUploader } from "baseui/file-uploader";
import { Modal, ModalBody, ModalHeader } from "baseui/modal";
import { Notification } from "baseui/notification";
import React, { useState } from "react";
import { connect } from 'react-redux';
import { setCurrentPoleWithoutSaving } from "../../actions/poles";
import { ModalOverride, NotificationOverride } from "../../theming";
import { parseCSV } from "../../utils/parseCSV";
import { DownloadSchemaButton } from '../Assess/ExportButton';
import { Spacer } from "../layout/Spacer";

const ImportPole = ({ pole, setCurrentPoleWithoutSaving, isOpen, setOpen }) => {
  const [error, setError] = useState(undefined);
  const handleClose = () => {
    setOpen(false);
    setError(undefined);
  }

  const parse = async (file, type) => {
    let importedPole;
    try {
      if (type === 'text/csv' || type === 'application/vnd.ms-excel') {
        const poleCsv = parseCSV(await file.text());
        importedPole = pole.importCsv(poleCsv);
      } else if (type === 'application/json') {
        const poleJson = await file.text();
        importedPole = pole.importJson(poleJson);
        importedPole.projectId = undefined;
      }
      setCurrentPoleWithoutSaving(importedPole);
      setOpen(false);
    } catch (e) {
      console.error(e);
      setError(e.message);
    }
  };

  return (
    <>
      <Modal overrides={ModalOverride} onClose={handleClose} closeable isOpen={isOpen} unstable_ModalBackdropScroll>
        <ModalHeader>Upload</ModalHeader>
        <ModalBody>
          {error &&
            <Notification overrides={NotificationOverride} kind='negative'>
              <Block width="100%">
                {error}
              </Block>
            </Notification>}
          <FileUploader accept={['.csv', '.json']} onDrop={([file]) => parse(file, file.type)} onDropRejected={setError} />
          <Block >
            {error &&
              <>
                <Spacer /><Spacer />
                Would you like to download a template? <br />
                <DownloadSchemaButton type='json' onClick={() => setError(undefined)}>
                  Download
                </DownloadSchemaButton>
              </>
            }
          </Block>
        </ModalBody>
      </Modal>
    </>
  );
};

export default connect(({ pole }) => ({ pole }), { setCurrentPoleWithoutSaving })(ImportPole);
