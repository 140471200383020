import { useStripe } from "@stripe/react-stripe-js";
import { Block } from 'baseui/block';
import { Button, KIND } from 'baseui/button';
import { Card, StyledBody } from 'baseui/card';
import { Checkbox } from 'baseui/checkbox';
import { FormControl } from "baseui/form-control";
import { Input } from 'baseui/input';
import { Radio, RadioGroup } from 'baseui/radio';
import { PLACEMENT, ToasterContainer, toaster } from "baseui/toast";
import { Caption1, Caption2, Label2, Paragraph1, Paragraph2 } from 'baseui/typography';
import React from 'react';
import { connect } from 'react-redux';
import withStripe from "../../billing/withStripe";
import BillingAPI from '../../firebase/BillingAPI';
import { ToasterContainerOverride } from "../../theming";
import Registration from "./Registration";


export const OnboardingPage = (props) => {
  const stripe = useStripe();
  const [history, setHistory] = React.useState(['paymentModel']);
  const [paymentModel, setPaymentModel] = React.useState();
  const [numLicenses, setNumLicenses] = React.useState();
  const [apiAccess, setApiAccess] = React.useState();

  const [currentQuestion, _setCurrentQuestion] = React.useState({ id: 'paymentModel', next: 'licenseAmount' });
  const [spinning, setSpinning] = React.useState();

  const next = () => {
    const nextQ = currentQuestion.next
    _setCurrentQuestion(questions[nextQ])
    setHistory(history => ([...history, nextQ]));
  };

  const back = () => {
    setHistory(history.slice(0, -1));
    _setCurrentQuestion(questions[history[history.length - 2]]);
  }

  const StripeButton = () => <Button
    overrides={{ BaseButton: { style: { backgroundColor: '#2596DE', color: 'white', ':hover': { backgroundColor: '#4FB1F7' } } } }}
    onClick={(e) => {
      setSpinning(true);
      BillingAPI.purchase(stripe, paymentModel, numLicenses, apiAccess).then((resp) => {
        setSpinning(false)
      }).catch((error) => {
        setSpinning(false);
        toaster.negative(error.message)
      })
    }} isLoading={spinning}>Purchase</Button>

  const RadioInput = ({ setAnswer, id, currentAnswer, options }) =>
    <RadioGroup
      overrides={{ Root: { style: { marginLeft: '10px' } } }}
      marginLeft={'20px'}
      value={currentAnswer || ''}
      onChange={e => setAnswer(e.target.value)}
      name={id}
      align='vertical'
    >
      {options.map(({ description, value, label }, index) =>
        <Radio key={index} value={value} description={description}>{label}</Radio>
      )}
    </RadioGroup>;

  const QuestionCard = ({ caption, Question, buttons }) =>
    <Card title='Onboard' overrides={{ Root: { style: { width: '500px', maxWidth: '95vw', minHeight: '300px', position: 'relative', borderRadius: 0 } } }}>
      <Caption1>{caption}</Caption1>
      <StyledBody >
        <Question />
      </StyledBody>
      <Block display='flex' justifyContent='flex-end' position='absolute' bottom='10px' right='10px'>
        {buttons.map(CustomButton =>
          <Block marginLeft='4px' marginRight='4px'>
            <CustomButton />
          </Block>)}
      </Block>
    </Card>;

  const OnboardingPage = ({ children }) => <Block
    display='flex'
    flexDirection='column'
    width='100%'
    height='85vh'
    alignItems='center'
    justifyContent='center'
  >
    {children}
  </Block>;

  const questions = {
    'paymentModel': {
      id: 'paymentModel',
      next: 'licenseAmount',
      caption: 'How would you like to subscribe...',
      Question: () => <>
        <Label2>Subscription Model</Label2>
        <RadioInput
          setAnswer={(e) => setPaymentModel(e)}
          id={'paymentModel'}
          currentAnswer={paymentModel}
          options={[{ label: 'Monthly - $50/mo per license', value: 'monthlyLicenses' },
          { label: 'Annually - $500/yr per license', value: 'yearlyLicenses' },
          { label: 'Perpetual - $950 per license', value: 'perpetualLicenses' },]}
        />
      </>,
      buttons: [() => <Button kind={KIND.minimal} disabled>Back</Button>, () => <Button disabled={!paymentModel} onClick={next}>Next</Button>]
    },
    'licenseAmount': {
      id: 'licenseAmount',
      next: 'apiAccess',
      caption: 'How many licenses does your business need?',
      Question: () => {
        return <>
          <FormControl label="License Amount" error={Number(numLicenses) > 50 ? 'Cannot buy more than 50 licenses at a time.' : undefined}>
            <Input
              overrides={{ Root: { style: { width: '150px', marginLeft: '20px', marginTop: '8px' } } }}
              type='number'
              value={numLicenses}
              onChange={e => {
                setNumLicenses(Number(e.target.value))
              }}
              autoFocus={true}
            />
          </FormControl>

        </>
      },
      buttons: [() => <Button onClick={back} kind='tertiary'>Back</Button>, () => <Button disabled={numLicenses < 0 || !numLicenses || Number(numLicenses) > 50} onClick={next}>Next</Button>]
    },
    'apiAccess': {
      id: 'apiAccess',
      next: 'summary',
      caption: 'One last thing...',
      Question: () => <>
        <Label2>Do you need API Access?</Label2>
        <Block marginTop={'8px'} marginLeft={'20px'}>
          <Checkbox
            onChange={e => setApiAccess(e.target.checked)}
            checked={apiAccess}
            labelPlacement='right'
          >Include API Access</Checkbox>
          <Caption2>$5000/per 25,000 requests</Caption2>
        </Block>
      </>,
      buttons: [() => <Button onClick={back} kind='tertiary'>Back</Button>, () => <Button onClick={next}>Next</Button>]
    },
    'summary': {
      id: 'summary',
      next: null,
      caption: `Let's wrap up`,
      Question: () => {
        const { productInfo: { cost, billingFrequency }, total: { startingCost, recurring, } } = calcTotals(paymentModel, numLicenses, apiAccess);
        return <>
          <Label2>Summary</Label2>
          <Block display='flex' flexDirection='row' height='40px' alignItems='center' justifyContent='space-between' paddingLeft='10px' marginTop='20px'>
            <Paragraph2>{numLicenses}x DCalc license{numLicenses > 1 && 's'} for commercial use</Paragraph2>
            <b>${cost}{billingFrequency.short}</b>
          </Block>
          {apiAccess && <Block height='40px' display='flex' flexDirection='row' alignItems='center' justifyContent='space-between' paddingLeft='10px'>
            <Paragraph2>25,000 API calls</Paragraph2>
            <b>$5000</b>
          </Block>}
          <Block width='100%' display='flex' justifyContent='flex-end' marginRight='4px' height='100px'>
            <Paragraph1>Total: <b>${startingCost}</b>{billingFrequency.long !== 'lifetime' && ` + $${recurring} billed ${billingFrequency.long}`}</Paragraph1>
          </Block>
        </>
      },
      buttons: [() => <Button kind='tertiary' onClick={back}>Back</Button>, StripeButton]
    },
  };

  return <OnboardingPage>
    <ToasterContainer overrides={ToasterContainerOverride} placement={PLACEMENT.bottomLeft} autoHideDuration={5000}></ToasterContainer>
    {props.claims.orgId ? <QuestionCard {...questions[currentQuestion.id]} /> : <Registration />}
  </OnboardingPage>;
}

export default connect((state) => ({
  claims: state.user.claims,
}), {})(withStripe(OnboardingPage));

const calcTotals = (paymentModel, numLicenses, apiAccess) => {
  console.log(paymentModel, numLicenses, apiAccess);
  const products = {
    monthlyLicenses: {
      cost: 50,
      billingFrequency: {
        long: 'monthly',
        short: '/mo',
      },
    },
    yearlyLicenses: {
      cost: 500,
      billingFrequency: {
        long: 'annually',
        short: '/yr',
      },
    },
    perpetualLicenses: {
      cost: 950,
      billingFrequency: {
        long: 'lifetime',
      },
    }
  }

  let total = 0;
  let licenseAmount = numLicenses || 1;
  total += licenseAmount * products[paymentModel].cost;
  return { productInfo: products[paymentModel], total: { startingCost: apiAccess ? total + 5000 : total, recurring: total } }
}
