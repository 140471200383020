import { Button, KIND, SIZE } from 'baseui/button';
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from 'baseui/modal';
import { Notification } from "baseui/notification";
import { Select, TYPE } from 'baseui/select';
import {
    TableBuilder,
    TableBuilderColumn
} from 'baseui/table-semantic';
import { Tag } from "baseui/tag";
import { toaster } from "baseui/toast";
import { HeadingSmall, Label1, Paragraph1 } from 'baseui/typography';
import 'material-icons/iconfont/filled.css';
import moment from 'moment';
import React from 'react';
import BillingAPI from "../../firebase/BillingAPI";
import { NotificationOverride } from '../../theming';
import { Spacer } from "../layout/Spacer";
import { dcalcBlueButton } from './Billing';
import InviteUserModal from "./InviteUserModal";


export const LicenseTable = ({ licenses, setLicenses, orgId, orgData, claims }) => {
    const [updateButtonLoading, setUpdateButtonLoading] = React.useState(false);
    const [editModalOpen, setEditModalOpen] = React.useState(false);
    const [editingIndex, setEditingIndex] = React.useState();

    async function deleteUser(id, index) {
        try {
            await BillingAPI.deleteUser(id, orgId);
            let newLicenses = [...licenses];
            newLicenses.splice(index, 1);
            setLicenses(newLicenses)
        } catch (err) {
            toaster.negative('Failed to delete user.')
        }
    }

    async function openEditModalForUser(id, index) {
        setEditModalOpen({ ...licenses[index] })
        setEditingIndex(index)
    }

    async function editUser() {
        try {
            setUpdateButtonLoading(true)
            let newLicense = await BillingAPI.updateLicense(licenses[editingIndex].id, editModalOpen?.admin, editModalOpen?.useCalculator, orgId);
            let newLicenses = [...licenses];
            newLicenses[editingIndex] = newLicense.license;
            setLicenses(newLicenses)
            setEditModalOpen()
            setEditingIndex()
            setUpdateButtonLoading(false)
        } catch (err) {
            setUpdateButtonLoading(false)
            toaster.negative('Failed Change. ' + err.message)
        }
    }

    function editUserModal() {
        let header = 'Edit User';

        let value = []
        if (editModalOpen?.useCalculator) {
            value.push({ id: 'Licensed', value: 'useCalculator' });
        }
        if (editModalOpen?.admin) {
            value.push({ id: 'Administrator', value: 'admin' });
        }

        return <Modal isOpen={editModalOpen} onClose={(e) => setEditModalOpen(false)} closeable>
            <ModalHeader>{header}</ModalHeader>
            <ModalBody>
                <FormControl label="Email Address">
                    <Input
                        disabled
                        value={editModalOpen?.emailAddress}
                    ></Input>
                </FormControl>
                <FormControl label="Roles">
                    <Select
                        options={[
                            { id: 'Licensed', value: 'useCalculator' },
                            { id: 'Administrator', value: 'admin' },
                        ]}
                        labelKey="id"
                        valueKey="value"
                        placeholder="License Options"
                        maxDropdownHeight="300px"
                        type={TYPE.search}
                        multi
                        onChange={({ value }) => {
                            let newUserData = { ...editModalOpen };
                            newUserData['useCalculator'] = false;
                            newUserData['admin'] = false;

                            for (let option of value) {
                                newUserData[option.value] = true;
                            }

                            setEditModalOpen(newUserData)
                        }}
                        value={value}
                    />
                </FormControl>
            </ModalBody>
            <ModalFooter>
                <ModalButton
                    onClick={(e) => setEditModalOpen(false)}
                    kind={KIND.tertiary}
                >
                    Cancel
                </ModalButton>
                <ModalButton
                    onClick={(e) => editUser()}
                    isLoading={updateButtonLoading}
                    disabled={!licenses[editingIndex] || (licenses[editingIndex].useCalculator === editModalOpen?.useCalculator && licenses[editingIndex].admin === editModalOpen?.admin)}
                    overrides={{ BaseButton: { style: { backgroundColor: '#2596DE', color: 'white', ':hover': { backgroundColor: '#4FB1F7' } } } }}
                >
                    Apply
                </ModalButton>
            </ModalFooter>
        </Modal>
    }

    if (!licenses.length) {
        return <div>
            <HeadingSmall>Licenses</HeadingSmall>
            <Paragraph1>{BillingAPI.getNumLicensesString(orgData)}</Paragraph1>
            <Notification overrides={NotificationOverride}><Label1>No licenses have been set up yet!</Label1>
                <InviteUserModal licenses={licenses} setLicenses={setLicenses} orgId={orgId} />
            </Notification>
        </div>
    }

    return <div>
        {claims && claims.isTrial && claims.timeExpiring ? <Notification
            kind={KIND.negative}
            overrides={{
                Body: {
                    style: {
                        margin: '0',
                        backgroundColor: '#924444',
                        width: '96.6%',
                        color: '#FFFFFF',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        borderRadius: 0,
                    }
                }
            }
            }>{() => "Your trial will be expiring in " + moment(new Date(claims.timeExpiring['_seconds'] * 1000)).diff(moment(new Date()), 'days') + " days"}
        </Notification> : undefined}
        <HeadingSmall>Licenses</HeadingSmall>
        <Paragraph1>{BillingAPI.getNumLicensesString(orgData)}</Paragraph1>
        <div style={{ width: '95%' }}>
            <TableBuilder data={licenses}>
                <TableBuilderColumn header="Email">
                    {row => row.emailAddress}
                </TableBuilderColumn>
                <TableBuilderColumn header="Type">
                    {row => <>{row.useCalculator ? <Tag closeable={false}>Using License</Tag> : undefined}
                        {row.admin ? <Tag closeable={false}>Admin</Tag> : undefined}
                        {row.isTrial ? <Tag closeable={false}>Trial</Tag> : undefined}
                    </>}
                </TableBuilderColumn>
                <TableBuilderColumn header="Device 1">
                    {row => row.device1Name ? row.device1Name : 'Inactive'}
                </TableBuilderColumn>
                <TableBuilderColumn header="Device 2">
                    {row => row.device2Name ? row.device2Name : 'Inactive'}
                </TableBuilderColumn>
                <TableBuilderColumn header="Options">
                    {(row, index) => <div style={{ display: 'flex' }}>
                        <Button size={SIZE.mini} onClick={() => openEditModalForUser(row.id, index)} overrides={dcalcBlueButton}>Edit</Button>
                        <div style={{ width: '10px' }} />
                        <Button size={SIZE.mini} onClick={() => deleteUser(row.id, index)} kind={KIND.secondary}>Remove License</Button>
                    </div>}
                </TableBuilderColumn>
            </TableBuilder>
        </div>
        {editUserModal()}
        <InviteUserModal licenses={licenses} setLicenses={setLicenses} kind={KIND.primary} orgId={orgId} />
        <Spacer></Spacer>
    </div>
}
