import { Block } from 'baseui/block';
import { DatePicker } from "baseui/datepicker";
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { Textarea } from "baseui/textarea";
import { TimePicker } from "baseui/timepicker";
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setInfo } from '../../actions/pole';
import { CARD_STYLING } from './styles';

const PoleInfo = ({ setInfo, pole, projectName }) => {
    const [description, setDescription] = useState(pole.info.description);

    return (
        <div style={{ width: '100%', justifyContent: 'center' }}>
            <div style={CARD_STYLING}>
                <FormControl label="Project Name">
                    <Input
                        disabled
                        value={projectName}
                        size="mini"
                    />
                </FormControl>
                <FormControl label="Structure Name">
                    <Input
                        value={pole.info.name}
                        onChange={e => setInfo({ name: e.target.value })}
                        size="mini"
                    />
                </FormControl>
                <FormControl label="Date & Time">
                    <Block display="flex">
                        <Block width="200px" marginRight='10px'>
                            <DatePicker value={new Date(pole.info.date)} onChange={({ date }) => setInfo({ date })} size='mini' />
                        </Block>
                        <Block width="100%">
                            <TimePicker value={new Date(pole.info.date)} onChange={date => setInfo({ date })} size='mini' />
                        </Block>
                    </Block>
                </FormControl>
                <FormControl label="Description">
                    <Textarea value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        onBlur={() => setInfo({ description })}
                        rows={6}
                        overrides={{
                        }}>
                    </Textarea>
                </FormControl>
            </div>
        </div>)
}

export default connect(state => ({ pole: state.pole, projectName: state.ui.projectName }), { setInfo })(PoleInfo);
