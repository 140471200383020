import { ADD_PROJECT, DELETE_PROJECT, SET_ALL_PROJECTS, UPDATE_PROJECT } from "../actions/projects";

const getDefaultState = () => ([]);

export const projects = (projects = getDefaultState(), action) => {
    switch (action.type) {
        case ADD_PROJECT:
            return {
                ...projects,
                [action.project.id]: { ...action.project },
            }
        case UPDATE_PROJECT:
            return {
                ...projects,
                [action.project.id]: { ...action.project },
            }
        case DELETE_PROJECT:
            let newProjects = {
                ...projects,
            };
            delete newProjects[action.projectId];
            return newProjects;
        case SET_ALL_PROJECTS:
            return action.projects;
        default:
            return projects;
    }
}