import { Block } from "baseui/block";
import { Navigation } from "baseui/side-navigation";
import React from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { setMenuOpen } from "../../actions/ui";
export const ROUTE_APP = "/app";
export const ROUTE_ACCOUNT = "/account";
export const ROUTE_TOS = "/tos";
export const ROUTE_ONBOARD = "/onboard";
export const ROUTE_PAYMENT_SUCCESS = "/payment-success";
export const ROUTE_MODEL = "/app/model";
export const ROUTE_DATABASE = "/app/database";
export const ROUTE_SIGNUP = "/signup";
export const ROUTE_ADMIN = "/admin";
export const ROUTE_LOGIN = "/login";
export const ROUTE_REGISTER_CODE = "/registerCode";
export const ROUTE_LOGOUT = "/logout";
export const ROUTE_CREATEPOLE = "/createPole";
export const ROUTE_VIEWPOLE = "/viewPole";


const SIDEBAR_LINKS_ALL_USERS = [
  {
    title: "Account",
    itemId: ROUTE_ACCOUNT,
  },
];

const SIDEBAR_LINKS_LICENSED = [
  {
    title: "Model",
    itemId: ROUTE_MODEL,
  },
  {
    title: "Database",
    itemId: ROUTE_DATABASE,
  },
];

const SIDEBAR_LINKS_SUSR = [
  {
    title: "Administration",
    itemId: ROUTE_ADMIN
  }
]

const Sidebar = ({ menuOpen, setMenuOpen, licensed, susr }) => {
  const history = useHistory();
  const location = useLocation();

  function navigate(event, item) {
    event.preventDefault();
    history.push(item.itemId);
    setMenuOpen(false);
  }

  function buildLinks() {
    let linksToReturn = [...SIDEBAR_LINKS_ALL_USERS];
    if (licensed) {
      linksToReturn = [...SIDEBAR_LINKS_LICENSED, ...linksToReturn]
    }

    if (susr) {
      linksToReturn = [...linksToReturn, ...SIDEBAR_LINKS_SUSR]
    }
    return linksToReturn;
  }

  return (
    <Block
      display={[menuOpen ? 'block' : 'none', menuOpen ? 'block' : 'none', 'block', 'block']}
      minWidth={[menuOpen ? '200px' : '0px', menuOpen ? '200px' : '0px', '200px', '200px']}
      minHeight={'calc(100vh - 80px)'}
      maxHeight={'100vh'}
      paddingLeft={0}
      paddingRight={0}>
      <Navigation
        items={buildLinks()}
        overrides={{
          Root: {
            style: ({ $theme }) => {
              return {
                minHeight: "100%",
                backgroundColor: $theme.colors.backgroundTertiary,
                width: "200px",
              };
            },
          },
        }}
        activeItemId={location.pathname}
        onChange={({ event, item }) => navigate(event, item)}
      />
    </Block>
  );
};

export default connect(state => ({
  menuOpen: state.ui.menuOpen,
  licensed: state.user.claims.licensed,
  susr: state.user.claims.susr
}), {
  setMenuOpen
})(Sidebar);
