import { Button, SIZE } from 'baseui/button';
import { Card } from 'baseui/card';
import { Checkbox } from 'baseui/checkbox';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { ListItem, ListItemLabel } from 'baseui/list';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal';
import { StatefulSelect as Search, TYPE } from 'baseui/select';
import { StyledSpinnerNext } from 'baseui/spinner';
import { Tab, Tabs } from "baseui/tabs-motion";
import { PLACEMENT, ToasterContainer, toaster } from "baseui/toast";
import { Label2, Paragraph1 } from 'baseui/typography';
import React from 'react';
import { connect } from 'react-redux';
import { setUserDetails } from '../actions/user';
import { APIPurchasesComponent } from '../components/account/APIPurchasesComponent';
import { LicenseTable } from '../components/account/LicenseTab';
import BillingAPI from '../firebase/BillingAPI';
import UserApi from '../firebase/UserApi';
import { CardOverride, ModalOverride, ToasterContainerOverride } from '../theming';
import "./AdminPage.css";


export const AdminPage = ({ claims }) => {
    const [user, setUser] = React.useState([]);
    const [organization, setOrganizations] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [licenses, setLicenses] = React.useState([]);
    const [admins, setAdmins] = React.useState(undefined);
    const [queriedOrganization, setQueriedOrg] = React.useState();
    const [adminAddPanelOpen, setAdminAddPanelOpen] = React.useState(false);
    const [organizationOptions, setOrganizationOptions] = React.useState([]);
    const [isOrgDirty, setIsOrgDirty] = React.useState(false);
    const [activeKey, setActiveKey] = React.useState("0");
    const [addOrgPanelOpen, setAddOrgPanelOpen] = React.useState(false);
    const [orgNameInput, setOrgNameInput] = React.useState('');
    const [licenseCountInput, setLicenseCountInput] = React.useState(undefined);
    const [tinInput, setTINInput] = React.useState('');
    const [initialAdminEmail, setInitialAdminEmail] = React.useState('');
    const [updatingOrgLoading, setUpdatingOrgLoading] = React.useState(false);
    const [addOrgErrors, setAddOrgErrors] = React.useState({});
    const [checked, setChecked] = React.useState(false);
    const handleSetOptions = (users) => {
        setOptions(users.map(([id, email]) => ({ label: `${email}`, id })));
    }
    const handleSetOrganizationOptions = (orgs) => {
        setOrganizationOptions(orgs.map(([id, name]) => ({ label: `${name}`, id })));
    }

    const setAndQuery = async (user) => {
        if (user.length) {
            let x = await UserApi.getUserClaims(user[0].id);
        }

        setUser(user)
    }

    const setOrgAndQuery = async (org) => {
        if (org.length) {
            let x = await UserApi.getOrganizationDetails(org[0].id);
            setQueriedOrg({ ...x, id: org[0].id })
        }

        setOrganizations(org)
    }

    const debounce = (func, delay) => {
        let timeoutId;
        const debounced = (...args) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => func(...args), delay);
        }
        return debounced;
    }

    const handleInputChange = debounce(keyword => {
        if (!keyword) {
            setOptions([]);
            return;
        }
        setIsLoading(true);
        UserApi.search(keyword)
            .then(handleSetOptions)
            .catch(e => console.log(e))
            .finally(() => setIsLoading(false));
    }, 400);

    const handleOrganizationsInputChange = debounce(keyword => {
        if (!keyword) {
            setOrganizationOptions([]);
            return;
        }
        setIsLoading(true);
        UserApi.searchOrgs(keyword)
            .then(handleSetOrganizationOptions)
            .catch(e => console.log(e))
            .finally(() => setIsLoading(false));
    }, 400);

    const saveOrgChanges = () => {
        setUpdatingOrgLoading(true);
        UserApi.updateOrg(queriedOrganization.id, queriedOrganization.TIN, queriedOrganization.licenses, queriedOrganization.apiQuotaPurchased, queriedOrganization.testApiQuotaPurchased).then(e => {
            setIsOrgDirty(false)
            setUpdatingOrgLoading(false)
        }).catch(e => {
            toaster.negative('Failed to update: ' + e.message)
            setUpdatingOrgLoading(false)
        })
    }

    const retrieveAdminList = () => {
        if (admins !== undefined) {
            setAdmins(undefined)
        }

        UserApi.getAdmins()
            .then(data => setAdmins(data))
            .catch(e => toaster.negative(e.message))
    }

    const changeLicenseCount = (newCount) => {
        setQueriedOrg({ ...queriedOrganization, licenses: newCount })
        setIsOrgDirty(true);
    }

    const changeAPICallCount = (newCount) => {
        setQueriedOrg({ ...queriedOrganization, apiQuotaPurchased: newCount })
        setIsOrgDirty(true);
    }

    const changeTestAPICallCount = (newCount) => {
        setQueriedOrg({ ...queriedOrganization, testApiQuotaPurchased: newCount })
        setIsOrgDirty(true);
    }

    const makeOrgPanel = () => {
        return <Card overrides={CardOverride}>
            <FormControl label="Name">
                <Input disabled value={queriedOrganization.name}></Input>
            </FormControl>
            <FormControl label="Manual Licenses">
                <div style={{ display: 'flex' }}>
                    <Input value={queriedOrganization.licenses} onChange={(e) => changeLicenseCount(e.target.value)}></Input>
                </div>
            </FormControl>
            <FormControl label="API Calls">
                <div style={{ display: 'flex' }}>
                    <Input value={queriedOrganization.apiQuotaPurchased} type="number" onChange={(e) => changeAPICallCount(e.target.value)}></Input>
                </div>
            </FormControl>
            <FormControl label="Test API Calls">
                <div style={{ display: 'flex' }}>
                    <Input value={queriedOrganization.testApiQuotaPurchased} type="number" onChange={(e) => changeTestAPICallCount(e.target.value)}></Input>
                </div>
            </FormControl>
            <FormControl label="Stripe Licenses">
                <div style={{ width: '100%' }}>
                    <Paragraph1>{BillingAPI.getStripeLicensesString(queriedOrganization)}</Paragraph1>

                </div>
            </FormControl>
            <FormControl label="Tax Id">
                <Input value={queriedOrganization.TIN || ''} onChange={(e) => {
                    setIsOrgDirty(true);
                    setQueriedOrg({ ...queriedOrganization, TIN: e.target.value })
                }}></Input>
            </FormControl>
            <Button disabled={!isOrgDirty} onClick={() => saveOrgChanges()} isLoading={updatingOrgLoading}>Save</Button>
        </Card>
    }

    const makeAdmin = () => {
        UserApi.makeAdmin(user[0].id).then(e => {
            setAdminAddPanelOpen(false)
            retrieveAdminList();
        }).catch(e => toaster.negative(e.message))
    }

    const createOrganization = () => {
        let errors = {};
        if (!initialAdminEmail) {
            errors['adminEmailInput'] = 'Please enter an email to send an invite.'
        }
        if (!orgNameInput) {
            errors['orgNameInput'] = 'Please enter an organization name.'
        }
        if (!licenseCountInput) {
            errors['licenseCountInput'] = 'Please input how many licenses.'
        }
        if (Object.keys(errors).length) {
            setAddOrgErrors(errors);
        } else {
            UserApi.createOrganization(orgNameInput, licenseCountInput, tinInput, initialAdminEmail, checked).then(e => {
                setAddOrgPanelOpen(false)
                setChecked(false);
                setLicenseCountInput(undefined)
                setOrgNameInput('')
                setTINInput('')
                setInitialAdminEmail('');
            }).catch(e => toaster.negative(e.message))
        }
    }

    React.useEffect(() => {
        retrieveAdminList();
    }, [])

    React.useEffect(() => {
        if (!queriedOrganization?.id || isOrgDirty) return;
        UserApi.getLicenses(queriedOrganization.id).then(e => setLicenses(e)).catch(e => {
            console.error(e)
            setLicenses([]);
        });
    }, [queriedOrganization, isOrgDirty])


    return <>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '15px' }}>
            <div style={{ maxWidth: 2000, width: '95%' }}>
                <Tabs
                    activeKey={activeKey}
                    onChange={({ activeKey }) => setActiveKey(activeKey)}
                >
                    <Tab title="Organization Management">
                        <FlexGrid gridRowStart={300} flexGridColumnCount={2} flexGridColumnGap='scale1000'>
                            <FlexGridItem >
                                <Card overrides={CardOverride} title='Search for Organization...'>
                                    <Search
                                        value={organization}
                                        isLoading={isLoading}
                                        options={organizationOptions}
                                        type={TYPE.search}
                                        onInputChange={({ target }) => handleOrganizationsInputChange(target.value)}
                                        onChange={({ value }) => setOrgAndQuery(value)} />
                                    {
                                        queriedOrganization ? makeOrgPanel() : undefined
                                    }
                                    <Button onClick={() => setAddOrgPanelOpen(true)}>
                                        Add Organization
                                    </Button>
                                    <Modal overrides={ModalOverride} isOpen={addOrgPanelOpen} onClose={() => setAddOrgPanelOpen(false)}>
                                        <ModalHeader>Add Organization</ModalHeader>
                                        <ModalBody>Enter details here:
                                            <FormControl label="Organization Name" error={addOrgErrors['orgNameInput']}>
                                                <Input value={orgNameInput} onChange={(e) => setOrgNameInput(e.target.value)}></Input>
                                            </FormControl>
                                            <FormControl label="Manual License Count" error={addOrgErrors['licenseCountInput']}>
                                                <Input type="number" value={licenseCountInput} onChange={(e) => setLicenseCountInput(e.target.value)} />
                                            </FormControl>
                                            <div className='access__freeTrial'>
                                                <Checkbox
                                                    checked={checked}
                                                    onChange={() => setChecked(!checked)}
                                                >
                                                    Access to free trial
                                                </Checkbox>
                                            </div>
                                            <FormControl label="Tax Id" >
                                                <Input value={tinInput} onChange={(e) => {
                                                    setTINInput(e.target.value);
                                                }}></Input>
                                            </FormControl>
                                            <FormControl label="Admin Email" error={addOrgErrors['adminEmailInput']}>
                                                <Input value={initialAdminEmail} onChange={(e) => {
                                                    setInitialAdminEmail(e.target.value);
                                                }}></Input>
                                            </FormControl>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button onClick={(e) => createOrganization()}>Create Organization</Button>
                                        </ModalFooter>
                                    </Modal>
                                </Card>
                            </FlexGridItem>
                            <FlexGridItem>
                                {queriedOrganization && queriedOrganization.apiEnabled ? <APIPurchasesComponent orgData={queriedOrganization} orgId={queriedOrganization.id} /> : undefined}
                                {queriedOrganization ? <LicenseTable licenses={licenses} setLicenses={setLicenses} orgId={queriedOrganization?.id} orgData={queriedOrganization} /> : undefined}
                            </FlexGridItem>
                        </FlexGrid>
                    </Tab>
                    <Tab title="Admins">
                        <div>
                            <Label2>Admins:</Label2>
                            <ul style={{
                                width: '375px',
                                paddingLeft: 0,
                                paddingRight: 0,
                            }}>
                                {
                                    admins !== undefined ? admins.map((adminUser, index) => <ListItem key={index + 'admin'}>
                                        <ListItemLabel>{adminUser.email}</ListItemLabel>
                                        <div style={{ padding: '5px', width: '120px', alignItems: 'right' }}>
                                            <Button size={SIZE.mini} onClick={() => UserApi.removeAdmin(adminUser.id).then(() => retrieveAdminList()).catch(e => toaster.negative(e.message))}>Remove Admin</Button>
                                        </div>
                                    </ListItem>) : <div style={{ padding: '5px' }}><StyledSpinnerNext></StyledSpinnerNext></div>
                                }
                                <Modal overrides={ModalOverride} isOpen={adminAddPanelOpen} onClose={() => setAdminAddPanelOpen(false)}>
                                    <ModalHeader>Add Admin</ModalHeader>
                                    <ModalBody>Add an administrator of D-Calc
                                        <Search
                                            value={user}
                                            isLoading={isLoading}
                                            options={options}
                                            type={TYPE.search}
                                            onInputChange={({ target }) => handleInputChange(target.value)}
                                            onChange={({ value }) => setAndQuery(value)} />
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button onClick={(e) => makeAdmin()}>Make Admin</Button>
                                    </ModalFooter>
                                </Modal>
                                <Button size={SIZE.mini} onClick={(e) => setAdminAddPanelOpen(true)}>Add Admin</Button>
                            </ul>

                        </div>
                    </Tab>

                </Tabs>
                <ToasterContainer overrides={ToasterContainerOverride} placement={PLACEMENT.bottomLeft} autoHideDuration={5000} />
            </div>
        </div>
    </>
}

export default connect(state => ({
    claims: state.user.claims,
}), {
    setUserDetails
})(AdminPage);