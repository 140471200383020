import { AnalyzePole, Pole, poleSchema, SectionCalculator } from "@edm-internationa/d-calc-calculations";
import firebase from "firebase";
import SyncClientDB from "../components/database/SyncClientDB";
import { getAnsiDiameter, setPole } from "./pole";
import { updateProject } from "./projects";
import { setIsSaveDirty, setProject } from "./ui";

export const UPDATE_POLE = "UPDATE_POLE";
const { v4: uuidv4 } = require("uuid");

export const setCurrentPoleWithoutSaving = (pole, projectName, projectId) => {
    return async (dispatch) => {
        let timestamp = firebase.firestore.Timestamp.fromDate(new Date());
        let uuid = uuidv4();
        let newPole = new Pole(pole);
        newPole.id = uuid;
        newPole.info.id = uuid;
        newPole.info.updatedTimestamp = timestamp;

        if (projectId) {
            newPole.projectId = projectId;
        }

        if (projectName) {
            dispatch(setProject(projectName, projectId))
        }
        dispatch(setPole(newPole));
        dispatch(setIsSaveDirty(true))
    }
}

export const addPole = (projectId, skipSettingCurrent, useCurrentPole) => {
    return async (dispatch, getState) => {
        let currentState = getState();
        if (!projectId) {
            projectId = currentState.pole.projectId;
        }

        let newPole;
        let uuid;
        if (useCurrentPole) {
            let currentPole = currentState.pole;
            newPole = new Pole(currentPole);
            newPole.id = currentPole.info.id;
            uuid = currentPole.info.id;
        } else {
            uuid = uuidv4();
            newPole = new Pole();
            newPole.id = uuid;
            newPole.info.id = uuid;
        }

        let timestamp = firebase.firestore.Timestamp.fromDate(new Date());
        newPole.info.updatedTimestamp = timestamp;
        newPole.projectId = projectId;

        if (!skipSettingCurrent) {
            dispatch(setPole(newPole));
        }
    };
};

export const viewPole = (pole, projectName, projectId) => {
    return async (dispatch) => {
        dispatch(setProject(projectName, projectId))
        dispatch(setPole(pole));
    }
}


export const calculatePoleValues = (pole) => {
    let output = { ...poleSchema.output };
    for (let level = 1; level < 4; level++) {
        const damageParameters = pole.damageParameters[level];
        const { damages } = pole.damageParameters[level];
        if (damages.length) {
            let raw = new SectionCalculator().calculateLevel(damageParameters);
            const ansiDiameter = getAnsiDiameter(pole.info, damageParameters);
            let analyze = new AnalyzePole(damageParameters, ansiDiameter);
            let outputLevel = analyze.calculateForAPI(raw);
            outputLevel.svg = raw.svg;

            if (ansiDiameter !== undefined) {
                outputLevel.ansiDiameter = ansiDiameter;
            } else {
                outputLevel.ansiDiameter = 0;
            }

            output[level] = outputLevel;
        }
    }
    return output;
}
export const savePole = (callback, projectId) => {
    return async (dispatch, getState) => {
        let idb = SyncClientDB.getIDB();

        let pole = getState().pole;
        try {
            let output = calculatePoleValues(pole)

            let uuid = pole.info.id ? pole.info.id : uuidv4();
            pole.output = output;
            pole.id = uuid;
            if (!projectId) {
                projectId = getState().pole.projectId;
            }

            pole.projectId = projectId;

            let timestamp = firebase.firestore.Timestamp.fromDate(new Date());
            pole["updatedTimestamp"] = timestamp;

            let idbPole = await idb.poles.get(uuid)
            console.log(idbPole)
            await sendPoleToIDBAndServer(pole, pole.projectId, dispatch);

            if (callback) callback('success');
        } catch (e) {
            console.log(e);
            if (callback) callback('fail');
        }
    };
};

export const deletePole = async (poleId, projectId) => {
    try {
        let idb = SyncClientDB.getIDB();
        await idb.transaction("rw", idb.poles, idb.projects, async () => {
            let timestamp = new Date();
            await idb.poles.where("id").equals(poleId).modify({
                deleted: "1",
                deletedTimestamp: timestamp,
            });
            if (projectId !== "") {
                let project = await idb.projects.get(projectId);
                await idb.projects.put(project);
            }
        });
        return true;
    } catch (e) {
        return false
    }
};

async function sendPoleToIDBAndServer(newPole, projectId, dispatch) {
    let idb = SyncClientDB.getIDB();
    console.log('sending pole to IDB')
    await (idb).transaction("rw", idb.poles, idb.projects, async () => {
        await idb.poles.put({ ...newPole, deleted: "0" });
        let project = await idb.projects.get(projectId);
        await (idb).projects.put(project);
        dispatch(updateProject(project));
    });
}

