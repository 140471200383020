import SyncClientDB from "./SyncClientDB";

const POLL_INTERVAL = 10 * 60 * 1000
export default function initSync(serverComm, isOnline) {
    return async function sync(
      context,
      url,
      options,
      baseRevision,
      syncedRevision,
      changes,
      partial,
      applyRemoteChanges,
      onChangesAccepted,
      onSuccess,
      onError
    ) {
      options.pollInterval = POLL_INTERVAL
      const request = {
        // Will not be defined the first time we call the server
        clientIdentity: context.clientIdentity,
        baseRevision,
        partial,
        changes,
        syncedRevision,
      };

      //Hack to get dexie to stop syncing before a page refresh since it doesn't work otherwise
      const shouldSync = await SyncClientDB.getShouldSync();
      if (!shouldSync) {
        options.pollInterval = Infinity;
        onSuccess({ again: options.pollInterval });
        return
      } else {
        try {
          let data = await serverComm(url, request, options)
          if (!data.success) {
              console.error('error')
              console.error(data)
            // Server didn't accept our changes. Stop trying to sync
            onError(data.errorMessage, Infinity);
          } else {
            console.log('accepting Changes here')
            data = {changes: data.changes, currentRevision: new Date(), partial: false}
            
            applyRemoteChanges(data.changes, data.currentRevision, data.partial, false);
            onChangesAccepted();
            onSuccess({ again: options.pollInterval });
          }
        } catch(e) {
          console.log("ERROR: DEXIE", e)
          let status = await isOnline(url)
          console.log('getting status here: ', status)
          if (status) { // We were temporarily offline -> retry
            onError(e, options.pollInterval);
          } else { // Was probably not just a temp thing -> stop retrying
            // Synable will automatically disconnect us with an ERROR
            // and we will have to manually reconnect
            onError(e, Infinity);
          }
        }  
      }
    };
  }