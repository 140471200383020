import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import React from "react";
import Firebase from "../firebase/Firebase";

const withStripe = Component => {
    class WithStripe extends React.Component {
        constructor(props) {
            super(props);
        }

        render() {
            let pk = Firebase.env === 'production' ?
                'pk_live_51I66IPJRYVeZiLPpFpjXonAHpCjyPkfAEBPVIILQZfljeGzNPwBUyIEMBQh5UHhJgMXmz5my5ghlF0zo8h5UtMZo00LxNVH34m' :
                'pk_test_51I66IPJRYVeZiLPpUbtxye2kR3dCDg3FHkiDyaBc8GDhCDsvbNNrCwdk82HodBSUmBSpwz4SBjcUKUL2WaD4L3p900zIuLlXNk';

            return (
                <Elements stripe={loadStripe(pk)}>
                    <Component {...this.props} />
                </Elements>
            )
        }
    }

    return WithStripe;
}

export default withStripe;