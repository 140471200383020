import { ANSITables, ANSI_CLASSES, ANSI_LENGTHS, ansiSpeciesList } from '@edm-internationa/d-calc-calculations';
import { Block } from "baseui/block";
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { Cell } from 'baseui/layout-grid';
import { Notification } from 'baseui/notification';
import { Radio, RadioGroup } from 'baseui/radio';
import { Select } from 'baseui/select';
import { Tab, Tabs } from 'baseui/tabs-motion';
import { Label1, Label3 } from "baseui/typography";
import React from 'react';
import { connect } from 'react-redux';
import { setInfo } from '../../actions/pole';
import { NotificationOverride } from '../../theming';
import useIsMobile from '../../utils/useIsMobile';
import { CompactH2 } from './Assess';
import ReferenceChart from './ReferenceChart';

const ANSITab = ({ info: { ansiSpecies, ansiClass, ansiLength, userEmbedment, isLinearTaper }, setInfo }) => {
  const [isValid, setIsValid] = React.useState(true);
  const [currentTab, setCurrentTab] = React.useState(true);
  const [errorType, setErrorType] = React.useState(undefined);
  const shouldShowError = !isValid;
  const handleSpecies = ({ value: [{ label, id }] }) => {
    setInfo({ ansiSpecies: { id, name: label }, ansiClass: undefined, ansiLength: undefined });
  }
  const handleClass = ({ value: [{ id }] }) => {
    setInfo({ ansiClass: id, ansiLength: undefined });
  }
  const handleLength = ({ value: [{ id }] }) => setInfo({ ansiLength: id });

  const makeSelectValue = (id) => id !== undefined || id !== '' ? [{ id, label: id }] : undefined;
  const isMobile = useIsMobile();


  const validateInput = (number) => {
    let errorMessage = "";
    if (isNaN(number)) {
      errorMessage = "Input should be a number";
    }
    else if (0 > number) {
      errorMessage = "Input should be a positive value";
    }
    else if (number > ansiLength) {
      errorMessage = "Input should be less than length";
    }
    return errorMessage;
  }

  const EmbedmentInput = () => {
    const [numberInput, setNumberInput] = React.useState(userEmbedment);


    const setUserEmbedment = () => {
      let number = parseFloat(numberInput);
      let errorMessage = validateInput(number);
      if (errorMessage.length > 0) {
        setIsValid(false);
        setErrorType(errorMessage);
      }
      else {
        setInfo({ userEmbedment: number })
        setIsValid(true);
        setErrorType(undefined)
      }
    }

    return <FormControl
      error={shouldShowError ? errorType : ""}
    >
      <Input
        endEnhancer="ft"
        onChange={(e) => setNumberInput(e.target.value)}
        value={numberInput}
        error={shouldShowError}
        onBlur={() => setUserEmbedment()}
      />
    </FormControl>
  }



  const CascadeDropDownTab = () => {
    let validClasses = [];
    let validLengths = [];

    if (ansiSpecies) {
      console.log(ansiSpecies)
      const ANSIId = ansiSpeciesList[ansiSpecies.id].ansiSpeciesMapId;
      const table = ANSITables[ANSIId];
      let columnRef = {};
      for (let rowIndex in table) {
        if (rowIndex === '0') continue;
        for (let columnIndex in table[rowIndex]) {
          if (columnIndex === '0') continue;
          if (table[rowIndex][columnIndex] !== 0) columnRef[columnIndex] = true;
        }
      }

      ANSI_CLASSES.forEach((e, i) => {
        if (columnRef[i + 1]) {
          validClasses.push(e);
        }
      })
    }

    if (ansiClass) {
      const ANSIId = ansiSpeciesList[ansiSpecies.id].ansiSpeciesMapId;
      const table = ANSITables[ANSIId];

      let classId = ANSI_CLASSES.indexOf(ansiClass);
      for (let rowIndex in table) {
        if (rowIndex === '0') continue
        if (table[rowIndex][classId + 1] !== 0) validLengths.push(ANSI_LENGTHS[rowIndex - 1])
      }
    }


    return <>
      <CompactH2>Classification</CompactH2>
      <FormControl label={"Species"}>
        <Select
          clearable={false}
          value={ansiSpecies}
          size={'compact'}
          onChange={handleSpecies}
          placeholder=''
          searchable={false}
          options={ansiSpeciesList} />
      </FormControl>
      <FormControl label={"Class"}>
        <Select
          disabled={!ansiSpecies}
          size='compact'
          clearable={false}
          searchable={false}
          value={makeSelectValue(ansiClass)}
          onChange={handleClass}
          options={ANSI_CLASSES.map(ansiClass => ({ label: ansiClass, id: ansiClass, disabled: !validClasses.includes(ansiClass) }))}
        />
      </FormControl>
      <FormControl label={"Length"}>
        <Select
          disabled={!ansiClass}
          size='compact'
          clearable={false}
          searchable={false}
          value={makeSelectValue(ansiLength)}
          onChange={handleLength}
          options={ANSI_LENGTHS.map((length, lengthIndex) => ({ label: length, id: length, disabled: !validLengths.includes(length) }))} />
      </FormControl>

    </>
  }

  const ANSIReferenceTableDropDownTab = () => {

    return <>
      {ansiSpecies['name'] && <Cell span={12}>
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <Block style={{ width: "50%" }}>
            <FormControl label={<Label1> Taper Options </Label1>}>
              <RadioGroup
                value={isLinearTaper ? 'linear' : 'ansi'}
                onChange={e => setInfo({ isLinearTaper: e.target.value === 'linear' })}
                align='vertical'
              >
                <Radio value='linear'>Linear Taper</Radio>
                <Radio value='ansi'>ANSI Taper</Radio>
              </RadioGroup>
            </FormControl>
          </Block>
          <Block style={{ width: "50%" }}>
            <FormControl label={<Label1> Embedment Options </Label1>}>
              <RadioGroup
                value={userEmbedment !== undefined ? 'user' : 'ansi'}
                onChange={e => setInfo({ userEmbedment: e.target.value === 'user' ? userEmbedment || 0 : undefined })}
                align='vertical'
              >
                <Radio value='ansi'>ANSI Embedment</Radio>
                <Radio value='user'>User Defined Embedment</Radio>
              </RadioGroup>
            </FormControl>
            {userEmbedment !== undefined ? <EmbedmentInput /> : null}
          </Block>
        </div>
      </Cell>}
    </>
  }


  return (
    <Tabs onChange={(e) => setCurrentTab(e.activeKey)} activeKey={currentTab}>
      <Tab title='Parameters' key="1">
        <CascadeDropDownTab />
        <ANSIReferenceTableDropDownTab />
      </Tab>
      <Tab title='Table' key="2">
        <CompactH2>Reference</CompactH2>
        <FormControl label={"Species"}>
          <Select
            clearable={false}
            value={ansiSpecies}
            size={'compact'}
            onChange={handleSpecies}
            placeholder=''
            searchable={false}
            options={ansiSpeciesList} />
        </FormControl>
        <Label3>Click on the table to set a length and class</Label3>
        {ansiSpecies.id !== undefined ? <ReferenceChart ansiSpeciesId={ansiSpecies.id} /> : <Notification overrides={NotificationOverride} >Please select a species to begin.</Notification>}

      </Tab>
    </Tabs>
  );
}

export default connect(state => ({ info: state.pole.info }), { setInfo })(ANSITab);