import { TYPE_BOLT, TYPE_CAVITY, TYPE_HEART, TYPE_POCKET, TYPE_SECTION, TYPE_SHELL, TYPE_SLICE, TYPE_WEDGE } from '@edm-internationa/d-calc-calculations';
import { Bolt, Cavity, Heart, Pocket, Section, Shell, Slice, Wedge } from '../../assets';
import { WidthOrDepth } from './WidthOrDepth';

const inputs = [
  {
    name: 'Heart',
    type: TYPE_HEART,
    iconUrl: Heart,
    fields: [{ label: "Shell Thickness", fieldId: "shellThickness", unit: "in" }],
  },
  {
    name: 'Shell',
    type: TYPE_SHELL,
    iconUrl: Shell,
    fields: [{ label: "Damage Depth", fieldId: 'damageDepth', unit: "in" }],
  },
  {
    name: 'Pocket',
    type: TYPE_POCKET,
    iconUrl: Pocket,
    fields: [{ label: "Pocket Diameter", fieldId: "pocketDiameter", unit: "in" }, { label: "Shell Thickness", fieldId: "shellThickness", unit: "in" },{ label: "Angle To Min Shell", fieldId: "angleToMinShell", unit: "deg" }, ],
  },
  {
    name: 'Wedge',
    type: TYPE_WEDGE,
    iconUrl: Wedge,
    fields: [{ label: "Circumference Missing", fieldId: "circumferenceMissing", unit: "in" }, { label: "Depth Missing", fieldId: "depthMissing", unit: "in" }, { label: "Angle To Damage", fieldId: "angleToDamage", unit: "deg" }],
  },
  {
    name: 'Section',
    type: TYPE_SECTION,
    iconUrl: Section,
    fields: [{ label: "Depth Missing", fieldId: "depthMissing", unit: "in" }, { label: "Circumference Missing", fieldId: "circumferenceMissing", unit: "in" }, { label: "Shell Thickness", fieldId: "shellThickness", unit: "in" },{ label: "Angle To Opening", fieldId: "angleToOpening", unit: "deg" }, ],
  },
  {
    name: 'Slice',
    type: TYPE_SLICE,
    iconUrl: Slice,
    fields: [{ label: "Depth Missing", fieldId: "depthMissing", unit: "in", Component: WidthOrDepth },{ label: "Angle To Damage", fieldId: "angleToDamage", unit: "deg" }, ],
  },
  {
    name: 'Cavity',
    type: TYPE_CAVITY,
    iconUrl: Cavity,
    fields: [{ label: "Circumference Missing", fieldId: "circumferenceMissing", unit: "in" }, { label: "Shell Thickness At Opening", fieldId: "shellThicknessAtOpening", unit: "in" }, { label: "Nest Diameter", fieldId: "nestDiameter", unit: "in" },{ label: "Angle To Opening", fieldId: "angleToOpening", unit: "deg" }],
  },
  {
    name: 'Bolt',
    type: TYPE_BOLT,
    iconUrl: Bolt,
    fields: [{ label: "Hole Diameter", fieldId: "holeDiameter", unit: "in" }, { label: "Angle At Opening", fieldId: "angleToOpening", unit: "deg" }, ],
  },
];

export default inputs;