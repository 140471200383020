import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { ALIGN, HeaderNavigation, StyledNavigationItem, StyledNavigationList } from "baseui/header-navigation";
import { Menu } from "baseui/icon";
import { Display3 } from "baseui/typography";
import React from "react";
import { connect } from "react-redux";
import { useMediaQuery } from 'react-responsive';
import { setMenuOpen } from "../../actions/ui";
import { logo } from "../../assets";


export const Header = ({ menuOpen, setMenuOpen, logoOnly }) => {
  const shouldShowHashMenu = useMediaQuery({ query: '(max-width: 600px)' }) && !logoOnly

  return (
    <HeaderNavigation
      overrides={{
        Root: {
          style: {
            height: "79px",
            paddingRight: "20px",
            paddingLeft: "0px",
            backgroundColor: logoOnly ? '#EEEEEE' : 'white',
            paddingTop: 0,
            paddingBottom: 0,
            margin: 0,
            maxWidth: "100vw"
          },
        },
      }}>
      <StyledNavigationList $align={ALIGN.left} padding='0px'>
        <StyledNavigationItem>
          <Block display="flex" alignItems="center">
            <img src={logo} length="62px" height="60px" alt='d-calc logo' />
            {!logoOnly && <Display3 marginLeft={'15px'} marginTop={'-3px'}>
              D-Calc
          </Display3>}
          </Block>
        </StyledNavigationItem>
        {shouldShowHashMenu ? <>
          <StyledNavigationItem />
          <StyledNavigationItem>
            <Button kind="minimal" size='mini' onClick={(e) => setMenuOpen(!menuOpen)}>
              <Menu size={20} />
            </Button>
          </StyledNavigationItem></> : undefined}
      </StyledNavigationList>
      <div style={{ margin: 'auto' }}></div>
      {/* <StyledNavigationList $align={ALIGN.right}> */}
      {/* <StyledNavigationItem>
          <Button
            onClick={() => {
              history.push("/admin");
            }}>
            Admin
          </Button>
        </StyledNavigationItem> */}
      {/* <Button
            onClick={() =>
              firebase
                .auth()
                .signOut()
                .then((e) => console.log(e))
                .catch((e) => console.log(e))
            }>
            Log Out
          </Button> */}

    </HeaderNavigation>
  );
};

export const ConnectedHeader = connect(
  (state) => ({
    menuOpen: state.ui.menuOpen
  }),
  {
    setMenuOpen
  }
)(Header);
