import { Pole } from "@edm-internationa/d-calc-calculations";
import _ from 'lodash';
import { ADD_DAMAGE, REMOVE_DAMAGE, SET_DAMAGE_PARAMETERS, SET_DAMAGE_VALUE, SET_INFO, SET_OUTPUT, SET_POLE, SET_PROJECT_ID } from "../actions/pole";
import inputs from "../components/inputs/inputs";

const getDefaultState = () => new Pole();

function removeDamage(pole, action) {
    let newPole = _.cloneDeep(pole);
    newPole.damageParameters[+action.level].damages.splice(action.damageIndex, 1);
    return newPole
}

function addDamage(pole, action) {
    let newPole = _.cloneDeep(pole);
    newPole.damageParameters[action.level].damages.push(action.damage)
    return newPole
}

function setDamageValue(pole, action) {
    let newPole = _.cloneDeep(pole);

    let damages = [...pole.damageParameters[action.level].damages];
    if (damages[action.damageIndex] && !action.clear) {
        damages[action.damageIndex] = Object.assign({ ...damages[action.damageIndex] }, action.damage)
    } else {
        damages[action.damageIndex] = action.damage;
    }
    let foundInput = inputs.find(input => input.type === damages[action.damageIndex]['type'])

    let foundField = foundInput.fields.find(field => field.fieldId.includes('angle'));
    if (foundField && damages[action.damageIndex][foundField.fieldId] === undefined) {
        damages[action.damageIndex][foundField.fieldId] = 0;
    }

    newPole.damageParameters[action.level].damages = damages;
    return newPole
}

export const poleReducer = (pole = getDefaultState(), action) => {
    let damageAtLevel;
    let damages;
    switch (action.type) {
        case SET_PROJECT_ID:
            let newPole = _.cloneDeep(pole);
            newPole.projectId = action.projectId;
            return newPole;
        case SET_INFO:
            pole.info = {
                ...pole.info,
                ...action.info,
            };
            if (pole.info.userEmbedment === undefined) {
                delete pole.info.userEmbedment;
            }

            return _.cloneDeep(pole);
        case SET_DAMAGE_VALUE:
            return setDamageValue(pole, action)
        case ADD_DAMAGE:
            return addDamage(pole, action)
        case REMOVE_DAMAGE:
            return removeDamage(pole, action);
        case SET_DAMAGE_PARAMETERS:
            pole.damageParameters[action.level] = {
                ...pole.damageParameters[action.level],
                ...action.damageParameters,
            }
            return _.cloneDeep(pole);
        case SET_OUTPUT:
            pole.output[action.level] = {
                ...pole.output[action.level],
                ...action.output
            }
            return _.cloneDeep(pole);
        case SET_POLE:
            return new Pole(action.pole);
        default:
            return pole;
    }
}