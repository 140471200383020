import { SVGDamagePreview, validateDamage } from '@edm-internationa/d-calc-calculations';
import { Block } from 'baseui/block';
import { Button, KIND } from 'baseui/button';
import { ArrowUp } from 'baseui/icon';
import { Cell } from 'baseui/layout-grid';
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { setDamageAngle } from '../../actions/pole';
import { setCurrentlyEditingAngle } from '../../actions/ui';
import { Spacer } from '../layout/Spacer';


//Dont set damageAngle if not needed
const DamagePreview = ({ section, isValid, setDamageAngle, showPreview, isEditing, setCurrentlyEditingAngle }) => {
    const level = 1;
    const ref = React.useRef();
    const preview = useRef(new SVGDamagePreview());
    const [svg, setSvg] = React.useState('')
    const [dragging, setDragging] = React.useState(false);

    React.useEffect(() => {
        renderSvg();
    }, [section])


    function renderSvg() {
        if (isValid) {
            preview.current.drawSection(level, section)
            setSvg(preview.current.getSvg(level));
        }
    }

    const dragStart = (e) => {
        e.stopPropagation();
        let d = __clickAngle(e);
        setDamageAngle(Math.round((d + 90)))
    }

    const __clickAngle = (e) => {
        const R2D = 180 / Math.PI;

        const { top, left, width, height } = ref.current.getBoundingClientRect();
        const center = {
            x: left + (width / 2),
            y: top + (height / 2)
        };

        if (e.touches && e.touches.length > 0) {
            let x = e.touches[0].clientX - center.x;
            let y = e.touches[0].clientY - center.y;
            return R2D * Math.atan2(y, x);
        } else {
            let x = e.clientX - center.x;
            let y = e.clientY - center.y;
            return R2D * Math.atan2(y, x);
        }
    }

    const drag = (e) => {
        let d = __clickAngle(e);
        e.stopPropagation();
        setDamageAngle(Math.round((d + 90)))
        renderSvg();
    }

    const dragEnd = (e) => {
        e.stopPropagation();
        let d = __clickAngle(e);
        setDamageAngle(Math.round((d + 90)))
        renderSvg();
    }

    if (!showPreview) return <></>

    return (
        <Cell skip={[0, 0, 0, 2]} span={[10, 10, 5, 4]}>
            <Spacer />
            <Block width="100%" position='relative'>
                {isEditing ? <div style={{ display: 'flex' }}><p>Click and drag on the pole to set angle. </p><div style={{ width: '15px' }}></div><Button kind={KIND.minimal} onClick={() => setCurrentlyEditingAngle(false)}>Done</Button></div> : undefined}
                {true && <div dangerouslySetInnerHTML={{ '__html': svg }} style={{ position: 'absolute', width: "96%", height: "96%", margin: '2.1%', touchAction: 'none' }} ref={ref}
                    onTouchMove={(e) => {
                        if (!isEditing) return;
                        if (dragging) drag(e)
                    }}
                    onTouchStart={(e) => {
                        if (!isEditing) return;
                        setDragging(true)
                        dragStart(e);
                    }}
                    onTouchEnd={(e) => {
                        if (!isEditing) return;
                        setDragging(false)
                        dragEnd(e);
                    }}
                    onMouseMove={(e) => {
                        if (!isEditing) return;
                        if (dragging) drag(e)
                    }}
                    onMouseDown={(e) => {
                        if (!isEditing) return;
                        setDragging(true)
                        dragStart(e);
                    }}
                    onMouseUp={(e) => {
                        if (!isEditing) return;
                        setDragging(false)
                        dragEnd(e);
                    }}
                />}
                <img src="/poleBackground.png" alt="pole background" style={{ width: "100%", height: "100%" }} />
            </Block>
            <Block display="flex" justifyContent="center" paddingRight='6rem'>
                <p>Line Direction:</p>
                <ArrowUp size={50} />
            </Block>
        </Cell>
    )
};

export default connect(state => {
    const section = state.pole.damageParameters[state.ui.currentLevel];
    const currentDamageValue = section.damages[state.ui.currentDamageIndex];

    let isValid = true;
    if (section && currentDamageValue && currentDamageValue.type) {
        const validation = validateDamage(currentDamageValue, section);
        isValid = validation.isValid;
    }

    return {
        damageParameters: state.pole.damageParameters,
        section,
        isValid,
        showPreview: state.pole.damageParameters[state.ui.currentLevel].diameter >= 1,
        isEditing: state.ui.currentlyEditingAngle,
    }
}, {
    setDamageAngle,
    setCurrentlyEditingAngle
})(DamagePreview);
