import firebase from 'firebase';
import { updateLicenseAndSetLocal } from '../components/account/DeviceManager';
import SyncClientDB from '../components/database/SyncClientDB';
import UserApi from '../firebase/UserApi';
import { openModal, setLoading, setLoadingUserData, setSyncing } from './ui';

export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_ORG_DATA = 'SET_ORG_DATA';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const SET_CLAIMS = 'SET_CLAIMS';
export const SET_PARAMS_POLE_DETAILS = 'SET_PARAMS_POLE_DETAILS';
export const SET_RETURN_URL = 'SET_RETURN_URL';

export const setDetails = (details) => ({
    type: SET_USER_DETAILS,
    details
});

export const setPoleDetails = (poleParamsDetails) => ({
    type: SET_PARAMS_POLE_DETAILS,
    poleParamsDetails,
});
export const setReturnURLDetail = (returnUrl) => ({
    type: SET_RETURN_URL,
    returnUrl,
});
export const setUserData = (userData) => ({
    type: SET_USER_DATA,
    userData
});

export const setOrgData = (orgData) => ({
    type: SET_ORG_DATA,
    orgData
});

export const loadUserData = () => {
    return async (dispatch, getState) => {
        let claims = getState().user.claims;
        let userId = firebase.auth().currentUser.uid;

        async function refreshUserData(state) {
            let userData = state || {};

            try {
                let networkedUserData = await UserApi.getData(firebase.auth().currentUser.uid)
                await SyncClientDB.putSetting('userData', {
                    ...networkedUserData,
                    userId,
                })
                userData = networkedUserData;
            } catch (e) {
                console.error(e);
            }

            dispatch(setUserData(userData));
            return userData;
        }

        const CURRENT_USER_ID_KEY = 'currentUserId';
        let currentUserId = await SyncClientDB.getSetting(CURRENT_USER_ID_KEY);
        console.log('currentUserDexie')
        console.log(currentUserId)

        let userData = undefined;
        if (!currentUserId) {
            await SyncClientDB.putSetting(CURRENT_USER_ID_KEY, userId)
        } else if (currentUserId === userId) {
            userData = await SyncClientDB.getSetting('userData');
            if (userData) {
                dispatch(setUserData(userData));
                dispatch(setLoadingUserData(false));
            }
        } else if (currentUserId !== userId) {
            await SyncClientDB.clearUser();
        }

        if (userData) {
            userData = refreshUserData(userData); //Get new data if possible
        } else {
            userData = await refreshUserData(); //Have to wait for data
        }

        if (claims.lId) {
            //TODO error handle
            await deviceCheck(claims, dispatch);
        }

        dispatch(setLoadingUserData(false));
        await startSyncing(dispatch, userData, userId);
    };
}

export const setUserDetails = (details) => {
    return (dispatch) => {
        dispatch(setDetails({ user: details, loggedIn: !!details }));
        dispatch(setLoading(false));
    }
}

export const setClaims = (claims) => ({
    type: SET_CLAIMS,
    claims
});

async function deviceCheck(claims, dispatch) {
    let deviceId = await getThisDeviceId();
    if (!deviceId) {
        //TODO need to have a toaster somewhere here.


        await startDeviceRegistrationProcess(claims, dispatch);
    } else {
        let deviceRegistered = checkDeviceIdAgainstLicense(claims, deviceId);
        if (!deviceRegistered) {
            await startDeviceRegistrationProcess(claims, dispatch);
        }
    }
}

function checkDeviceIdAgainstLicense(claims, deviceId) {
    return claims.d1 === deviceId || claims.d2 === deviceId;
}

async function bootDeviceOut(dispatch, getState, data) {
    let claims = getState().user.claims;
    await updateLicenseAndSetLocal(claims, data.slot)
}

async function startDeviceRegistrationProcess(claims, dispatch) {
    if (!claims.d1) {
        await updateLicenseAndSetLocal(claims, 'device1');
    } else if (!claims.d2) {
        await updateLicenseAndSetLocal(claims, 'device2');
    } else {
        dispatch(openModal('tooManyDevices', (dispatch, getState, data) => bootDeviceOut(dispatch, getState, data)))
    }
}

async function getThisDeviceId() {
    let idb = SyncClientDB.getIDB();
    let keys = await idb.settingTable.where('settingKey').equals('deviceId').toArray();
    if (keys.length) {
        return keys[0].uuid;
    }
    return undefined;
}

export async function startSyncing(dispatch, userData, userId) {
    let shouldSync = await getShouldSyncFromIDB(dispatch);
    dispatch(setSyncing(shouldSync)); //Set sync state in UI

    try {
        userData = await Promise.resolve(userData)

        if (userData.orgId) {
            await SyncClientDB.setShouldSync(shouldSync, userData.orgId); //Actually turn on syncing
        }
    } catch (err) {
        console.error(err);
    }
}
export async function getShouldSyncFromIDB() {
    let shouldSync = true;
    try {
        let idbResult = await SyncClientDB.getSetting('shouldSync');
        if (idbResult === undefined) {
            await SyncClientDB.setShouldSync(true);
        } else {
            shouldSync = idbResult;
        }
    } catch (e) {
        console.log('Error getting sync', e);
    }
    return shouldSync;
}