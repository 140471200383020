import { PLACEMENT, toaster, ToasterContainer } from "baseui/toast";
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { CenteredSpinner } from '../components/transition/CenteredSpinner';
import UserApi from '../firebase/UserApi';
import { ToasterContainerOverride } from "../theming";


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const RegisterPage = () => {
    const query = useQuery();
    const history = useHistory();


    async function registerAndRedirect() {
        try {
            await UserApi.registerLicense(query.get('licenseId'), query.get('orgId'))
            history.push('/account')
        } catch (e) {
            console.error(e)
            if (e.message === 'User is not logged in') {
                history.push('/signup?' + query.toString())
            } else {
                history.push('/account')
                toaster.negative(e.message)
            }
        }
    }

    React.useEffect(() => {
        registerAndRedirect();
    }, [])
    return <div>
        <CenteredSpinner />
        <ToasterContainer overrides={ToasterContainerOverride} placement={PLACEMENT.bottomLeft}></ToasterContainer>
    </div>
}

export default RegisterPage;