import firebase from "firebase";
import firebaseFetch from "../utils/firebaseFetch";
import Firebase from "./Firebase";

class UserApi {
  async createOrganization(name, numLicenses, tin, adminEmail, isTrial) {
    try {
      const res = await Firebase.fetch("/organizations/new", {
        method: "POST",
        body: { name, numLicenses, tin, adminEmail, isTrial },
      });
      return res.url;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async search(keyword) {
    const res = await Firebase.fetch(
      "/users/search?keyword=" + encodeURIComponent(keyword),
      {
        method: "GET",
      }
    );
    return res;
  }

  async put(data) {
    try {
      const { uid } = firebase.auth().currentUser;
      await firebaseFetch(`/user/${uid}`, {
        method: "PUT",
        body: data,
      });
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async new(data) {
    try {
      let userObject = { ...data };
      let fbUser = await firebase
        .auth()
        .createUserWithEmailAndPassword(data.email, data.password);
      delete userObject.password;
      delete userObject.email;

      userObject["acceptedPrivacyPolicy"] = false;
      userObject["acceptedTOS"] = false;
      userObject["acceptedSourceCodeLicense"] = false;

      await firebase
        .firestore()
        .collection("users")
        .doc(fbUser.user.uid)
        .set(userObject);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async getMyLicense(lId, orgId) {
    try {
      let license = await firebase
        .firestore()
        .collection("organizations")
        .doc(orgId)
        .collection("licenses")
        .doc(lId)
        .get();
      if (!license.exists) {
        return Promise.reject("License Doesn't exist!");
      }
      return license.data();
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async getLicenses(orgId) {
    try {
      let licenses = [];
      await (
        await firebase
          .firestore()
          .collection("organizations")
          .doc(orgId)
          .collection("licenses")
          .get()
      ).forEach((e) => licenses.push({ ...e.data(), id: e.ref.id }));
      console.log(licenses);
      return licenses;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async createLicense() {
    try {
      return await Firebase.fetch("/organizations/createLicense", {
        method: "POST",
        body: {},
      });
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async registerLicense(code, orgCode) {
    try {
      await Firebase.fetch("/users/registerLicense", {
        method: "POST",
        body: {
          licenseId: code,
          orgId: orgCode,
        },
      });
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async updateLicense(licenseId, whichDevice, uuid, deviceModel) {
    return await Firebase.fetch("/users/license/" + licenseId, {
      method: "POST",
      body: {
        [whichDevice]: {
          uuid,
          deviceModel,
        },
      },
    });
  }

  async getClaims() {
    return firebase
      .auth()
      .currentUser.getIdTokenResult(true)
      .then((idTokenResult) => {
        this.currentUserClaims = idTokenResult.claims;
        return idTokenResult.claims;
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  }

  async getOrganizationDetails(orgId) {
    return (
      (
        await firebase.firestore().collection("organizations").doc(orgId).get()
      ).data() || {}
    );
  }

  async getData(userId) {
    return (
      (
        await firebase.firestore().collection("users").doc(userId).get()
      ).data() || {}
    );
  }

  async acceptLicense(type) {
    try {
      await Firebase.fetch("/users/" + firebase.auth().currentUser.uid, {
        method: "PUT",
        body: {
          [type]: true,
        },
      });
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async claimSnapshotListener(authUser, useClaims) {
    return firebase
      .firestore()
      .collection("user-claims")
      .doc(authUser.uid)
      .onSnapshot(async (document) => {
        const claims = await this.getClaims();
        this.currentUserClaims = claims;
        let impersonate;
        try {
          if (claims.impersonating) {
            impersonate = await this.getUserDetails(claims.impersonating);
          }
          useClaims(claims, impersonate);
        } catch (err) {
          console.error("Something went wrong", err);
          useClaims({}, undefined);
        }
      });
  }

  async getUserClaims(userId) {
    return await firebase
      .firestore()
      .collection("user-claims")
      .doc(userId)
      .get();
  }

  async getAdmins() {
    let admins = await Firebase.fetch("/users/admins", {
      method: "GET",
    });

    return admins;
  }

  async removeAdmin(uid) {
    return await Firebase.fetch("/users/setAdminStatus", {
      method: "POST",
      body: {
        susr: false,
        uid,
      },
    });
  }

  async makeAdmin(uid) {
    return await Firebase.fetch("/users/setAdminStatus", {
      method: "POST",
      body: {
        susr: true,
        uid,
      },
    });
  }

  async searchOrgs(keyword) {
    const res = await Firebase.fetch(
      "/organizations/search?keyword=" + encodeURIComponent(keyword),
      {
        method: "GET",
      }
    );
    return res;
  }

  async updateOrg(orgId, TIN, licenses, apiCallCount, testApiCallCount) {
    const res = await Firebase.fetch("/organizations/update", {
      method: "POST",
      body: {
        orgId,
        TIN,
        licenses,
        apiCalls: apiCallCount,
        testApiCalls: testApiCallCount,
      },
    });

    return res;
  }
}

export default new UserApi();