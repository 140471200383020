import React from 'react';
import { connect } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import BillingAPI from '../../firebase/BillingAPI';

export const PaymentSuccessPage = ({ }) => {
    const location = useLocation();

    let queryParams = new URLSearchParams(location.search)

    React.useState(() => {
        BillingAPI.validatePurchase(queryParams.get('session_id'))
    }, [])

    return <Redirect to="/app/account" />
}

export default connect(state => ({}), {})(PaymentSuccessPage);