import { createTheme } from "baseui";

const overrides = {
  typography: {
    DisplaySmall: {
      fontFamily: "Stereo Gothic",
      fontSize: "28px"
    },
  },
};

export default createTheme({}, overrides);
