import { csvHeaders, Pole } from '@edm-internationa/d-calc-calculations';
import { Button, SIZE } from 'baseui/button';
import { Input } from 'baseui/input';
import {
    Modal, ModalBody, ModalButton, ModalFooter, ModalHeader
} from 'baseui/modal';
import { Notification } from 'baseui/notification';
import { Pagination } from "baseui/pagination";
import {
    StyledHeadCell,
    StyledTable
} from 'baseui/table-grid';
import { PLACEMENT, toaster, ToasterContainer } from "baseui/toast";
import { saveAs } from 'file-saver';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setProjectId } from '../actions/pole';
import { addPole, deletePole, setCurrentPoleWithoutSaving } from '../actions/poles';
import { PrintLocal } from '../actions/print';
import { deleteProject } from '../actions/projects';
import { ModalOverride, ToasterContainerOverride } from '../theming';
import { DB_PAGE_STYLING } from './Assess/styles';
import AddProjectModal from './database/AddProjectModal';
import Project from './database/Project';
import SyncClientDB from './database/SyncClientDB';
import { Page } from "./layout/Page";
import { ROUTE_MODEL } from './navigation/Sidebar';
import { CenteredSpinner } from './transition/CenteredSpinner';

import { useLiveQuery } from "dexie-react-hooks";


function buildRow(project) {
    return [
        project.name,
        project.numPoles,
        project.owner,
        project.startDate,
        project.poles || [],
        project.id
    ];
}


export const Database = ({ setCurrentPoleWithoutSaving, orgId }) => {
    const [isAddOpen, setIsAddOpen] = React.useState(false);
    const history = useHistory();
    const [isOpen, setIsOpen] = React.useState(false);
    const [currentProject, setCurrentProject] = React.useState(undefined)
    const [projectToDelete, setProjectToDelete] = React.useState(undefined);
    const [poleToDelete, setPoleToDelete] = React.useState(undefined);
    const [loadingData, setLoadingData] = React.useState(true);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [exportLoading, setExportLoading] = React.useState(false);
    const [printLoading, setPrintLoading] = React.useState(false);
    const [searchString, setSearchString] = React.useState(false);
    const [syncEnabled, setSyncEnabled] = React.useState(false)
    const idb = SyncClientDB.getIDB();


    const projects = useLiveQuery(async () => {
        let allData = [];
        if (searchString) {
            let exp = new RegExp(searchString, 'i');
            let allPoles = await idb.poles.filter(pole => {
                return exp.test(pole.info?.name) && pole.deleted !== '1'
            }).toArray();

            let keyMap = {};
            for (let pole of allPoles) {
                keyMap[pole.projectId] = true;
            }

            allData = await idb.projects.filter(project => {
                return exp.test(project.name) || keyMap[project.id] && project.deleted !== '1';
            }).toArray();
        } else {
            allData = await idb.projects.filter((project) => project.deleted !== '1').toArray();
        }

        let poles = await getPolesFromIDB(searchString);
        for (let project of allData) {
            for (let pole of poles) {
                if (pole.projectId === project.id) {
                    if (project.poles === undefined) {
                        project.poles = [];
                    }
                    project.poles.push(pole)
                }
            }
            project.numPoles = project.poles ? project.poles.length : 0;
        }

        if (currentPage >= Math.ceil(allData.length / 10)) {
            setCurrentPage(0)
        }
        
        return allData;
      }, [searchString]);


    React.useEffect(() => {
        getData();
        SyncClientDB.getShouldSync().then((shouldSync) => setSyncEnabled(shouldSync));
    }, [])

    function getData() {
        setLoadingData(false)
    }

    function close() {
        setIsOpen(false);
        setProjectToDelete(undefined);
        setPoleToDelete(undefined)
    }

    async function confirm() {
        setIsOpen(false);
        await deleteProject(projectToDelete);
        getData(searchString);
        setProjectToDelete(undefined);
    }

    async function confirmDeletePole() {
        setIsOpen(false);
        let result = await deletePole(poleToDelete, projectToDelete);
        getData(searchString);
        setProjectToDelete(undefined);
        setPoleToDelete(undefined)
    }

    async function exportAll() {
        setExportLoading(true)

        let poles = await getPolesFromIDB(searchString);

        let rows = [csvHeaders];
        for (let pole of poles) {
            try {
                let poleToExport = new Pole(pole);
                rows.push(poleToExport.getCSVRowData());
            } catch (e) {
                console.error(e)
            }
        }

        try {
            let csvContent = rows.map(e => e.join(",")).join("\n");
            saveAs(new Blob([csvContent], {
                type: 'text/csv',
            }), 'AllPoles' + new Date().toString() + '.csv');
        } catch (e) {
            console.error(e);
        }

        setExportLoading(false)
    }

    async function getPolesFromIDB(searchString) {
        let poles = [];
        if (searchString) {
            let exp = new RegExp(searchString, 'i');
            let allProjects = await idb.projects.filter(project => {
                return exp.test(project.name) && project.deleted !== '1';
            }).toArray();

            let keyMap = {};
            for (let project of allProjects) {
                keyMap[project.id] = true;
            }

            poles = await idb.poles.filter(pole => {
                return exp.test(pole.info?.name) || keyMap[pole.projectId] && pole.deleted !== '1';
            }).toArray();
        } else {
            poles = await idb.poles.filter(pole => pole.deleted != '1').toArray();
        }
        return poles;
    }

    async function printAll() {
        let poles = await getPolesFromIDB(searchString);

        for (let poleId in poles) {
            let projectName = '';
            let pole = poles[poleId]
            if (pole.projectId) {
                let idb = SyncClientDB.getIDB();
                let projectId = pole.projectId

                let project = await idb.projects.get(projectId);
                if (project && project.name) {
                    projectName = project.name;
                }
            }
            if (!poles[poleId]?.info) {
                poles[poleId].info = {}
            }
            poles[poleId].info.projectId = projectName
        }

        try {
            PrintLocal(poles);
        } catch (e) {
            console.error(e)
            toaster.negative('Something went wrong, please try again later.', 5)
        }
    }

    function forceSync() {
        if (navigator.onLine) {
            toaster.positive('Syncing Started.')
            SyncClientDB.forceSync(orgId)    
        }
        else {
            toaster.negative('Please reconnect to the internet to use this feature.')
        }
    }

    if (loadingData) {
        return <div style={{ height: '100%' }}>
            <CenteredSpinner />
        </div>
    }

    return (
        <Page
            title='Database'
            buttons={
                <>
                    <Input size='compact' placeholder='search' onChange={(e) => {
                        getData(e.target.value);
                        setSearchString(e.target.value);
                    }} />
                    <AddProjectModal
                        isAddOpen={isAddOpen}
                        setIsAddOpen={(isOpen) => {
                            setCurrentProject(undefined)
                            setIsAddOpen(isOpen)
                        } }
                        data={currentProject}
                        getData={() => getData(searchString)}
                    />
                    <Button size={SIZE.mini} onClick={(e) => exportAll(e)} isLoading={exportLoading}>Export All</Button>
                    <Button size={SIZE.mini} onClick={(e) => printAll(e)} isLoading={printLoading}>Print All</Button>
                    <Button size="compact" onClick={() => forceSync()} disabled={!syncEnabled}>
                        Sync
                    </Button>
                </>
            }>
            <div style={DB_PAGE_STYLING}>
                <ToasterContainer autoHideDuration={3000} overrides={ToasterContainerOverride} placement={PLACEMENT.bottomLeft}></ToasterContainer>
                <Modal overrides={ModalOverride} onClose={close} isOpen={isOpen} unstable_ModalBackdropScroll={true}>
                    <ModalHeader>Warning!</ModalHeader>
                    <ModalBody>
                        Are you sure you would to delete this {poleToDelete ? 'pole and all associated data?' : 'project with the associated pole(s)?'}
                    </ModalBody>
                    <ModalFooter>
                        <ModalButton kind="tertiary" onClick={close}>
                            Cancel
                        </ModalButton>
                        <ModalButton onClick={() => {
                            if (poleToDelete) {
                                confirmDeletePole()
                            } else {
                                confirm()
                            }
                        }}>Confirm</ModalButton>
                    </ModalFooter>
                </Modal>
                <StyledTable role="grid" $gridTemplateColumns="minmax(100px, 300px) 156px 165px 146px minmax(0px, 500px)">
                    <StyledHeadCell>Project Name</StyledHeadCell>
                    <StyledHeadCell>Structures</StyledHeadCell>
                    <StyledHeadCell>Owner</StyledHeadCell>
                    <StyledHeadCell>Start Date</StyledHeadCell>
                    <StyledHeadCell>Actions</StyledHeadCell>
                    <>
                        {projects && projects.length ? projects.slice(currentPage * 10, currentPage * 10 + 10).map((row, index) => {
                            return <Project
                                searchString={searchString}
                                row={buildRow(row)}
                                index={index}
                                deleteProject={() => {
                                    setProjectToDelete(row.id)
                                    setIsOpen(true);
                                }}
                                editProject={() => {
                                    setCurrentProject(Object.assign({}, projects[currentPage * 10 + index]))
                                    setIsAddOpen(true);
                                }}
                                deletePole={(id, projectId) => {
                                    setIsOpen(true);
                                    setPoleToDelete(id);
                                    setProjectToDelete(projectId);
                                }}
                                addPole={() => {
                                    setCurrentPoleWithoutSaving(undefined, row.name, row.id)
                                    history.push(ROUTE_MODEL);
                                }}
                                key={'project-' + row.id}
                            />
                        }) : <Notification overrides={{ Body: { style: { marginTop: '15px', marginLeft: 'auto', marginRight: 'auto', marginBottom: '5px', borderRadius: 0 } } }}>No results found</Notification>}
                        {projects && projects.length ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '100px', width: "290%", marginBottom: '5px' }}>
                                <Pagination
                                    numPages={Math.ceil(projects.length / 10)}
                                    currentPage={currentPage + 1}
                                    onPageChange={({ nextPage }) => {
                                        setCurrentPage(
                                            Math.min(Math.max(nextPage, 1), Math.ceil(Object.values(projects).length / 10)) - 1
                                        );
                                    }}
                                />
                            </div> : undefined}
                    </>

                </StyledTable>
            </div>
        </Page>
    );
}

export default connect(state => ({
    orgId: state.user.claims.orgId
}), {
    setProjectId,
    addPole,
    setCurrentPoleWithoutSaving,
})(Database);
