import bolt from './bolt.bmp';
import cavity from './cavity.bmp';
import heart from './heart.bmp';
import _logo from './logo.png';
import _trellisLogo from './trellisLogo.png';
import pocket from './pocket.bmp';
import selectHeight from './pole.png';
import woodCrossSection from './poleBackground.png';
import _referenceChart from './referenceChart.png';
import section from './section.bmp';
import shell from './shell.bmp';
import slice from './slice.bmp';
import wedge from './wedge.bmp';
import woodGrainBackground from './woodbackground2.jpg';

export const Heart = heart;
export const Shell = shell;
export const Pocket = pocket;
export const Wedge = wedge;
export const Section = section;
export const Slice = slice;
export const Cavity = cavity;
export const Bolt = bolt;
export const SelectHeight = selectHeight;
export const referenceChart = _referenceChart;
export const logo = _logo;
export const trellisLogo = _trellisLogo;
export const poleBackground = woodCrossSection;
export const woodBackground = woodGrainBackground;