import UAParser from "ua-parser-js";
import { v4 } from "uuid";
import UserApi from "../../firebase/UserApi";
import SyncClientDB from '../database/SyncClientDB';

function getCurrentDevice() {
    let ua = new UAParser(window.navigator.userAgent);
    let cModel =
        ua.getDevice().model != undefined
            ? ua.getDevice().model
            : ua.getResult().os.name + " " + ua.getResult().os.version;
    let cType = ua.getDevice().type != undefined ? ua.getDevice().model : "PC";
    let cVendor =
        ua.getDevice().vendor != undefined
            ? ua.getDevice().vendor
            : "Unknown Vendor";
    return { cModel, cType, cVendor };
}

export async function updateLicenseAndSetLocal(claims, slot) {
    let idb = SyncClientDB.getIDB();
    let uuid = v4();
    let { cModel, cType, cVendor } = getCurrentDevice();
    await UserApi.updateLicense(claims.lId, slot, uuid, cModel);
    await idb.settingTable.put({ settingKey: "deviceId", uuid, model: cModel, type: cType, vendor: cVendor });
}