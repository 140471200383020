import firebase from 'firebase';
import SyncClientDB from '../components/database/SyncClientDB';
export const ADD_PROJECT = 'ADD_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const SET_ALL_PROJECTS = 'SET_ALL_PROJECTS';
const { v4: uuidv4 } = require('uuid');

export const addProject = async (project) => {
    let idb = SyncClientDB.getIDB();
    let timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    let uuid = uuidv4();
    project = { ...project, id: uuid, updatedTimestamp: timestamp, createdTimestamp: timestamp, deleted: '0', dirty: '1' };
    await (idb).projects.add(project);
}

export const deleteProject = async (projectId) => {
    let idb = SyncClientDB.getIDB();
    await (idb).transaction('rw', idb.poles, idb.projects, async () => {
        let Timestamp = new Date();
        await (idb).projects.where("id").equals(projectId).modify({
            deleted: '1',
            deletedTimestamp: Timestamp,
            updatedTimestamp: Timestamp,
        });

        await (idb).poles.where("projectId").equals(projectId).modify({
            deleted: '1',
            deletedTimestamp: Timestamp,
            updatedTimestamp: Timestamp,
        });
        console.log('Deleting project!')
    });
}

export async function getAllProjectsFromIDB() {
    let idb = SyncClientDB.getIDB();
    let projects = await idb.projects.toArray();
    let pMap = {};
    projects.forEach((project) => {
        pMap[project.id] = project;
    });
    return pMap;
}

export const updateProject = (project) => {
    return async (dispatch) => {
        let idb = SyncClientDB.getIDB();
        let timestamp = firebase.firestore.Timestamp.fromDate(new Date());
        project = { ...project, updatedTimestamp: timestamp };

        dispatch({
            type: UPDATE_PROJECT,
            project
        });
        await (idb).projects.put(project);
    };
}

export const setAllProjects = (projects) => ({
    type: SET_ALL_PROJECTS,
    projects,
});