import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from "baseui/modal";
import { Radio, RadioGroup } from "baseui/radio";
import { StyledSpinnerNext } from "baseui/spinner";
import { toaster } from "baseui/toast";
import { Paragraph3 } from "baseui/typography";
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { closeModal } from "../../actions/ui";
import UserApi from "../../firebase/UserApi";
import { ModalOverride } from "../../theming";
import { ROUTE_LOGOUT } from "../navigation/Sidebar";

export const TooManyDevicesModal = ({ modal, closeModal, claims }) => {
    const [device, setDevice] = React.useState('device1');
    const [licenses, setLicenses] = React.useState([]);
    const [licenseNotFound, setLicenseNotFound] = React.useState(false);
    const history = useHistory();


    React.useState(() => {
        UserApi.getMyLicense(claims.lId, claims.orgId)
            .then(resp => setLicenses([resp.device1Name, resp.device2Name])).catch(e => {
                if (e?.message !== "Failed to get document because the client is offline.") {
                    toaster.negative(e?.message)
                    setLicenseNotFound(true)
                }
            });
    }, [])

    const makeLicenseNotFoundDialog = () => {
        return <>
            <Paragraph3>Your license was not found! It may be invalid. Please contact your administrator.</Paragraph3>
        </>
    }


    return <Modal overrides={ModalOverride} onClose={() => closeModal()} isOpen={!!modal && modal.type === 'tooManyDevices'} unstable_ModalBackdropScroll closeable={false}>
        <ModalHeader>Too many devices!</ModalHeader>
        <ModalBody>
            {!licenses.length ? !licenseNotFound ? makeLicenseNotFoundDialog() : <StyledSpinnerNext /> :
                <>
                    <Paragraph3>You have too many active devices. Choose a slot to replace with this device, or log out.</Paragraph3>
                    <RadioGroup onChange={(e) => setDevice(e.target.value)} value={device}>
                        <Radio value={'device1'} description={licenses.length >= 1 ? licenses[0] : ''}>Device 1</Radio>
                        <Radio value={'device2'} description={licenses.length >= 2 ? licenses[1] : ''}>Device 2</Radio>
                    </RadioGroup>
                </>
            }
        </ModalBody>
        <ModalFooter>
            <ModalButton kind="tertiary" onClick={() => history.push(ROUTE_LOGOUT)}>
                Logout
            </ModalButton>
            <ModalButton onClick={() => closeModal({ slot: device })}>Confirm</ModalButton>
        </ModalFooter>
    </Modal>
}

export const ConnectedTooManyDevicesModal = connect(state => ({
    modal: state.ui.modal,
    claims: state.user.claims,
}), {
    closeModal,
})(TooManyDevicesModal);
