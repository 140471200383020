import { poleSchema } from '@edm-internationa/d-calc-calculations';
import { Block } from 'baseui/block';
import { Button, KIND, SIZE } from "baseui/button";
import { StatefulPopover } from 'baseui/popover';
import saveAs from 'file-saver';
import { connect } from "react-redux";
import { calculatePole } from '../../actions/pole';
import { calculatePoleValues } from '../../actions/poles';
import { savePoleTrigger } from '../../actions/ui';
import useIsMobile from '../../utils/useIsMobile';

const DownloadButton = ({ file, fileName, children, onClick }) => {

  return <Button kind={KIND.tertiary} onClick={() => {
    saveAs(file, fileName);
    if (onClick) onClick();
  }}>
    {children}
  </Button>
};

const Export = ({ pole, calculatePole, savePoleTrigger }) => {
  const isMobile = useIsMobile();

  return (
    <>
      <StatefulPopover
        placement='bottom'
        onOpen={() => calculatePole}
        content={() =>
          <Block display="flex" flexDirection="column" padding='20px'>
            <Button kind={KIND.tertiary} onClick={() => {
              savePoleTrigger(() => {
                pole.output = calculatePoleValues(pole);
                saveAs(pole.exportCSV(), `${pole.info.name}.csv`);
              })
            }}>
              CSV
            </Button>
            <Button kind={KIND.tertiary} onClick={() => {
              savePoleTrigger(() => {
                pole.output = calculatePoleValues(pole);
                saveAs(pole.exportJson(), `${pole.info.name}.json`);
              })
            }}>
              JSON
            </Button>
          </Block>
        }
      >
        <Button kind="secondary" size={isMobile ? SIZE.mini : SIZE.default}>
          Export
        </Button>
      </StatefulPopover>
    </>
  );
}

export const DownloadSchemaButton = ({ type, children, onClick }) => {
  if (type === 'json') return (
    <DownloadButton
      file={new Blob([`data:text/json;charset=utf-8,${JSON.stringify(poleSchema, null, 2)}`], {
        type: 'application/json',
      })}
      fileName='D-Calc Pole Import Template.json'
      onClick={onClick}
    >
      {children}
    </DownloadButton>
  )
  if (type === 'csv') return null;
}

export const ExportButton = connect(({ pole }) => ({ pole }), { calculatePole, savePoleTrigger })(Export);
