import { combineReducers } from 'redux';
import { firebase } from './firebase';
import { poleReducer as pole } from './pole';
import { projects } from './projects';
import { ui } from './ui';
import { user } from './user';


export const DCalcReducers = combineReducers({
    user,
    ui,
    firebase,
    projects,
    pole,
});