import { Block } from 'baseui/block';
import { Input } from 'baseui/input';
import { Paragraph2 } from 'baseui/typography';
import React from 'react';

const props = {
    size: "compact",
    type: "number",
    name: "diameter",
    overrides: {
        Root: {
            style: {
                height: "38px"
            }
        },
        Input: {
            style: {
                paddingLeft: 5,
                paddingRight: 5
            }
        },
        StartEnhancer: {
            style: {
                paddingLeft: "5px",
                paddingRight: "5px"
            }
        }
    }
}

export default ({ diameter, handleChange }) => {
    const round = value => {
        if (isNaN(value) || value === '') {
            return value;
        } else {
            return Math.round((value) * 100) / 100
        }
    }
    const [diameterInput, setDiameterInput] = React.useState(!isNaN(diameter) ? diameter : '');
    const [circumferenceInput, setCircumferenceInput] = React.useState(!isNaN(diameter) ? parseFloat(diameter) * Math.PI : 0);

    React.useEffect(() => {
        handleChange({ diameter: +diameterInput });
    }, [diameter])

    const setCircumference = () => {
        handleChange({ diameter: +circumferenceInput / Math.PI });
    }

    const setDiameter = () => {
        handleChange({ diameter: +diameterInput });
    }

    return (
        <Block display="flex" alignItems="center">
            <Input
                value={round(circumferenceInput)}
                onFocus={() => {
                    if (!circumferenceInput) {
                        setCircumferenceInput('');
                    }
                }}
                onChange={e => {
                    setCircumferenceInput(e.target.value);
                    setDiameterInput(e.target.value / Math.PI);
                }}
                onBlur={() => setCircumference()}
                pattern={'[0-9]*'} inputMode='decimal'
                {...{ ...props }}
                endEnhancer='in.'
            />
            <Paragraph2 marginLeft="10px" marginRight="10px">OR</Paragraph2>
            <Input
                value={round(diameterInput)}
                onFocus={() => {
                    if (!diameterInput) {
                        setDiameterInput('');
                    }
                }}
                onBlur={() => setDiameter()}
                onChange={(e) => {
                    setDiameterInput(e.target.value);
                    setCircumferenceInput(e.target.value * Math.PI);
                }}
                pattern={'[0-9]*'} inputMode='decimal'
                {...{ ...props }}
                endEnhancer='in.'
            />
        </Block>
    );
}
