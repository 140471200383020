import fs from 'fs';
import PDFDocument from 'pdfkit';
import Helvetica from 'pdfkit/js/data/Helvetica.afm';

function registerBinaryFiles(ctx) {
    ctx.keys().forEach(key => {
        // extracts "./" from beginning of the key
        fs.writeFileSync(key.substring(2), ctx(key))
    });  
}

registerBinaryFiles(require.context('../assets/printImages', true))
registerBinaryFiles(require.context('../assets/fonts', true))

const blobStream = require('blob-stream');
const SVGtoPDF = require('svg-to-pdfkit')
const { AnalyzePole } = require('@edm-internationa/d-calc-calculations');
const layoutFormat = require('./layoutFormat.json')

  
class PolePrinter {
    constructor() {
        fs.writeFileSync('data/Helvetica.afm', Helvetica)
        this.doc = new PDFDocument();
        this.doc.registerFont('Roboto-Regular', 'Roboto-Regular.ttf')
        this.doc.registerFont('Roboto-Bold', 'Roboto-Bold.ttf')
        this.doc.registerFont('Aldrich-Regular', 'Aldrich-Regular.ttf')
        this.doc.registerFont('Roboto-Light', 'Roboto-Light.ttf')

        // Aldrich-Regular

        this.doc.page.margins.bottom = 0;
        this.doc.page.margins.top = 0;
        this.doc.page.margins.right = 0;

        this.stream = this.doc.pipe(blobStream());
    }

    setVariables(pole, levelNumber) {
        this.pole = pole;
        this.levelNumber = levelNumber;
        this.layout = layoutFormat.layout;
        this.firstDamageCount = 0;
        this.secondDamageCount = 0;
        this.thirdDamageCount = 0;
        this.fourthDamageCount = 0;
        this.damagesCountArr = new Array(16).fill(false);
        this.actualCount = 0;
    }

    buildConversionMap() {
        let cm = {
            'level_n': this.levelNumber,
            'pole_number': this.pole.info.name,
            'project_description': this.pole.info.projectId ? this.pole.info.projectId : '',
            'species_type': this.pole.info.ansiSpecies.name ? this.pole.info.ansiSpecies.name : '',
            'class_number': this.pole.info.ansiClass ? this.pole.info.ansiClass : '',
            'length': this.pole.info.ansiLength ? this.pole.info.ansiLength : '',
            'fiber_strength': this.pole.info.fiberStrength ? this.pole.info.fiberStrength : '',
            'taper': this.pole.info.ansiSpecies && this.pole.info.ansiClass && this.pole.info.ansiLength ? this.pole.info.isLinearTaper ? 'Linear' : 'ANSI' : 'N/A',
            'project_date': this.pole.info.date,
            'level_number': this.levelNumber,
            'ansi': '',
            'embedment': this.pole.info.userEmbedment ? this.pole.info.userEmbedment : this.pole.info.ansiEmbedment ? this.pole.info.ansiEmbedment : 'N/A',
            'ansi_diameter': this.pole.output[this.levelNumber]['ansiDiameter'] ? this.pole.output[this.levelNumber]['ansiDiameter'].toFixed(2) : 'N/A',
            'circumference': '',
            'distance': '',
            'diameter': this.pole.info.ansiDiameter,
            'damage_1_name': '',
            'damage_1_1': '',
            'damage_1_1_val': '',
            'damage_1_2': '',
            'damage_1_2_val': '',
            'damage_1_3': '',
            'damage_1_3_val': '',
            'damage_1_4': '',
            'damage_1_4_val': '',
            'damage_1_5': '',
            'damage_1_5_val': '',
            'damage_2_name': '',
            'damage_2_1': '',
            'damage_2_1_val': '',
            'damage_2_2': '',
            'damage_2_2_val': '',
            'damage_2_3': '',
            'damage_2_3_val': '',
            'damage_2_4': '',
            'damage_2_4_val': '',
            'damage_2_5': '',
            'damage_2_5_val': '',
            'damage_3_name': '',
            'damage_3_1': '',
            'damage_3_1_val': '',
            'damage_3_2': '',
            'damage_3_2_val': '',
            'damage_3_3': '',
            'damage_3_3_val': '',
            'damage_3_4': '',
            'damage_3_4_val': '',
            'damage_3_5': '',
            'damage_3_5_val': '',
            'damage_4_name': '',
            'damage_4_1': '',
            'damage_4_1_val': '',
            'damage_4_2': '',
            'damage_4_2_val': '',
            'damage_4_3': '',
            'damage_4_3_val': '',
            'damage_4_4': '',
            'damage_4_4_val': '',
            'damage_4_5': '',
            'damage_4_5_val': '',
            // Results for Level #1
            'selected_section': '',
            'remaining_section': '',
            'percent_remaining_section': '',
            'bending_strength': '',
            'ansi_bending_strength': '',
            'section_transverse': '',
            'remaining_section_transverse': '',
            'percent_remaining_section_t': '',
            'bending_strength_transverse': '',
            'ansi_bending_strength_transverse': '',
            //
            'selected_inertia': '',
            'remaining_inertia': '',
            'percent_remaining_inertia': '',
            'selected_intertia_transverse': '',
            'remaining_inertia_transverse': '',
            'percent_remaining_inertia_t': '',
            //
            'selected_area': '',
            'remaining_area': '',
            'percent_remaining_area': '',
            'selected_area_transverse': '',
            'remaining_area_transverse': '',
            'percent_remaining_area_t': '',
            // Ansi Results
            'ansi_section_modulus': '',
            'ansi_percent_remaining': '',
            'ansi_section_modulus_transverse': '',
            'ansi_percent_remaining_transverse': '',
            //
            'ansi_inertia': '',
            'ansi_inertia_percent_remaining': '',
            'ansi_intertia_transverse': '',
            'ansi_intertia_percent_transverse': '',
            //
            'ansi_area': '',
            'ansi_area_percent_remaining': '',
            'ansi_area_transverse': '',
            'ansi_area_percent_transverse': ''
        }
        return cm;

    }

    addPole(pole, levelNumber) {
        this.setVariables(pole, levelNumber);
        let cm = this.buildConversionMap();
        this.calculations(cm, levelNumber);
        this.fillDamages(cm);
        let indexOfDamages = 0;
        for (let layoutElement of layoutFormat) {
            if (layoutElement.type === 'TEXT') {
                let characters = layoutElement.characters;
                let matches = characters.match(/(.*)\${(.*)}(.*)/);
                if (matches) {
                    if (matches[3].includes(':')) {
                        if (!this.damagesCountArr[((matches[2][7] - 1) * 4) + (matches[2][9] - 1)]) {
                            indexOfDamages++;
                            continue;
                        }
                        indexOfDamages++;
                    }
                    characters = matches[1] + cm[matches[2]] + matches[3];
                }

                this.doc.fontSize(layoutElement.style.fontSize);
                this.doc.opacity(1).fillColor('black');
                if (layoutElement.style.fontFamily === 'Roboto') {
                    if (layoutElement.characterStyleOverrides.length > 0) {
                        let currentOverride = layoutElement.characterStyleOverrides[0]
                        let currentStartIndex = 0;
                        for (let index = 0; index < layoutElement.characterStyleOverrides.length; index++) {
                            if (layoutElement.characterStyleOverrides[index] === currentOverride && index !== layoutElement.characterStyleOverrides.length - 1) {
                                continue;
                            } else {
                                let charsToRender = characters.substring(currentStartIndex, index);
                                let path2Font = layoutElement.styleOverrideTable[currentOverride];
                                path2Font = path2Font || {}
                                path2Font = path2Font.fontPostScriptName ? path2Font.fontPostScriptName : path2Font.fontFamily;

                                if (!path2Font) path2Font = layoutElement.style.fontPostScriptName ? layoutElement.style.fontPostScriptName : layoutElement.style.fontFamily;

                                currentOverride = layoutElement.characterStyleOverrides[index];
                                currentStartIndex = index;
                                let translateMap = {
                                    'Roboto': 'Roboto-Regular',
                                    'Roboto-Bold': 'Roboto-Bold',
                                    'Roboto-Light': 'Roboto-Light'
                                }
                                console.log('Path 2 Font')
                                console.log(path2Font)
                                this.doc.font(translateMap[path2Font]).text(charsToRender, layoutElement.absoluteBoundingBox['x'], layoutElement.absoluteBoundingBox['y'], { continued: index !== layoutElement.characterStyleOverrides.length - 1 || index < characters.length - 1 });
                            }
                        }

                        if (layoutElement.characterStyleOverrides.length < characters.length) {
                            let charsToRender = characters.substring(layoutElement.characterStyleOverrides.length - 1, characters.length - 1);
                            let path2Font = layoutElement.styleOverrideTable[currentOverride];
                            path2Font = path2Font || {}
                            path2Font = path2Font.fontPostScriptName ? path2Font.fontPostScriptName : path2Font.fontFamily;

                            if (!path2Font) path2Font = layoutElement.style.fontPostScriptName ? layoutElement.style.fontPostScriptName : layoutElement.style.fontFamily;
                            let translateMap = {
                                'Roboto': 'Roboto-Regular',
                                'Roboto-Bold': 'Roboto-Bold',
                                'Roboto-Light': 'Roboto-Light'
                            }
                            console.log('path2Font 1')
                            console.log(path2Font)
                            this.doc.font(translateMap[path2Font]).text(charsToRender, layoutElement.absoluteBoundingBox['x'], layoutElement.absoluteBoundingBox['y'], { continued: false });
                        }
                    } else {
                        let path2Font = layoutElement.style.fontPostScriptName ? layoutElement.style.fontPostScriptName : layoutElement.style.fontFamily;
                        let translateMap = {
                            'Roboto': 'Roboto-Regular',
                            'Roboto-Bold': 'Roboto-Bold',
                            'Roboto-Light': 'Roboto-Light'
                        }
                        console.log('path2Font 3')
                        console.log(path2Font)

                        this.doc.font(translateMap[path2Font]).text(characters, layoutElement.absoluteBoundingBox['x'], layoutElement.absoluteBoundingBox['y']);
                    }
                } else {
                    console.log('path2Font 2')
                    console.log('path2Font not defined')
                    this.doc.font('Aldrich-Regular').text(characters, layoutElement.absoluteBoundingBox['x'], layoutElement.absoluteBoundingBox['y']);
                }
            } else if (layoutElement.type === 'RECTANGLE') {
                let color = 'black';
                let opacity = 1;
                if (layoutElement.fills) {
                    if (layoutElement.fills[0].type === 'IMAGE') {
                        this.doc.opacity(1);
                        this.doc.image(`${layoutElement.fills[0].imageRef}.png`, layoutElement.absoluteBoundingBox['x'], layoutElement.absoluteBoundingBox['y'], { fit: [layoutElement.absoluteBoundingBox['width'], layoutElement.absoluteBoundingBox['height']], align: 'center', valign: 'center' })
                    } else {
                        color = [
                            layoutElement.fills[0].color.r * 255,
                            layoutElement.fills[0].color.g * 255,
                            layoutElement.fills[0].color.b * 255]

                        opacity = layoutElement.fills[0].opacity;
                        this.doc.opacity(opacity).fill(color);
                        this.doc.rect(layoutElement.absoluteBoundingBox['x'], layoutElement.absoluteBoundingBox['y'], layoutElement.absoluteBoundingBox['width'], layoutElement.absoluteBoundingBox['height'])
                    }
                }
            }
        }

        let svgBoundingBox = { x: 36, y: 239 };
        SVGtoPDF(this.doc, pole.output[levelNumber].svg, svgBoundingBox['x'], svgBoundingBox['y'], { width: 172, height: 172 });
    }

    finish(res) {
        this.stream.on('finish', () => {
            let blob = this.stream.toBlob('application/pdf')
            let url = window.URL.createObjectURL(blob)
            const a = document.createElement("a")
            document.body.appendChild(a)
            a.style = "display: none"
            a.href = url
            a.download = "test.pdf"
            a.click();
            window.URL.revokeObjectURL(url)
        })
        this.doc.end();
        // return this.stream.toBlobURL('application/pdf');
        // res.setHeader('Content-Type', 'application/pdf');
        // res.setHeader('Content-Disposition', 'attachment; filename=pole.pdf');
        // res.status(200);
    }

    calculations(cm, levelNumber) {
        // analyzes the pole and returns necessary data for the rest of the calculations
        let analyzedPole = new AnalyzePole(this.pole['damageParameters'][levelNumber], this.pole['output'][levelNumber]['ansiDiameter']);
        this.actualCount = analyzedPole.damages.length;
        this.firstDamageCount = this.actualCount >= 1 ? Object.keys(analyzedPole.damages[0]).length - 2 : 0;
        this.secondDamageCount = this.actualCount >= 2 ? Object.keys(analyzedPole.damages[1]).length - 2 : 0;
        this.thirdDamageCount = this.actualCount >= 3 ? Object.keys(analyzedPole.damages[2]).length - 2 : 0;
        this.fourthDamageCount = this.actualCount >= 4 ? Object.keys(analyzedPole.damages[3]).length - 2 : 0;
        let damagesArr = Array(4);
        damagesArr[0] = this.firstDamageCount;
        damagesArr[1] = this.secondDamageCount;
        damagesArr[2] = this.thirdDamageCount;
        damagesArr[3] = this.fourthDamageCount;
        let i = 0;
        let j = 0;
        let currentDamageCount = 0;
        while (i < this.damagesCountArr.length) {
            while (j < damagesArr[currentDamageCount]) {
                this.damagesCountArr[i] = true;
                j++;
                i++;
            }
            i += 4 - damagesArr[currentDamageCount];
            j = 0;
            currentDamageCount++;
        }

        let percentResults = analyzedPole.calculatePercent(this.pole['output'][levelNumber].actual);
        let actualResults = analyzedPole.calculateActual(this.pole['output'][levelNumber].actual);
        let strenghtResults = analyzedPole.calculateStrength(this.pole['output'][levelNumber].actual, this.pole.info.fiberStrength);
        cm['bending_strength_number'] = 0;
        // level 1
        // selected section modulus
        cm['selected_section'] = actualResults['originalMomentSlopeY'] ? actualResults['originalMomentSlopeY'] : '';
        cm['section_transverse'] = actualResults['originalMomentSlopeX'] ? actualResults['originalMomentSlopeX'] : '';
        // remaining section modulus
        cm['remaining_section'] = actualResults['poleActualMomentSlopeY'] ? actualResults['poleActualMomentSlopeY'] : '';
        cm['remaining_section_transverse'] = actualResults['poleActualMomentSlopeX'] ? actualResults['poleActualMomentSlopeX'] : '';
        // percent remaining
        cm['percent_remaining_section'] = strenghtResults.result['polePercentY'] ? strenghtResults.result['polePercentY'] : '';
        cm['percent_remaining_section_t'] = strenghtResults.result['polePercentX'] ? strenghtResults.result['polePercentX'] : '';
        //
        // equivalent binding strength (psi)
        cm['bending_strength'] = strenghtResults.result['poleStrengthY'] ? strenghtResults.result['poleStrengthY'] : '';
        cm['bending_strength_transverse'] = strenghtResults.result['poleStrengthX'] ? strenghtResults.result['poleStrengthX'] : '';

        cm['ansi_bending_strength'] = strenghtResults.result['ansiStrengthY'] ? strenghtResults.result['ansiStrengthY'] : '';
        cm['ansi_bending_strength_transverse'] = strenghtResults.result['ansiStrengthX'] ? strenghtResults.result['ansiStrengthX'] : '';

        // selected moment of inertia 
        cm['selected_inertia'] = actualResults['originalMomentInertiaY'] ? actualResults['originalMomentInertiaY'] : '';
        cm['selected_intertia_transverse'] = actualResults['originalMomentInertiaX'] ? actualResults['originalMomentInertiaX'] : '';
        // remaining moment of inertia
        cm['remaining_inertia'] = actualResults['poleActualMomentInertiaY'] ? actualResults['poleActualMomentInertiaY'] : '';
        cm['remaining_inertia_transverse'] = actualResults['poleActualMomentInertiaX'] ? actualResults['poleActualMomentInertiaX'] : '';
        // percent remaining - currently overridden by same keys
        cm['percent_remaining_inertia'] = percentResults['polePercentMomentY'] ? percentResults['polePercentMomentY'] : '';
        cm['percent_remaining_inertia_t'] = percentResults['polePercentMomentX'] ? percentResults['polePercentMomentX'] : '';
        //
        // selected area (in^2)
        cm['selected_area'] = actualResults['originalArea'] ? actualResults['originalArea'] : '';
        // cm['selected_area_transverse'] = actualResults.actual[7][1];
        // remaining area (in^2)
        cm['remaining_area'] = actualResults['poleArea'] ? actualResults['poleArea'] : '';
        // percent of area remaining
        cm['percent_remaining_area'] = percentResults['polePercentArea'] ? percentResults['polePercentArea'] : '';
        // cm['percent_remaining_area_t'] = percentResults.percent[4][1];
        // ansi results
        // ansi section modulus (in^3)
        cm['ansi_section_modulus'] = actualResults['ansiMomentSlopeY'] ? actualResults['ansiMomentSlopeY'] : '';
        cm['ansi_section_modulus_transverse'] = actualResults['ansiMomentSlopeX'] ? actualResults['ansiMomentSlopeX'] : '';
        // ansi percent remaining
        cm['ansi_percent_remaining'] = percentResults['ansiPercentSlopeY'] ? percentResults['ansiPercentSlopeY'] : '';
        cm['ansi_percent_remaining_transverse'] = percentResults['ansiPercentSlopeX'] ? percentResults['ansiPercentSlopeX'] : '';
        // ansi moment of inertia
        cm['ansi_inertia'] = actualResults['ansiMomentInertiaY'] ? actualResults['ansiMomentInertiaY'] : '';
        cm['ansi_intertia_transverse'] = actualResults['ansiMomentInertiaX'] ? actualResults['ansiMomentInertiaX'] : '';
        // percent ansi moment of inertia
        cm['ansi_inertia_percent_remaining'] = percentResults['ansiPercentMomentY'] ? percentResults['ansiPercentMomentY'] : '';
        cm['ansi_intertia_percent_transverse'] = percentResults['ansiPercentMomentX'] ? percentResults['ansiPercentMomentX'] : '';
        // ansi area(in^2)
        cm['ansi_area'] = actualResults['ansiArea'] ? actualResults['ansiArea'] : '';
        // cm['ansi_area_transverse'] = actualResults.actual[8][1];
        cm['ansi_area_percent_remaining'] = percentResults['ansiPercentArea'] ? percentResults['ansiPercentArea'] : '';
        // ansi % remaining
        this.fillDamages(cm, levelNumber)
    }

    fillDamages(cm, levelNumber) {
        for (var typeOfDamage in this.pole.damageParameters[levelNumber]) {
            let valFromTypeOfDamage = this.pole.damageParameters[levelNumber][typeOfDamage];
            if (typeOfDamage === 'distanceFromGround' && cm['distance'] === '') {
                cm['distance'] = valFromTypeOfDamage;
            }
            if (typeOfDamage === 'diameter' && cm['circumference'] === '') {
                // circumference calculations
                let circum = valFromTypeOfDamage * Math.PI;
                circum = circum.toFixed(2);
                cm['circumference'] = circum;
                // diamater 
                cm['diameter'] = valFromTypeOfDamage.toFixed(2);
            }
            if (typeOfDamage === 'damages' && valFromTypeOfDamage.length != 0) {
                let arrOfDamages = valFromTypeOfDamage;
                for (let i = 0; i < arrOfDamages.length; i++) {
                    let currentIndex = i + 1;
                    let damageType = arrOfDamages[i];
                    let currentIndexOfDamage = 0;
                    for (const [key, value] of Object.entries(damageType)) {
                        if (key === 'type') {
                            cm['damage_' + currentIndex + '_name'] = value;
                        }
                        else if (key !== 'name') {
                            let damageNameArr = key.split(/(?=[A-Z])/);
                            let damageName = '';
                            damageNameArr.forEach(word => {
                                damageName = damageName.concat(' ', capitalizeFirstLetter(word));
                            })
                            currentIndexOfDamage += 1;
                            cm['damage_' + currentIndex + '_' + currentIndexOfDamage] = damageName;
                            cm['damage_' + currentIndex + '_' + currentIndexOfDamage + '_val'] = value;
                        }
                    }
                }
            }
        }
    }
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const PrintLocal = (poles) => {
    console.log('print local')
    console.log(poles)

    let builder = new PolePrinter();
    let hasOnePoleAlready = false;
    for (let poleIndex in poles) {
        let pole = poles[poleIndex];
        for (let i = 1; i <= 3; i++) {
            if (pole.damageParameters && pole.damageParameters[i] && pole.damageParameters[i].diameter) {
                if (!hasOnePoleAlready) {
                    hasOnePoleAlready = true;
                } else {
                    builder.doc.addPage();
                }
                try {
                    builder.addPole(pole, i);
                } catch (e) {
                    console.log(e)
                }
            }
        }
    }

    builder.finish();
}
