import firebase from "firebase";
const environment = process.env.NODE_ENV;

const servers = {
  development: "http://localhost:5002",
  production: "https://trellis-banking-api.firebaseapp.com",
};

const firebaseFetch = async (route, params) => {
  if (!firebase.auth().currentUser) throw new Error("User is not logged in");
  const token = await firebase.auth().currentUser.getIdToken();

  if (params.body) params.body = JSON.stringify(params.body);
  const url = `${servers[environment]}/trellis-banking-api/us-central1/api${route}`;

  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    ...params,
  });
  const data = await response.json();
  return data;
};

export default firebaseFetch;
