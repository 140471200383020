export const CARD_STYLING = {
    maxWidth: '500px',
    margin: 'auto',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    paddingRight: '12px',
    paddingLeft: '12px',
    paddingBottom: '15px',
    paddingTop: '15px'
}

export const API_CARD_STYLING = {
    maxWidth: '600px',
    width: '95%',
    margin: 'auto',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    paddingRight: '12px',
    paddingLeft: '12px',
    paddingBottom: '15px',
    paddingTop: '15px'
}

export const DB_PAGE_STYLING = {
    maxWidth: '800px',
    margin: 'auto',
    // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    paddingRight: '12px',
    paddingLeft: '12px',
    paddingBottom: '15px',
    paddingTop: '15px'
}