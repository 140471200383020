import firebase from "firebase";

const config = require("./credentials.json");

class Firebase {
    constructor() {
        this.env = this.__getEnv();
        this.config = this.__getConfig();
        firebase.initializeApp(this.config);

        this.auth = firebase.auth();
        this.database = firebase.firestore();
        this.functions = firebase.functions();

        if (window.location.hostname === "localhost") {
            this.functions.useFunctionsEmulator("http://localhost:5000");
            //Comment out if running from dev
            //TODO make a toggle function
            //this.database.settings({ host: "localhost:8080", ssl: false });
            //this.auth.useEmulator("http://localhost:9099");
        }
    }

    __getConfig() {
        return this.env === "production"
            ? config["production"]
            : config["development"];
    }

    __getEnv() {
        if (window.location.hostname === "localhost") {
            return "localhost";
        } else if (
            window.location.hostname.startsWith(config["development"]["projectId"])
        ) {
            return "development";
        } else {
            return "production";
        }
    }

    signOut() {
        return this.auth.signOut();
    }

    async fetch(route, params) {
        if (!this.auth.currentUser) throw new Error("User is not logged in");
        const token = await this.auth.currentUser.getIdToken();

        if (params?.body) params.body = JSON.stringify(params.body);
        const response = await fetch(this.getAPIRoute(route), {
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`,
            },
            ...params,
        });

        if (response.status >= 200 && response.status < 400) {
            const data = await response.json();
            return data;
        }
        let errData;
        try {
            errData = await response.json();
        } catch (err) {
            console.error(err);
        }

        throw new Error(errData?.error || "Server Failed. ");
    }

    async fetchCSV(route, params) {
        if (!this.auth.currentUser) throw new Error("User is not logged in");
        const token = await this.auth.currentUser.getIdToken();

        if (params?.body) params.body = JSON.stringify(params.body);
        const response = await fetch(this.getAPIRoute(route), {
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`,
            },
            ...params,
        });
        const data = await response.text();

        if (response.statusCode >= 400) throw new Error(data.message);
        return data;
    }

    async fetchPDF(route, params) {
        if (!this.auth.currentUser) throw new Error("User is not logged in");
        const token = await this.auth.currentUser.getIdToken();

        if (params?.body) params.body = JSON.stringify(params.body);
        const response = await fetch(this.getAPIRoute(route), {
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`,
            },
            ...params,
        });
        const data = await response.blob();
        if (response.status >= 400) throw new Error(data.message);
        return data;
    }

    getAPIRoute(route) {
        let apiRoute = `/api${route}`;
        if (this.env === "localhost") {
            apiRoute = "http://localhost:5000" + apiRoute;
        }
        return apiRoute;
    }
}

export default new Firebase();