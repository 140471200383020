import { Button, KIND } from "baseui/button";
import { Input } from "baseui/input";
import { toaster } from "baseui/toast";
import { HeadingSmall, Label1 } from "baseui/typography";
import React from 'react';
import BillingAPI from "../../firebase/BillingAPI";
import { Spacer } from "../layout/Spacer";
import { dcalcBlueButton } from "./Billing";


export const APIPurchasesComponent = ({orgData, orgId}) => {
    const [showAPIKey, setShowAPIKey] = React.useState(false);
    const [cycleKeyLoading, setCycleKeyLoading] = React.useState(false);
    const [getKeyLoading, setGetKeyLoading] = React.useState(false);

    function getKey() {
        setGetKeyLoading(true);
        BillingAPI.getAPIKey(orgId)
            .then((e) => {
                setShowAPIKey(e)
                setGetKeyLoading(false)
            })
            .catch(e => {
                console.error('error at getting key')
                console.error(e)
                setGetKeyLoading(false)
            })
    }

    function cycleKey() {
        setCycleKeyLoading(true);
        BillingAPI.cycleAPIKey(orgId)
            .then((e) => {
                setShowAPIKey(e)
                setCycleKeyLoading(false)
            })
            .catch(e => {
                console.log(e)
                toaster.negative(e.message + e.stack)
                setCycleKeyLoading(false)
            })
    }

    function copyKey() {
        navigator.clipboard.writeText(orgId + ':' + showAPIKey.key);
        toaster.info('Key copied to clipboard')
    }

    return <>
        <HeadingSmall>API</HeadingSmall>
            {
                orgData.apiEnabled ?
                    <>
                        <Label1>API Requests Purchased: {orgData.apiQuotaPurchased || 0}</Label1>
                        <Label1>API Access Used: {orgData.apiQuotaUsed || 0}</Label1>
                        <Label1>Test API Access Purchased: {orgData.testApiQuotaPurchased || 0}</Label1>
                        <Label1>Test API Access Used: {orgData.testApiQuotaUsed || 0}</Label1>
                    </> : undefined
            }
            <p style={{ fontSize: 18 }}>API Key:</p>
            <div style={{ display: 'flex', margin: '10px 0px ' }}>
                {orgData.apiEnabled ? showAPIKey ? <><Input value={orgId + ':' + showAPIKey.key} disabled></Input><Button kind={KIND.secondary} onClick={(e) => copyKey()}><span className="material-icons">content_copy</span></Button></> : <><Button isLoading={getKeyLoading} overrides={dcalcBlueButton} onClick={() => getKey()}>Get API Key</Button><Spacer /></> : undefined}
                {orgData.apiEnabled ? <><Button isLoading={cycleKeyLoading} overrides={dcalcBlueButton} onClick={() => cycleKey()} style={{ margin: '0px 15px' }} disabled={!showAPIKey}><span className="material-icons">refresh</span></Button><Spacer /></> : undefined}
            </div>
            <p style={{ fontSize: 18 }}>Documentation</p>
            <><Button overrides={dcalcBlueButton} $as='a' target='_blank' href='/swagger-ui/'>View documentation <span className="material-icons" style={{ marginLeft: '10px' }}>open_in_new</span></Button><Spacer /></>
    </>
}