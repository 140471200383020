import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { ArrowDown, ArrowUp } from "baseui/icon";
import { Paragraph1 } from "baseui/typography";
import React from "react";
import { connect } from "react-redux";
import { setCurrentLevel } from "../../actions/ui";

export const LevelChooser = ({ setLevel, level }) => <Block display={'flex'} justifyContent="space-around" margin="15px">
    <Button onClick={(e) => setLevel((level - 1).toString())} disabled={parseInt(level) <= 1}><ArrowDown /></Button>
    <Paragraph1>Level {level}</Paragraph1>
    <Button onClick={(e) => setLevel((level - -1).toString())} disabled={parseInt(level) >= 3}><ArrowUp /></Button>
</Block>

export const ConnectedLevelChooser = connect(state => ({
    level: state.ui.currentLevel
}), {
    setLevel: setCurrentLevel
})(LevelChooser);