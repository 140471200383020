import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import React from 'react';

const whiteLabelStyle = {
  Input: {
    style: ({ $theme }) => ({
      backgroundColor: '#FFFFFF',
    })
  }
}

export const WidthOrDepth = ({ startEnhancer, currentDamageValue, level, size, setDamageValue, currentDamageIndex, type }) => {
  const setInput = ({ target }, otherInput) => setDamageValue(level, currentDamageIndex, {
    // type: damageName.type,
    [target.name]: +target.value,
    [otherInput]: '',
  });

  return (
    <>
      <FormControl label={'Depth Missing'}>
        <Input
          overrides={whiteLabelStyle}
          value={currentDamageValue?.['depthMissing'] || ''}
          onChange={e => setInput(e, 'widthMissing')}
          name='depthMissing'
          {...{ startEnhancer, size, type }} />
      </FormControl>
      <FormControl label={'Width Missing'}>
        <Input
          overrides={whiteLabelStyle}
          value={currentDamageValue?.['widthMissing'] || ''}
          onChange={e => setInput(e, 'depthMissing')}
          name='widthMissing'
          {...{ startEnhancer, size, type }} />
      </FormControl>
    </>
  );
}
